import { Modal, Spin, Upload } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { getExistingLatestAuditRecord } from '../../apis/tools';
import RightArrow from "../../assets/audit/rightArrow.svg";
import UploadIcon from '../../assets/audit/uploadIcon.svg';
import ZeroStateImg from '../../assets/audit/zerostate.svg';
import organisation from "../../redux/modules/organisation";
import config, { checkFinancialYear, getFinancialYears, monthList } from '../../utils/config';
import { HeaderComponent } from '../Common';
import { NormalButton } from '../ComponentLibrary';
import DropdownComponent from '../InputLibrary/DropdownComponent/DropdownComponent';
import "./ZeroState/audit.less";
import AuditReports from './ZeroState/AuditReports';
import ParserValidator from './ZeroState/ParserValidator';

const { Dragger } = Upload;



const Audit = ({ user }) => {
  const [uploadModal, setUploadModal] = useState(false);
  const [auditData, setAuditData] = useState({});
  const [currentMonth, setCurrentMonth] = useState(checkFinancialYear().month);
  const [currentFinancialYear, setCurrentFinancialYear] = useState(checkFinancialYear().financialYear);
  const [downloadedFinancialYear] = useState(checkFinancialYear().financialYear);
  const [downloadedMonth] = useState(checkFinancialYear().month);
  const [loading, setIsLoading] = useState(false);
  const [pageState, setPageState] = useState(0);
  const [parsedData, setParserData] = useState({});

  const showModal = () => {
    setUploadModal(true);
  };


  const platforms = {
    name: "file",
    action: `${config.rootUrl}/api/audit/report_parser`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setParserData(response.data);
      }
    },
  };

  useEffect(() => {
    const { organisationId } = user;
    getExistingLatestAuditRecord({ organisationId }).then(res => {
      if (res.data.data) {
        setAuditData(res.data.data);
        setCurrentFinancialYear(res.data.data.financialYear ?? downloadedFinancialYear)
        setCurrentMonth(parseInt(res.data.data.month ?? downloadedMonth))
      } else {
        setAuditData({});
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleAction = async (url, name) => {
    let res = await axios({
      url, //your url
      method: 'GET',
      responseType: 'blob',
    })
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', name); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  return (
    <Spin spinning={loading}>
      <HeaderComponent name={"Audit Reports"} ></HeaderComponent>
      {
        pageState === 1 ? <ParserValidator setAuditData={setAuditData} parsedData={parsedData} user={user} setPageState={setPageState} month={currentMonth} financialYear={currentFinancialYear} /> :
          <>
            {auditData && Object.keys(auditData).length === 0 ?
              <div className='audit_container_zero_state'>
                <h2 className='audit_head'>Audit your list</h2>
                <div className='zero_state_cont m-left-10'>
                  <img src={ZeroStateImg} alt='zerostateimage'></img>
                  <h1 className='audit_head_zero'>Audit your pay register</h1>
                  <p className='audit_p'>Add your employees</p>

                  <NormalButton
                    buttonText="Get started"
                    secondaryIcon={RightArrow}
                    style={{ width: "200px" }}
                    type={13}

                    onClick={showModal}
                  />

                </div>
                <Modal title="" visible={uploadModal} footer={[]} onCancel={() => setUploadModal(false)}>

                  <div className='modal_Inner_cont'>
                    <div>
                      <h1 className='upload_head'>Upload and attach files</h1>
                      <p className='p_text'>
                        <a
                          href
                          className="download-employee-list"
                          onClick={() =>
                            handleAction(
                              `${config.rootUrl}/api/audit/report/${user.organisationId}/${currentMonth}/${currentFinancialYear}`,
                              "Audit_Report_" + monthList[currentMonth] + ".xlsx"
                            )
                          }
                        >
                          Download sample .xlsx format
                        </a> or drag and drop</p>
                    </div>

                    <div className='modal_input_box'>
                      <DropdownComponent labeltext={"Month"} width={"100%"} values={Object.keys(monthList)} options={monthList} value={monthList[currentMonth]} onChange={e => setCurrentMonth(e)} />
                    </div>
                    <div className='modal_input_box'>
                      <DropdownComponent labeltext={"Financial Year"} width={"100%"} values={getFinancialYears()} options={getFinancialYears()} value={currentFinancialYear} onChange={e => setCurrentFinancialYear(e)} />
                    </div>

                    <div className='modal_upload_box'>
                      <Dragger
                        {...platforms}
                        showUploadList={false}
                        className="dragger"
                      >
                        {
                          <div className="dragger-info-container">
                            <img src={UploadIcon} alt="upload icon" />
                            <p className="ant-upload-text">
                              Drag and drop your file or click here to upload
                            </p>
                          </div>
                        }
                      </Dragger>
                    </div>
                  </div>
                </Modal>


              </div>
              :
              <div className='audit_container_data_state'>
                <AuditReports user={user} financialYear={currentFinancialYear} />
              </div>
            }
          </>
      }

    </Spin>
  )
}
const AuditContainer = connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Audit);

export default AuditContainer;