/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Spin,
  message,
  Upload,
  notification,
  Table,
  Progress,
  Row,
  Col,
  Select
} from "antd";
import { updateEmployeePercentage } from "../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import config from "../../utils/config";
import uploadLogo from "../../assets/icons/savefile.svg";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createLog } from "../../apis/user";
import { NormalButton } from "../ComponentLibrary";

const { Dragger } = Upload;
const { Option } = Select;

const DataItem = ({ itemName, amount, text }) => {
  return (
    <div className="individual-structure" key={itemName}>
      <p className="break-label">
        {itemName}
      </p>
      <p className="break-value">
        {config.numformatter(amount)} {text}
      </p>
    </div>
  );
};

const PercentageStructure = ({ user, groups, handleAction, commonColumn }) => {
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [percentageData, setPercentageStructureData] = useState([]);
  const [displayPercentageStructure, setDisplayPercentageStructure] = useState(
    []
  );
  const [completed, setCompleted] = useState(0);
  const [groupId, setGroupId] = useState("general");

  const heading = ["Upload the Document", "Verify and Update"];
  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/parser/percentage/${groupId}`,
    headers: {
      authorization: localStorage.getItem("mool_auth")
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setPercentageStructureData([...response]);
        let res = response.map((val, index) => {
          return { ...val, key: index + 1 };
        });
        setDisplayPercentageStructure([...res]);
      }
    }
  };

  const reset = () => {
    setPercentageStructureData([]);
    setPageState(0);
    setCompleted(0);
    setGroupId("general");
  };

  const updateAllowance = async () => {
    if (percentageData.length === 0) {
      message.error("Please Upload CSV File");
      return;
    }
    setIsLoading(true);
    let totalSuccess = 0;
    let { financialYear } = config.checkFinancialYear();
    for (const element of percentageData) {
      const { id, percentage } = element;
      await updateEmployeePercentage({
        id,
        financialYear,
        percentage
      })
      totalSuccess += 1;
      setCompleted(totalSuccess);
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organisation user updated ${percentageData.length} number of percentage structure for ${financialYear} financial year`,
      category: 15,
    });
    notification.success({
      message: "Successfully Uploaded the allowances data"
    });

    setIsLoading(false);
    reset();
  };

  return (
    <div className="">
      {isLoading &&
        pageState === 1 &&
        <Progress
          percent={Math.round(completed / percentageData.length * 100)}
          size="small"
          status="active"
        />}
      <Spin size="large" spinning={isLoading}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="flex align-center">
              {pageState !== 0 &&
                <ArrowLeftOutlined
                  onClick={() => {
                    let page = pageState;
                    page = page - 1;
                    setPageState(page);
                  }}
                  style={{ marginRight: 10 }}
                  className="arrow-left-button font-weight-700"
                />}
              <h3 className="app-heading-3">
                {heading[pageState]}
              </h3>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {pageState === 1
              ? <div className="flex w-full justify-end">
                <NormalButton
                  type={5}
                  buttonText="Cancel"
                  onClick={reset}
                  className="cancel-button"
                  style={{
                    border: "none",
                    boxShadow: "none",
                    marginRight: 10
                  }}
                />
                  <NormalButton
                    type={13}
                    buttonText="Upload Percentage Structure"
                    onClick={updateAllowance}
                    className="submit-button"
                    style={{
                      marginRight: "0px",
                      padding: "0px 24px"
                    }}
                  />
              </div>
              : <Select
                className="general-input-field"
                onChange={e => setGroupId(e)}
                style={{ width: "100%", padding: "2px" }}
                bordered={false}
                size={"large"}
                filterOption={(input, option) =>
                  option.children &&
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())}
                showSearch
                value={groupId}
                placeholder="Select Group"
              >
                <Option value="general">General</Option>
                {groups.map((val, index) => {
                  return (
                    <Option key={`group-select-${index}`} value={val.groupId}>
                      {val.name}
                    </Option>
                  );
                })}
              </Select>}
          </Col>
        </Row>
        {pageState === 0 &&
          <UploadDocument
            platforms={{ ...uploadProps }}
            user={user}
            groupId={groupId}
            handleAction={handleAction}
          />}
        {pageState === 1 &&
          <VerifyAndUpload
            displayPercentageStructure={displayPercentageStructure}
            commonColumn={commonColumn}
          />}
      </Spin>
    </div>
  );
};

const UploadDocument = ({ platforms, user, groupId, handleAction }) => {
  return (
    <Content className="bulk-wrapper-left">
      <div>
        <p className="paper-title">
          Download Sample in{" "}
          <a
            className="download-employee-list"
            onClick={() =>
              handleAction(
                `${config.rootUrl}/api/generate/document/percentage/${user.organisationId}/${groupId}`,
                "Percentage_Structure.xlsx"
              )
            }
          >
            .csv format
          </a>{" "}
          file to ensure that you have the correct file ready to import.
        </p>
      </div>
      <div>
        <Dragger
          {...platforms}
          showUploadList={false}
          height={300}
          className="bulk-wrapper-dragger"
        >
          <p className="ant-upload-drag-icon">
            <img alt="" src={uploadLogo} height="80" width="80" />
          </p>

          <p className="ant-upload-text">Drag and drop CSV file</p>
          <p className="ant-upload-hint">
            or <u>Click Here</u> to upload
          </p>
        </Dragger>
      </div>
    </Content>
  );
};

const VerifyAndUpload = ({ displayPercentageStructure, commonColumn }) => {
  return (
    <Table
      columns={commonColumn}
      dataSource={displayPercentageStructure}
      expandable={{
        expandedRowRender: record => {
          const { percentage } = record;
          const { basic, hra, allowances, customAllowances } = percentage;
          return (
            <div>
              <DataItem
                itemName={"Basic"}
                amount={Math.round(basic * 100000) / 1000}
                text={"% of CTC"}
              />
              <DataItem
                itemName={"HRA"}
                amount={Math.round(hra * 100000) / 1000}
                text={"% of CTC"}
              />
              {Object.keys(allowances).map((_all, i) =>
                allowances[_all].value > 0 && <DataItem
                  key={i}
                  itemName={config.allowances[_all].label}
                  amount={
                    allowances[_all].isPercent
                      ? Math.round(allowances[_all].value * 100000) / 1000
                      : allowances[_all].value
                  }
                  text={allowances[_all].isPercent ? "% of CTC" : "INR Per month"}
                />
              )}
              {Object.keys(customAllowances).map((_all, i) =>
                customAllowances[_all].value > 0 && <DataItem
                  key={i}
                  itemName={_all}
                  amount={
                    customAllowances[_all].isPercent
                      ? Math.round(customAllowances[_all].value * 100000) / 1000
                      : customAllowances[_all].value
                  }
                  text={customAllowances[_all].isPercent ? "% of CTC" : "INR Per month"}
                />
              )}
            </div>
          );
        },
        rowExpandable: record => record
      }}
      pagination={false}
      scroll={{ x: 600, y: "65vh" }}
    />
  );
};

export default PercentageStructure;
