import { NormalButton } from "../../../ComponentLibrary";
import { Col, notification, Radio, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { updateFreeze } from "../../../../apis/organisation";
import { checkFinancialYear } from "../../../../utils/config";
import blueEditIcon from "../../../../assets/icons/edit.svg";
import { createLog } from "../../../../apis/user";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
const options = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const CommonFreeze = ({
  employeeList,
  organisationReducer,
  user,
  organisationFreeze,
  type,
}) => {
  const types = ["Allowances", "Declarations", "Perquisite"];
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [freezeData, setFreezeData] = useState({
    organisationId: "",
    financialYear: "",
    type: 0,
    freeze: true,
    employeeList: {},
  });
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [employeeDisplayData, setEmployeeDisplayData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (rows) => {
    setSelectedRowKeys([...rows]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "displayName",
    },
    {
      title: "Employee ID",
      dataIndex: ["currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Email",
      dataIndex: ["primaryEmail"],
    },
  ];

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationFreeze, user, type, employeeList]);
  const updateData = () => {
    let selEmp = [];
    let freezeExceptions = null;
    if (type === 1) {
      setFreezeData({
        type,
        financialYear: organisationFreeze.financialYear,
        employeeList: organisationFreeze.declaration,
        freeze: organisationFreeze.declarationFreeze,
        organisationId: user.organisationId,
      });
      freezeExceptions = { ...organisationFreeze.declaration };
    } else if (type === 0) {
      setFreezeData({
        type,
        financialYear: organisationFreeze.financialYear,
        employeeList: organisationFreeze.allowances,
        freeze: organisationFreeze.allowancesFreeze,
        organisationId: user.organisationId,
      });
      freezeExceptions = { ...organisationFreeze.allowances };
    } else if (type === 2) {
      setFreezeData({
        type,
        financialYear: organisationFreeze.financialYear,
        employeeList: organisationFreeze.perquisites,
        freeze: organisationFreeze.perquisitesFreeze,
        organisationId: user.organisationId,
      });
      freezeExceptions = { ...organisationFreeze.perquisites };
    }
    Object.keys(freezeExceptions).forEach((val) => {
      let emp = searchInEmployee(val);
      if (Object.keys(emp).length > 0) {
        selEmp.push(emp);
      }
    });
    setEmployeeDisplayData([...employeeList])
    setSelectedEmployees([...selEmp]);
    // updateEmployeeList();
  };

  const updateFreezeInfo = async () => {
    let employeeList = {};
    if (freezeData.freeze) {
      for (const element of selectedRowKeys) {
        employeeList[element] = true;
      }
    }
    let putBody = {
      ...freezeData,
      employeeList,
      organisationId: user.organisationId,
    };
    setLoading(true);
    let res = await updateFreeze(putBody);
    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has updated freeze info`,
        category: 14,
      });
      notification.success({
        message: "Successfully updated the freeze information",
      });
      let fyYear = checkFinancialYear().financialYear;
      organisationReducer.updateFreeze(`${user.organisationId}/${fyYear}`);
    } else {
      notification.error({
        message: "Unable to update the freeze information",
      });
    }
    setLoading(false);
    setEditable(false);
  };


  const searchInEmployee = (id) => {
    for (const element of employeeList) {
      if (element._id.toString() === id.toString()) {
        return element;
      }
    }
  };

  return (
    <div>

      <h1 className="h1-bold-24">Freeze {types[type]} </h1>{" "}
      <Spinner spinning={loading} size="large">
        <div className="flex justify-end align-center">
          <NormalButton
            icon={editable ? "" : blueEditIcon}
            buttonText={editable ? "Cancel" : "Edit"}
            type={editable ? "5" : "13"}
            onClick={() => {
              setEditable(!editable);
              updateData();
            }}
          />
          {editable && (
            <NormalButton
              buttonText={"Update"}
              type={13}
              onClick={updateFreezeInfo}
            />
          )}
        </div>
        <div className="">
          <Row gutter={[100, 35]}>
            <Col sm={24} md={24} lg={24} xl={24}>
              <p className="app-label-5">
                Do you want to freeze {types[type]} for the financial year{" "}
                {freezeData.financialYear} ?
              </p>
              {editable ? (
                <div className="app-radio-container">
                  <Radio.Group
                    options={options}
                    onChange={(e) =>
                      setFreezeData({
                        ...freezeData,
                        freeze: e.target.value,
                      })
                    }
                    disabled={!editable}
                    value={freezeData.freeze}
                  />
                </div>
              ) : (
                <div className="app-input-value">
                  {freezeData.freeze && "Yes"}
                  {!freezeData.freeze && "No"}
                </div>
              )}
            </Col>
            <Col sm={24} md={24} lg={24} xl={24}>
              <div className="flex justify-start align-center">
                <h3 className="h5-bold-20">Freezed Employees </h3>
                <span className="subtitle1-med-16 m-right-10">
                  (The following employees can not upload their proofs while in this list.)
                </span>
              </div>
              {!editable ? (
                <Table
                  style={{ marginTop: "1.5rem" }}
                  columns={columns}
                  dataSource={selectedEmployees}
                  pagination={false}
                  scroll={{ y: 520 }}
                />
              ) : (
                freezeData.freeze && (
                  <Table
                    pagination={{ pageSize: 8 }}
                    rowSelection={rowSelection}
                    rowKey={"_id"}
                    columns={columns}
                    dataSource={employeeDisplayData}
                  />
                )
              )}
            </Col>
          </Row>
        </div>
      </Spinner>
    </div>
  );
};

export default CommonFreeze;
