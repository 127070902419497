import React, { useState, useEffect } from "react";
import { Table, Button, Modal, notification } from "antd";
import { FileViewer, NormalButton } from "../../../ComponentLibrary";
import { orgAcceptRejectLeave } from "../../../../apis/organisation";
import employeeLogo from "../../../../assets/employeeProfile/EmployeeLogo.svg";
import { connect } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { leaveLogForManager } from "../../../../apis/employee";
import { createLog } from "../../../../apis/user";

const confirmHeading = [
  "", "Approve", "Reject"
]

const Request = ({ user }) => {
  const [viewFile, setViewFile] = useState(false);
  const [viewFileDocumentProps, setviewFileDocumentProps] = useState({});
  const [isRefresh, setIsRefresh] = useState(false);

  const [
    viewFileDocumentVerifyProps,
    setviewFileDocumentVerifyProps,
  ] = useState({});
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getLeaveRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isRefresh]);

  const [leaveLogs, setLeaveLogs] = useState([]);
  const getLeaveRecords = async () => {
    let res = await leaveLogForManager(`${user.PAN}/0`);
    setLeaveLogs(res.data);
  };

  const leaveAction = async (leaveObj, status) => {
    Modal.destroyAll();
    setLoading(true)
    let data = {
      status, id: leaveObj.user._id, leaveLogId: leaveObj.leaveLogId
    }
    let res = await orgAcceptRejectLeave(user.organisationId, data)
    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has updated a leave of ${leaveObj.user.displayName}`,
        category: 6,
      });
      notification.success({
        message: `Successfully ${confirmHeading[status]}ed The Leave`,
      });
    } else {
      notification.error({
        message: `Sorry Unable To ${confirmHeading[status]} The Leave`,
      });
    }
    await getLeaveRecords();
    setLoading(false)
  }

  const confirmLeaveAction = (leaveObj, status) => {
    if (status > 0 && status < 3) {
      Modal.confirm({
        title: `${confirmHeading[status]} Leave ?`,
        icon: <ExclamationCircleOutlined />,
        okText: confirmHeading[status],
        okButtonProps: {
          type: "primary",
          danger: status === 2,
        },
        cancelText: "Cancel",
        onOk: () => leaveAction(leaveObj, status),
      })
    }
    else {
      notification.error({ message: "Sorry Unable To Process The Request" })
    }

  }

  const handelViewDocument = (fileProps) => {
    setViewFile(true);
    let tempFileProps = {
      documentKey: fileProps.key,
      documentType: fileProps.key.split(".").pop(),
      documentName: fileProps.key.split("reimbursement-").pop(),
    };
    let tempViewFileDocumentVerifyProps = {
      showVerificationStatus: true,
      userCanVerify: true,
      fileIsVerified: true,
      verifyCallback: () => { console.log("Mool!, Rocks") },
      propsForVerifyCallback: {
        proofId: 1,
        index: 0,
        verified: true,
      },
    };
    setviewFileDocumentProps(tempFileProps);
    setviewFileDocumentVerifyProps(tempViewFileDocumentVerifyProps);
  };

  const columns = [
    {
      title: "",
      dataIndex: ["user"],
      render: (usr) => {
        return (
          <div className="flex align-center">
            <img
              className="rounded-image-1"
              alt="img"
              src={usr.image ? usr.image : employeeLogo}
            />
            <p className="app-label-3" style={{ marginLeft: 10 }}>
              {usr.displayName}
            </p>
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: ["leaveType", "name"],
      key: "type",
      render: (data) => (
        <p>
          <b>Type:</b> {data}
        </p>
      ),
    },
    {
      title: " ",
      dataIndex: "duration",
      key: "duration",
      render: (data, rowData) => {
        return (
          <div className="flex algin-center">
            <p style={{ padding: 3 }}>{data} Days</p>
            {Object.keys(rowData.documents).length > 0 && (
              <Button
                onClick={() => handelViewDocument(rowData.documents)}
                type="link"
              >
                Documents
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "reason",
      key: "reason",
      ellipsis: true,
    },
    {
      title: " ",
      dataIndex: "button",
      key: "button",
      render: (_data, row) => (
        <React.Fragment>
          <NormalButton onClick={() => confirmLeaveAction(row, 1)} type={1} buttonText="Accept" />
          <NormalButton onClick={() => confirmLeaveAction(row, 2)} type={5} buttonText="Cancel" />
        </React.Fragment>
      ),
    },
  ];
  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  return (
    <div className="request-container">
      <div className="flex justify-between">
        <p className="request-heading">Leave Request</p>
        <NormalButton
          buttonText={"Refresh"}
          type={13}
          onClick={startRefresh}
        />
      </div>
      <Table loading={loading} pagination={leaveLogs.length <= 8 ? false : { pageSize: 8 }} columns={columns} dataSource={leaveLogs} />
      <FileViewer
        visible={viewFile}
        onclose={setViewFile}
        fileProps={viewFileDocumentProps}
        verifyProps={viewFileDocumentVerifyProps}
      />
    </div>
  );
};

const LeaveRequest = connect((state) => ({
  user: state.auth.user,
}))(Request);

export default LeaveRequest;
