import { notification, Table } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom';
import { saveAuditRecord } from '../../../apis/tools';
import { monthList } from '../../../utils/config';
import { NormalButton } from '../../ComponentLibrary'

const ParserValidator = ({ user, setAuditData, parsedData, setPageState, month, financialYear }) => {
    const columns = [
        {
            key: 1,
            title: "Employee ID",
            dataIndex: "employeeId"
        },
        {
            key: 2,
            title: "Employee Name",
            dataIndex: "displayName"
        },
        {
            key: 3,
            title: "Date Of Joining",
            dataIndex: "dateOfJoining"
        },
        {
            key: 4,
            title: "Date Of Birth",
            dataIndex: "dateOfBirth"
        },
        {
            key: 5,
            title: "Date Of Leaving",
            dataIndex: "dateOfLeaving"
        },
        {
            key: 6,
            title: "Phone",
            dataIndex: "phone"
        },
        {
            key: 7,
            title: "Designation",
            dataIndex: "designation"
        },
        {
            key: 8,
            title: "Department",
            dataIndex: "department"
        },
        {
            key: 9,
            title: "Location",
            dataIndex: "location"
        },
        {
            key: 10,
            title: "Gender",
            dataIndex: "gender"
        },
        {
            key: 11,
            title: "Martial Status",
            dataIndex: "maritalStatus"
        },
        {
            key: 12,
            title: "PF Number",
            dataIndex: "PFNo"
        },
        {
            key: 13,
            title: "ESIC Number",
            dataIndex: "ESICNo"
        },
    ];
    let history = useHistory();

    const handleSubmit = () => {
        saveAuditRecord({ organisationId: user.organisationId, month, financialYear, employees: parsedData }).then(res => {
            if (res.data.success) {
                notification.success({ message: "Updated employee list!" });
                setAuditData(res.data.data)
                setPageState(2);
                history.push("/org/utility/audit");
            } else {
                notification.error({ message: "Error while getting data!" });
                setAuditData({});
                setPageState(0);
                history.push("/org/utility/audit");
            }
        });
    }
    return (
        <div className='app-main-content-container paper-wrapper'>
            <div className='flex justify-between'>
                <div>
                    <h2>Preview Record</h2>
                    <p>For Month: {monthList[month]} and FY: {financialYear}</p>
                </div>
                <div className='flex justify-end m-bottom-20'>
                    <NormalButton buttonText={"Cancel"} type={2} onClick={() => setPageState(0)} />
                    <NormalButton buttonText={"Upload Record"} type={13} onClick={handleSubmit} />
                </div>
            </div>
            <Table dataSource={Object.values(parsedData)} pagination={false} style={{ minHeight: "300px", maxWidth: "100%", overflowX: "auto" }} columns={columns} />

        </div>
    )
}

export default ParserValidator