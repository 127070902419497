import React from "react";
import "./DropdownComponent.less";
import { Select } from "antd";
const { Option } = Select;
function DropdownComponent({
  maxWidth,
  showSearch,
  value,
  filterOption,
  onChange,
  options,
  values,
  width,
  labeltext,
  display,
  disabled,
  margin,
  labelColor,
  valueCanBeBlankString = false,
}) {
  let styles = {
    border: value === undefined || value === "" ? "" : "2px #5E5F62 solid",
    width: width ? `${width}` : "288px",
    maxWidth: maxWidth ? `${maxWidth}` : "100%",
    height: "50px",
    borderRadius: "8px",
  };
  let stylediv = {
    position: "relative",
    display: display ? display : "block",
    margin: margin
      ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`
      : "10px 0px",
  };

  return (
    <div className="dropdowndiv" style={stylediv}>
      <Select
        showSearch={showSearch}
        value={value}
        filterOption={filterOption}
        onChange={onChange}
        disabled={disabled}
        defaultValue=""
        style={styles}
      > 
        {options.map((val, index) => (
          <Option value={values ? values[index] : index} key={`dropdown-key-${index}`}>
            {val}
          </Option>
        ))}
      </Select>
      <label
        style={{ backgroundColor: labelColor ?? "white" }}
        className={
          !valueCanBeBlankString && (value === undefined || value === "") ? "labeldropdown" : "labelup"
        }
      >
        {labeltext ? labeltext : "Your Label"}
      </label>
    </div>
  );
}

export default DropdownComponent;
