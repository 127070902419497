import ReducerFactory from "../utils/reducerFactory";
import * as employee from "../../apis/employee";
import * as org from "../../apis/organisation";
import { notification } from "antd";
import config, {
  checkFinancialYear,
} from "../../utils/config";

const initialState = {
  loading: false,
  customPoint: 0,
  organisationSettings: {},
  moolData: null,
  financialYear: "",
  month: 0,
  payslips: [],
  ctc: {},
  hra: {},
  deductions: [],
  freezeInfo: {},
  bonuses: [],
  group: {},
  structureOfMool: false,
  isPercentageStructure: false,
  allowances: {},
  customAllowances: {},
  percentage: null,
  advanceTaxConfig: {
    advanceTax: 0,
    isAdvanceTaxAboveIncomeTax: true,
  },
  marker: {},
  orgInfo: {},
  organisationFreeze: {},
  leaveList: [],
  leaveBalanceReport: {},
  holidays: [],
  attendances: [],
  leaveLogs: [],
  monthlyReport: {},
  todayAttendance: {},
  weeklyOffs: {},
  finalPayslip: {
    basic: 0,
    bonuses: {},
    reimbursements: {},
    monthClose: {},
    customAllowances: {},
    employee_ESI: 0,
    employee_PF: 0,
    newRegime: {
      deductions: 0,
      incomeTax: 0,
      takeHome: 0,
    },
    oldRegime: {
      deductions: 0,
      incomeTax: 0,
      takeHome: 0,
    },
    otherAllowances: {},
    perks: {},
    proffTax: 0,
    specialAllowance: 0,
    totalEarnings: 0,
    totalFixedIncome: 0,
  },
  declaration: {},
  isPayslipSaved: false,
  monthClose: [],
  isChanged: false,
  selectedIndex: 0,
  employeeTour: false,
  reimbursements: [],
  broadcastMessage: [],
  separations: {},
  department: {},
  status: {},
  designation: {},
  groups: {},
  location: {},
  settlement: {},
  onboarding: null,
  previousEmployement: {},
  taxSettlement: {},
  perquisites: {},
  supervisor: null
};
const reducerFactory = new ReducerFactory("employee", initialState);

reducerFactory.addAction(
  "LOADING",
  "loading",
  (status) => status,
  (state, action) => {
    state.loading = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_HRA",
  "updateHra",
  async (status) => status,
  (state, action) => {
    state.hra = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "INITIATE_EMPLOYEE",
  "initiateEmployee",
  async (data) => {
    reducerFactory.action("loading", true);
    const { month, financialYear } = config.checkFinancialYear();
    let employeeData = await employee.getEmployeeDetails(
      `${data}/${month}/${financialYear}`
    );

    let broadCastData = await employee.getBroadcastMessages(3);
    let settlementData = await org.getEmployeeSettlement(data);
    let empOnboarding = await employee.getEmployeeOnBoarding(
      employeeData.data.data.user.organisationId,
      employeeData.data.data.user._id
    );
    return {
      employeeData: employeeData.data,
      broadCastData: broadCastData,
      settlementData: settlementData.data,
      empOnboarding: empOnboarding.data,
    };
  },
  (state, action) => {
    const {
      ctc,
      organisation: orgDetails,
      declaration,
      payslip,
      bonuses,
      reimbursements,
      monthClose,
      group,
      freezeInfo,
      department,
      status,
      designation,
      groups,
      location,
      settlement,
      organisationFreeze,
      previousEmployement,
      taxSettlement,
      supervisor,
      leaveBalanceReport,
      holidays,
      attendances,
      leaveLogs,
      monthlyReport,
      todayAttendance,
      weeklyOffs,
    } = action.data.employeeData.data;
    const {
      deductions,
      allowances,
      perquisites,
      houseRent,
      customAllowances,
      percentage,
    } = declaration.declarations;
    const { advanceTaxConfig } = declaration;
    const { month, financialYear } = config.checkFinancialYear();
    if (leaveBalanceReport) {
      let leaveList = Object.keys(leaveBalanceReport.leaveTypes).map(
        (val) => leaveBalanceReport.leaveTypes[val]
      );
      state.leaveList = [...leaveList];
    }

    if (perquisites && Object.keys(perquisites).length > 0) {
      state.perquisites = perquisites;
    } else if (
      orgDetails.organisationSettings.perquisites &&
      Object.keys(orgDetails.organisationSettings.perquisites).length > 0
    ) {
      state.perquisites = orgDetails.organisationSettings.perquisites
    }

    state.organisationSettings = orgDetails.organisationSettings;
    state.advanceTaxConfig = advanceTaxConfig;
    state.percentage = percentage;
    state.financialYear = financialYear;
    state.month = month;
    state.organisationSettings = orgDetails.organisationSettings;
    state.payslips = payslip;
    state.ctc = ctc;
    state.freezeInfo = freezeInfo;
    state.hra = houseRent;
    state.reimbursements = reimbursements;
    state.deductions = deductions;
    state.allowances = allowances;
    state.customAllowances = customAllowances;
    state.orgInfo = orgDetails;
    state.group = group;
    state.supervisor = supervisor;
    state.organisationFreeze = organisationFreeze;
    state.structureOfMool = orgDetails.moolSettings
      ? orgDetails.moolSettings.structureOfMool
      : false;
    state.isPercentageStructure = orgDetails.moolSettings
      ? orgDetails.moolSettings.isPercentageStructure
      : false;
    state.monthClose = monthClose;
    state.bonuses = bonuses;
    state.declaration = declaration;
    state.broadcastMessage = action.data.broadCastData.data;
    state.separations = action.data.settlementData;
    state.department = department || {};
    state.status = status || {};
    state.designation = designation || {};
    state.groups = groups || {};
    state.location = location || {};
    state.settlement = settlement || {};
    state.loading = false;
    state.onboarding = action.data.empOnboarding;
    state.previousEmployement = previousEmployement;
    state.taxSettlement = taxSettlement;
    state.leaveBalanceReport = leaveBalanceReport;
    state.holidays = holidays;
    state.attendances = attendances;
    state.leaveLogs = leaveLogs;
    state.monthlyReport = monthlyReport;
    state.todayAttendance = todayAttendance;
    state.weeklyOffs = weeklyOffs;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "EMPLOYEE_TOUR",
  "employeeTour",
  (status) => status,
  (state, action) => {
    state.employeeTour = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "SET_CUSTOM_POINT",
  "setCustomPoint",
  (data) => data,
  (state, action) => {
    state.customPoint = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "SET_LAST_PAYSLIP_DATA",
  "setLastPayslipData",
  (data) => data,
  (state, action) => {
    state.customPoint = action.data.customPoint;
    state.moolData = action.data.moolData;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "GET_PAYSLIPS",
  "getPayslips",
  async (data) => {
    let res = await employee.getPayslips(data);
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      state.payslips = action.data.data;
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "SAVE_PAYSLIP",
  "savePayslip",
  async (data) => {
    const { user, finalPayslip, selectedIndex } = data;
    const { month, financialYear } = checkFinancialYear();
    let res = await org.createPayslip({
      user,
      finalPayslip,
      selectedIndex,
      customPoint: 0.66,
      freezedPayslip: false,
      month,
      financialYear,
    });
    if (res.data.success) {
      reducerFactory.action("getPayslips", user._id);
    }
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      state.isChanged = false;
      notification.success({ message: "Payslip saved successfully" });
    } else {
      notification.error({ message: "Sorry unable to save the payslip" });
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "ASK_MOOL",
  "askMool",
  async (data) => {
    const { financialYear, month, id, user, ctc } = data;
    let res = await employee.generatePayslip({
      id,
      financialYear,
      month,
      getGraphDataByKey: false,
      sendGraphData: true,
      savePayslip: false,
    });
    reducerFactory.action("getPayslips", user._id);
    let payslip = await employee.getCurrentPayslip(user._id);

    return {
      data: res.data,
      payslip: payslip.data,
      selectedIndex: ctc.selectedIndex || 0,
    };
  },
  (state, action) => {
    let selectedIndex = action.data.selectedIndex;
    let finalPayslip = {};
    if (!action.data.payslip.finalPayslip) {
      if (selectedIndex === 0 || action.data.data.graphData.length === 0) {
        finalPayslip = action.data.data.finalPayslip;
        state.selectedIndex = selectedIndex;
        state.isChanged = true;
      } else {
        finalPayslip = action.data.data.graphData[selectedIndex][3];
        state.selectedIndex = selectedIndex;
        state.isChanged = true;
      }
    } else {
      state.isChanged = false;
      finalPayslip = action.data.payslip.finalPayslip;
      state.selectedIndex = action.data.payslip.selectedIndex;
    }
    state.finalPayslip = finalPayslip;
    let minPF = 0;
    let minVPF = 0;
    let marker = {};
    let previous_Employee_PF = 0;
    let previous_Employer_PF = 0;
    let previous_new_regime_deductions = 0;
    let previous_old_regime_deductions = 0;
    if (action.data.data.graphData.length > 0) {
      for (let i = 0; i < 1000; i++) {
        let tempPayslip = action.data.data.graphData[i][3];
        if (tempPayslip.employee_PF / 1800 > 1) {
          minPF = i - 1;
        }
        if (tempPayslip.employee_PF > tempPayslip.employer_PF) {
          minVPF = i - 1;
          break;
        }
      }
      for (let i = 0; i <= 1000; i++) {
        let tempPayslip = action.data.data.graphData[i][3];
        const { employee_PF, employer_PF } = tempPayslip;
        if (((2 * employer_PF) + (employee_PF - employer_PF)) * 12 > 250000) {
          tempPayslip.employee_PF = previous_Employee_PF;
          tempPayslip.employer_PF = previous_Employer_PF;
          tempPayslip.newRegime.deductions = previous_new_regime_deductions;
          tempPayslip.oldRegime.deductions = previous_old_regime_deductions;
          tempPayslip.oldRegime.takeHome = tempPayslip.totalEarnings - previous_old_regime_deductions;
        }
        previous_Employee_PF = tempPayslip.employee_PF
        previous_Employer_PF = tempPayslip.employer_PF
        previous_new_regime_deductions = tempPayslip.newRegime.deductions
        previous_old_regime_deductions = tempPayslip.oldRegime.deductions
        action.data.data.graphData[i][3] = tempPayslip;
      }
    }
    if (minVPF - minPF > 30) {
      marker[minPF] = "Minimum PF";
    }
    state.moolData = action.data.data
    marker[minVPF + 1] = "VPF Start";
    state.marker = { ...marker };
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_SLIDER",
  "updateSlider",
  (data) => data,
  (state, action) => {
    let sliderValue = action.data;
    if (!sliderValue) {
      sliderValue = 0;
    }
    state.selectedIndex = sliderValue;
    state.isChanged = true;
    state.finalPayslip = state.moolData.graphData[sliderValue][3];
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "EMPLOYEE_REIMBURSEMENT",
  "employeeReimbursement",
  async (data) => {
    let res = await employee.employeeReimbursement(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.reimbursements = action.data;
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "EMPLOYEE_BROADCAST_MESSAGE",
  "employeeBroadcastMessage",
  async (data) => {
    let res = await employee.getBroadcastMessages(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.broadcastMessage = action.data;
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "EMPLOYEE_NEW_SETTLEMENT",
  "employeeNewSettlement",
  (data) => data,
  (state, action) => {
    if (action.data) {
      state.separations = action.data;
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_ALLOWANCE",
  "updateAllowances",
  (data) => data,
  (state, action) => {
    state.allowances = action.data.allowances;
    state.customAllowances = action.data.customAllowances;
    state.hra = action.data.hra;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_EMPLOYEE_ONBOARDING",
  "updateEmployeeOnBoarding",
  async (data) => {
    const { organisationId, id } = data;
    let res = await employee.getEmployeeOnBoarding(organisationId, id);
    return res.data;
  },
  (state, action) => {
    if (action.data || action.data === "") {
      state.onboarding = action.data;
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_EMPLOYEE_REBALANCE",
  "updateEmployeeRebalancing",
  async (data) => {
    const { financialYear, id } = data;
    let res = await employee.getPreviousEmploymentData(
      `${id}/${financialYear}`
    );
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.previousEmployement = action.data;
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE EMPLOYEE_ATTENDANCE",
  "updateTodayAttendance",
  async (data) => {
    let employeeAttendanceInfo = await employee.inititateEmployee(data.id);
    return {
      attendanceInfo: employeeAttendanceInfo.data,
    };
  },
  (state, action) => {
    const {
      holidays,
      attendances,
      leaveLogs,
      monthlyReport,
      todayAttendance,
      weeklyOffs,
    } = action.data.attendanceInfo;
    state.todayAttendance = todayAttendance;
    state.attendances = attendances;
    state.holidays = holidays;
    state.leaveLogs = leaveLogs;
    state.monthlyReport = monthlyReport;
    state.weeklyOffs = weeklyOffs;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_LEAVE_LOGS",
  "updateLeaveLogs",
  async (data) => {
    const { id, organisationId } = data;
    let res = await employee.employeeLeaveLogs({ id, organisationId });
    return {
      leaveLogs: res.data,
    };
  },
  (state, action) => {
    state.leaveLogs = action.data.leaveLogs;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_ATTENDANCE",
  "updateAttendance",
  async (data) => {
    const { id, month, year } = data;
    let res = await employee.updateAttendance(`${id}/${month}/${year}`);
    return {
      attendances: res.data,
    };
  },
  (state, action) => {
    state.attendances = action.data.attendances;
    return Object.assign({}, state);
  }
);

export default reducerFactory;
