/* eslint-disable no-unused-vars */
import { Button, Col, notification, Row, Skeleton, TimePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  daylist,
  getWeekList,
  shortMonthList,
} from "../../../../utils/config";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { InputField, NormalButton } from "../../../ComponentLibrary";
import Modal from "antd/lib/modal/Modal";
import { logLOPData } from "../../../../apis/employee";
import moment from "moment";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";

/**
 *
 * Type of Attendace numerical type.
 * 0: Attendance
 * 1: Holiday,
 * 2: LOP,
 * 3: OFF,
 */

/**
 *
 * Type of extra information numerical type.
 * 1: Holiday
 * 2: Lop
 */
const format = "HH:mm";

const AttendanceReport = ({
  monthlyReport,
  holidays,
  leaveLogs,
  attendances,
  user,
  weeklyOffs,
  employeeReducer,
}) => {
  const selectedMonth = new Date().getMonth();
  const [extraInformation, setExtraInformation] = useState([]);
  const [attendancePerWeek, setAttendancePerWeek] = useState([]);
  const [lopModal, setLOPModal] = useState({
    modal: false,
    loading: false,
  });
  const [lopDetails, setLOPDetails] = useState({
    dateIn: 0,
    dateOut: 0,
    PAN: "",
    id: "",
    currDate: 0,
    type: 1,
    reason: "",
  });
  const startRef = useRef(null);
  const endRef = useRef(null);

  const submitLOP = async () => {
    if (lopDetails.dateIn === 0 || lopDetails.dateIn === undefined) {
      notification.error({ message: "Please Select the start time" });
      return;
    }
    if (lopDetails.dateOut === 0 || lopDetails.dateOut === undefined) {
      notification.error({ message: "Please Select the end time" });
      return;
    }
    setLOPModal({
      ...lopModal,
      loading: true,
    });
    let res = await logLOPData({ ...lopDetails, id: user._id, type: 1 });
    if (res.data.success) {
      employeeReducer.updateTodayAttendance({ id: user._id });
      notification.success({ message: "Successfully submited the LOP" });
    } else {
      notification.error({ message: "Unable to submit the LOP" });
    }
    resetLOPModal();
  };

  const resetLOPModal = () => {
    setLOPModal({
      modal: false,
      loading: false,
    });
    setLOPDetails({
      dateIn: null,
      dateOut: null,
      PAN: "",
      id: "",
      currDate: 0,
      type: 1,
      reason: "",
    });
  };

  const increaseWeekIndex = () => {
    if (selectedWeekIndex + 1 < monthWeeks.length) {
      setSelectedWeekIndex(selectedWeekIndex + 1);
    }
  };

  const decreaseWeekIndex = () => {
    if (selectedWeekIndex - 1 >= 0) {
      setSelectedWeekIndex(selectedWeekIndex - 1);
    }
  };

  const [monthWeeks, setMonthWeeks] = useState([{ weekStart: 0, weekEnd: 0 }]);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);

  const createAttendanceReport = () => {
    let weekList = getWeekList(selectedMonth, new Date().getFullYear());
    setMonthWeeks([...weekList]);
    let today = new Date().getDate();

    for (let i = 0; i < weekList.length; i++) {
      if (today >= weekList[i].weekStart && today <= weekList[i].weekEnd) {
        setSelectedWeekIndex(i);
        break;
      }
    }
    if (Object.keys(monthlyReport).length > 0) {
      let monthAttendance = weekList.map((val) => {
        let currDate = new Date();
        let fullYear = currDate.getFullYear();
        let getCurrDate = currDate.getDate();
        let getCurrMonth = currDate.getMonth();
        let currentWeekData = [];
        for (let i = val.weekStart; i <= val.weekEnd; i++) {
          let date = `${selectedMonth + 1}/${i}/${fullYear}`;
          let isAttendanceAvailable = false;
          let traverseDate = new Date(date);
          let traverseDateOfMonth = traverseDate.getDate();
          let traverseMonth = traverseDate.getMonth();
          let traverseYear = traverseDate.getFullYear();
          let dayOfWeek = traverseDate.getDay();
          if (
            dayOfWeek === weeklyOffs.weeklyOffOne ||
            dayOfWeek === weeklyOffs.weeklyOffTwo
          ) {
            currentWeekData.push({
              type: 3,
              date: date,
              message: "Weekly Off",
              lopDetails: false,
            });
            continue;
          }
          // Check for weekly off or not;

          // Check Holiday or not
          let isHoliday = [];
          holidays.forEach((_val) => {
            let tempMonth = traverseMonth + 1;
            if (
              _val.month === tempMonth &&
              _val.year === traverseYear &&
              _val.day === traverseDateOfMonth
            ) {
              isHoliday.push(_val);
            }
          });
          if (isHoliday.length > 0) {
            currentWeekData.push({
              type: 1,
              date: date,
              message: isHoliday[0].name,
              lopDetails: false,
            });
            continue;
          }
          let isLeave = leaveLogs.some((_val) => {
            let leaveStartDate = new Date(_val.startDate);
            let leaveEndDate = new Date(_val.endDate);
            let tempLeaveStartMonth = leaveStartDate.getMonth();
            let tempLeaveStartDate = leaveStartDate.getDate();
            let tempLeaveStartYear = leaveStartDate.getFullYear();
            let tempLeaveEndMonth = leaveEndDate.getMonth();
            let tempLeaveEndDate = leaveEndDate.getDate();
            let tempLeaveEndYear = leaveEndDate.getFullYear();
            if (_val.status === 1 || _val.status === 0) {
              if (
                fullYear >= tempLeaveStartYear &&
                fullYear <= tempLeaveEndYear &&
                selectedMonth <= tempLeaveEndMonth &&
                selectedMonth >= tempLeaveStartMonth &&
                i <= tempLeaveEndDate &&
                i >= tempLeaveStartDate
              ) {
                return true;
              }
            }
            return false;
          });
          let leaveStatus = leaveLogs.some((_val) => {
            let leaveStartDate = new Date(_val.startDate);
            let leaveEndDate = new Date(_val.endDate);
            let tempLeaveStartMonth = leaveStartDate.getMonth();
            let tempLeaveStartDate = leaveStartDate.getDate();
            let tempLeaveStartYear = leaveStartDate.getFullYear();
            let tempLeaveEndMonth = leaveEndDate.getMonth();
            let tempLeaveEndDate = leaveEndDate.getDate();
            let tempLeaveEndYear = leaveEndDate.getFullYear();
            if (_val.status === 0) {
              if (
                fullYear >= tempLeaveStartYear &&
                fullYear <= tempLeaveEndYear &&
                selectedMonth <= tempLeaveEndMonth &&
                selectedMonth >= tempLeaveStartMonth &&
                i <= tempLeaveEndDate &&
                i >= tempLeaveStartDate
              ) {
                return true;
              }
            }
            return false;
          });
          if (isLeave) {
            currentWeekData.push({
              type: 3,
              date: date,
              message: leaveStatus ? "Applied For Leave" : "On Leave",
              lopDetails: false,
            });
            continue;
          }
          let attendance = [];
          attendances.forEach((_val) => {
            let tempMonth = traverseMonth + 1;
            if (
              _val.month === tempMonth &&
              _val.year === traverseYear &&
              _val.day === traverseDateOfMonth
            ) {
              attendance.push(_val);
              isAttendanceAvailable = true;
            }
          });

          if (
            !isAttendanceAvailable &&
            i > getCurrDate &&
            getCurrMonth === selectedMonth
          ) {
            currentWeekData.push({
              type: 3,
              date: date,
              message: "Not Available",
              lopDetails: false,
            });
            continue;
          }

          if (attendance.length > 0) {
            let tempAtateStart = new Date(attendance[0].start);
            let tempAtateEnd = new Date(attendance[0].end);
            let { minutes } = attendance[0];
            currentWeekData.push({
              type: 0,
              date: date,
              message: `Logged In: ${tempAtateStart.getHours()}:${tempAtateStart.getMinutes()} | 
                Logged Out:  ${attendance[0].end
                  ? `${tempAtateEnd.getHours()}:${tempAtateEnd.getMinutes()}`
                  : "Not Available"
                } ${!isNaN(parseInt(minutes))
                  ? `| Total Woked Hour: ${Math.floor(
                    minutes / 60
                  )}h ${minutes % 60}m`
                  : ``
                }`,
              lopDetails: false,
            });
            continue;
          }
          currentWeekData.push({
            type: 2,
            date: date,
            message: "LOP",
            lopDetails: true,
          });
        }
        return currentWeekData;
      });
      setAttendancePerWeek([...monthAttendance]);
    }
  };

  let styles = {
    border:  "2px #000000 solid",
    width:  "288px",
    maxWidth:  "100%",
    height: "50px",
    borderRadius: "8px",
    marginTop: "8px"
  };

  useEffect(() => {
    createAttendanceReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendances]);

  useEffect(() => {
    let refinedHoliday = holidays.map((val) => {
      return {
        type: 1,
        name: val.name,
        details: val.description,
        date: val.date,
      };
    });
    setExtraInformation([...refinedHoliday]);
  }, [holidays]);

  return (
    <Row className="h-full">
      <Col xs={24} sm={24} md={14} lg={14} style={{ borderRadius: 5 }}>
        <div className="weekly-attendance">
          {Object.keys(monthlyReport).length > 0 ? (
            <React.Fragment>
              <div className="attendance-header">
                <div className="flex align-center">
                  <Button
                    style={{
                      background: "#e1e6ff",
                      color: "#405cd2",
                      border: "none",
                    }}
                    shape="circle"
                    icon={<LeftOutlined />}
                    size="middle"
                    onClick={decreaseWeekIndex}
                  />

                  <span className="week-date">
                    {`${monthWeeks[selectedWeekIndex].weekStart} - ${monthWeeks[selectedWeekIndex].weekEnd}`}
                  </span>
                  <Button
                    style={{
                      background: "#e1e6ff",
                      color: "#405cd2",
                      border: "none",
                    }}
                    shape="circle"
                    icon={<RightOutlined />}
                    size="middle"
                    onClick={increaseWeekIndex}
                  />
                </div>
              </div>
              <div className="attendance-container">
                {attendancePerWeek[selectedWeekIndex] !== undefined &&
                  attendancePerWeek[selectedWeekIndex].map((val, index) => (
                    <DaysAttendance
                      {...val}
                      setLOPModal={setLOPModal}
                      setLOPDetails={setLOPDetails}
                      lopDetail={lopDetails}
                      key={`attendance-selected-${index}`}
                    />
                  ))}
                <Modal
                  onCancel={resetLOPModal}
                  visible={lopModal.modal}
                  footer={null}
                  centered
                  width={350}
                >
                  <Spinner spinning={lopModal.loading} size={"large"}>
                    <h3 className="app-header-3">Submit LOP</h3>
                    <div className="app-input-field-container--3">
                      {/* <p className="app-label-2">Date</p>
                      <InputField
                        value={new Date(lopDetails.currDate).toDateString()}
                        disabled={true}
                      /> */}
                      <InputFieldComponent
                        labeltext={"Date"}
                        style = {styles}
                        bordered={false}
                        value={new Date(lopDetails.currDate).toDateString()}
                      />
                    </div>
                    <div>
                      {/* <p className="app-label-2">Reason</p> */}
                      <InputFieldComponent
                        labeltext={"Reason"}
                        value={lopDetails.reason}
                        onChange={(e) => {
                          setLOPDetails({
                            ...lopDetails,
                            reason: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="datePickerDiv">
                      {/* <p className="app-label-2">Start Time</p> */}
                      <TimePicker
                        // value={moment(start, format)}
                        style={styles}
                        ref={startRef}
                        format={format}
                        onChange={(val) => {
                          let cDate = new Date(lopDetails.currDate);
                          let dateString = `${cDate.getMonth() +
                            1}/${cDate.getDate()}/${cDate.getFullYear()} ${moment(
                              val
                            ).format("hh:mm:ss a")}`;
                          setLOPDetails({
                            ...lopDetails,
                            dateIn: new Date(dateString).getTime(),
                          });
                        }}
                      />
                      <label
                        className={"labelup"} style={{marginTop:"10px"}} 
                      >
                        {"Start Time"}
                      </label>
                    </div>
                    <div className="app-input-field-container--3 datePickerDiv">
                      {/* <p className="app-label-2">End Time</p> */}
                      <TimePicker
                        // value={moment(end, format)}
                        style={styles}
                        format={format}
                        ref={endRef}
                        onChange={(val) => {
                          let cDate = new Date(lopDetails.currDate);
                          let dateString = `${cDate.getMonth() +
                            1}/${cDate.getDate()}/${cDate.getFullYear()} ${moment(
                              val
                            ).format("hh:mm:ss a")}`;
                          setLOPDetails({
                            ...lopDetails,
                            dateOut: new Date(dateString).getTime(),
                          });
                        }}
                      />
                      <label
                        className={"labelup"} style={{marginTop:"10px"}} 
                      >
                        {"End Time"}
                      </label>
                    </div>
                    <div className="text-align-right m-top-16 add-new-leave-modal-body">
                      <NormalButton
                        buttonText="Cancel"
                        margin={[0, 10]}
                        type={5}
                        onClick={resetLOPModal}
                      />
                      <NormalButton
                        buttonText={"Submit"}
                        type={13}
                        onClick={submitLOP}
                      />
                    </div>
                  </Spinner>
                </Modal>
              </div>
            </React.Fragment>
          ) : (
            [1, 2, 3, 4, 5].map((val) => (
              <Skeleton
                key={`attendance-skeleton-${val}`}
                active={true}
                loading={Object.keys(monthlyReport).length === 0}
                paragraph={{ rows: 3 }}
              />
            ))
          )}
        </div>
      </Col>
      <Col xs={24} sm={24} md={10} lg={10}>
        <div className="weekly-report">
          <h4 className="app-heading-5">Total Month Data</h4>
          <Row>
            <Col sm={12} xs={12} lg={12} md={12}>
              <Skeleton
                active={true}
                loading={Object.keys(monthlyReport).length === 0}
                paragraph={{ rows: 2 }}
              >
                <WeeklyReport
                  label="Average Working Hours"
                  borderRight={true}
                  value={Math.floor(
                    monthlyReport.workingMinutes /
                    (60 * monthlyReport.workingDays)
                  )}
                />
              </Skeleton>
            </Col>
            <Col sm={12} xs={12} lg={12} md={12}>
              <Skeleton
                active={true}
                loading={Object.keys(monthlyReport).length === 0}
                paragraph={{ rows: 2 }}
              >
                <WeeklyReport
                  label="Working Hours"
                  borderRight={false}
                  value={Math.floor(monthlyReport.workingMinutes / 60)}
                />
              </Skeleton>
            </Col>
            <Col sm={12} xs={12} lg={12} md={12}>
              <Skeleton
                active={true}
                loading={Object.keys(monthlyReport).length === 0}
                paragraph={{ rows: 2 }}
              >
                <WeeklyReport
                  label="Working Days"
                  borderRight={true}
                  value={monthlyReport.workingDays}
                />
              </Skeleton>
            </Col>
            <Col sm={12} xs={12} lg={12} md={12}>
              <Skeleton
                active={true}
                loading={Object.keys(monthlyReport).length === 0}
                paragraph={{ rows: 2 }}
              >
                <WeeklyReport
                  label="Leaves"
                  borderRight={false}
                  value={Math.floor(monthlyReport.leaves)}
                />
              </Skeleton>
            </Col>
          </Row>
          {extraInformation.length > 0 && (
            <h4 className="app-heading-5">Extra Information</h4>
          )}
          <div
            style={{
              maxHeight: 200,
              overflowY: "scroll",
            }}
          >
            {extraInformation.map((val, index) => (
              <ExtraInformation {...val} key={`extra-${index}`} />
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

const DaysAttendance = ({
  type,
  date,
  message,
  lopDetails = false,
  setLOPModal,
  setLOPDetails,
}) => {
  let dateTimeValues = new Date(date);
  return (
    <div className={`attendace attendace-${lopDetails ? 4 : type}`}>
      <div className="date-time-container">
        <p className="day">{daylist[dateTimeValues.getDay()]}</p>
        <p className="month">{`${shortMonthList[dateTimeValues.getMonth()]
          } ${dateTimeValues.getDate()}`}</p>
      </div>
      <div className="misc-attendance">
        <p className="message-container">{message}</p>
        {lopDetails && (
          <NormalButton
            buttonText="Take Action"
            onClick={() => {
              setLOPModal({ modal: true, loading: false });
              setLOPDetails({
                ...lopDetails,
                currDate: dateTimeValues.getTime(),
              });
            }}
            type={8}
          />
        )}
      </div>
    </div>
  );
};

const WeeklyReport = ({ label, value, borderRight }) => {
  return (
    <div
      style={{
        borderRight: borderRight ? `0.6px solid #DCDCDC` : 0,
        padding: borderRight ? "10px 20px 10px 0px" : "10px 0px 10px 20px",
        marginBottom: 15,
      }}
    >
      <p className="weekly-info-header">{label}</p>
      <p className="weekly-info-body">{value}</p>
    </div>
  );
};

const ExtraInformation = ({ type, name, details, date }) => {
  let dateTimeValues = new Date(date);
  return (
    <div className={`extra-information extra-inforamtion-${type}`}>
      <p className={`header`}>{name}</p>
      <p className={`info`}>{`${dateTimeValues.getDate()} ${shortMonthList[dateTimeValues.getMonth()]
        }, ${dateTimeValues.getFullYear()} ${type === 1 ? `| ${details}` : ""
        }`}</p>
    </div>
  );
};
export default AttendanceReport;
