const apiURLs = {
    dev: "https://apidev.moolfinance.com",
    prod: "https://endpoint.moolfinance.com",
    local: "http://localhost:4001",
    sandbox: "https://apidev.moolfinance.com"
}
const attendance = {
    dev: "https://attendance.moolfinance.com",
    prod: "https://attendance.moolfinance.com",
    local: "https://attendance.moolfinance.com",
    sandbox: "https://attendance.moolfinance.com"
}

const payslips = {
    dev: "http://devpayslip.moolfinance.com",
    prod: "https://payslip.moolfinance.com",
    local: "http://devpayslip.moolfinance.com",
    sandbox: "https://payslip.moolfinance.com"
}

const env = process.env.REACT_APP_ENVIRONMENT;
const gTagKey = process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY;
const gAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
const hotJarKey = process.env.REACT_APP_GOOGLE_HOTJAR_KEY;
const hotJarSv = process.env.REACT_APP_GOOGLE_HOTJAR_SV;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const clarityID = process.env.REACT_APP_CLARITY_ID;


module.exports = {
    endpoint: apiURLs[env],
    attendance: attendance[env],
    env,
    payslipURL: payslips[env],
    gTagKey,
    gAnalyticsKey,
    hotJarKey,
    hotJarSv,
    googleClientId,
    clarityID
}
