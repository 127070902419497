import "./normalButton.less";

const NormalButton = ({ float, type, block, onClick, disabled, href, icon, target, fontSize, padding, buttonText, margin, heightWidth, color, download, secondaryIcon, minHeight, minWidth, antIcon }) => {
    let styles = {
        color: color,
        fontSize: fontSize,
        display: block ? "block" : "inline-block",
        padding: padding ? `${padding[0]}px ${padding[1]}px` : "",
        margin: margin ? `${margin[0]}px ${margin[1]}px` : "",
        height: heightWidth ? heightWidth[0] : "",
        width: block ? "100%" : heightWidth ? heightWidth[1] : "",
        float: float,
        opacity: disabled ? "0.5" : "1",
        minHeight: minHeight ? minHeight : "",
        minWidth: minWidth ? minWidth : ""
    }


    return (
        <button
            className={` button-lib button-type-${type}`}
            onClick={onClick}
            disabled={disabled}
            href={href}
            target={href ? target : ""}
            style={styles}
            download={download ? "download" : ""}
        >
            <div className="button-lib-content">
                {
                    icon
                        ? <img style={{ display: block, height: 18, width: 18 }} alt="pri-icon" src={icon} className="button-lib-icon"></img>
                        : antIcon ?? ""
                }
                <div >{buttonText}</div>
                {
                    secondaryIcon ? <img style={{ display: block, height: 18, width: 18 }} src={secondaryIcon} alt="sec-icon" className="button-lib-icon m-left-10" /> : ""
                }
            </div>
        </button>
    )
}

export default NormalButton;