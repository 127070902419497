import { useState, useEffect } from "react";
import { Col, Radio, Row, Spin } from "antd";
import { NormalButton } from "../../../ComponentLibrary";
import config, { infoTextForGeneral } from "../../../../utils/config";
import editIcon from "../../../../assets/icons/edit.svg";
import InfoBtn from "../../../ComponentLibrary/InfoButtonComponent/InfoBtn";
import {
  orgPFContribution,
  percentage12PFContribution,
  pfContributionType,
  options,
  monthConfig,
  STATUTORY_BONUS_OPTION,
  CTC_GROSS,
  StatutoryBonusIncludeHRA,
} from "../../../../constants/radioOptions";
import { HeaderComponent } from "../../../Common";
import { connect } from "react-redux";
import organisation from "../../../../redux/modules/organisation";
import { createLog } from "../../../../apis/user";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const General = ({
  organisationSettings,
  organisationReducer,
  user,
  orgLoading,
  isTrial
}) => {
  const [editable, setEditable] = useState(false);
  const [orgSettings, setOrgSettings] = useState({
    ...organisationSettings,
    overtimeSetting: {},
    allowances: {},
    customAllowances: {},
    pfEnabled: true,
    gratuity: false,
    gratuityTenure: 0,
    payrollFreezeDate: 0,
    variableAboveCTC: false,
    variablePayoutMonth: 0,
    organisationPFContribution: false,
    employeePFContribution: false,
    pfContributionType: true,
    pfCalculatedOnBasic: true,
    orgPFContribution: false,
    percentageStructure: {},
    autoRebalance: false,
    esicEnabled: true,
    isEmployerContributionIncludedInCTC: true,
    isStatutoryBonusIncludedInSalary: true,
    isWeekOffsExcludedInLOP: false,
    isHolidayExcludedInLOP: false,
    isStatutoryBonusIncludeHRA: false,
    isStatutoryBonusIncludedInPF: true,
    isLopCalculatedFromCTC: true,
    salaryCycleDate: -1,
    isCurrentMonth: true,
    lwfEligible: true,
    isEmployeeOfAirlinesOrRailways: false,
  });
  useEffect(() => {
    document.title = "Settings | Global Configuration";
  });
  useEffect(() => {
    updateOrgSettings(organisationSettings);
  }, [organisationSettings]);

  const updateOrgSettings = (orgSett) => {
    setOrgSettings({
      ...orgSett,
      overtimeSetting: orgSett.overtimeSetting || {
        component: "Basic",
        factor: 1,
        workingHours: 8,
      },
      allowances: orgSett.allowances || {},
      customAllowances: orgSett.customAllowances || {},
      pfEnabled:
        typeof orgSett.pfEnabled === "boolean" ? orgSett.pfEnabled : true,
      gratuity: orgSett.gratuity || false,
      gratuityTenure: orgSett.gratuityTenure || 5,
      payrollFreezeDate: orgSett.payrollFreezeDate || 20,
      variableAboveCTC: orgSett.variableAboveCTC || false,
      variablePayoutMonth: orgSett.variablePayoutMonth || 3,
      salaryCycleDate: orgSett.salaryCycleDate || -1,
      organisationPFContribution:
        orgSett.organisationPFContribution || false,
      employeePFContribution: orgSett.employeePFContribution || false,
      pfContributionType:
        typeof orgSett.pfContributionType === "boolean"
          ? orgSett.pfContributionType
          : true,
      pfCalculatedOnBasic:
        typeof orgSett.pfCalculatedOnBasic === "boolean"
          ? orgSett.pfCalculatedOnBasic
          : true,
      isEmployerContributionIncludedInCTC:
        typeof orgSett.isEmployerContributionIncludedInCTC === "boolean"
          ? orgSett.isEmployerContributionIncludedInCTC
          : true,
      isStatutoryBonusIncludedInSalary:
        typeof orgSett.isStatutoryBonusIncludedInSalary === "boolean"
          ? orgSett.isStatutoryBonusIncludedInSalary
          : true,
      orgPFContribution:
        typeof orgSett.orgPFContribution === "boolean"
          ? orgSett.orgPFContribution
          : false,
      lwfEligible:
        typeof orgSett.lwfEligible === "boolean"
          ? orgSett.lwfEligible
          : true,
      percentageStructure: orgSett.percentageStructure || {},
      autoRebalance: orgSett.autoRebalance || false,
      esicEnabled: orgSett.esicEnabled || false,
      isWeekOffsExcludedInLOP:
        typeof orgSett.isWeekOffsExcludedInLOP === "boolean"
          ? orgSett.isWeekOffsExcludedInLOP
          : false,
      isHolidayExcludedInLOP:
        typeof orgSett.isHolidayExcludedInLOP === "boolean"
          ? orgSett.isHolidayExcludedInLOP
          : false,
      isStatutoryBonusIncludeHRA:
        typeof orgSett.isStatutoryBonusIncludeHRA === "boolean"
          ? orgSett.isStatutoryBonusIncludeHRA
          : false,
      isStatutoryBonusIncludedInPF:
        typeof orgSett.isStatutoryBonusIncludedInPF === "boolean"
          ? orgSett.isStatutoryBonusIncludedInPF
          : true,
      isLopCalculatedFromCTC:
        typeof orgSett.isLopCalculatedFromCTC === "boolean"
          ? orgSett.isLopCalculatedFromCTC
          : true,
      isCurrentMonth:
        typeof orgSett.isCurrentMonth === "boolean"
          ? orgSett.isCurrentMonth
          : true,
    });
  };

  const onSave = async () => {
    organisationReducer.updateSettings({
      organisationSettings: orgSettings,
      organisationId: user.organisationId,
    });
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `User has updated the general configuration`,
      category: 14,
    });
    setEditable(false);
  };

  const onCancel = () => {
    updateOrgSettings(organisationSettings);
    setEditable(false);
  };

  let workingHoursArray = [];
  let SalaryCycleDateArray = ["End of Month"];
  let SalaryCycleDateObject = {
    "End of Month": -1,
  };
  for (let i = 1; i < 30; i++) {
    SalaryCycleDateArray.push(i);
    SalaryCycleDateObject[i] = i;
    if (i <= 10) workingHoursArray.push(i);
  }

  return (
    isTrial ? <TrialPage user={user} trialMessage={"Configure Mool as per your company policy"} bgImage={configurationsBgImage}
    /> :
      <div className="site-layout">
        <HeaderComponent
          show={false}
          // icon={ }
          name="Global Configuration"
        />
        <div className="app-main-content-container paper-wrapper">
          <Spin spinning={orgLoading}>
            <div className="flex justify-between">
              <div>
                <h1 className="h1-bold-24 m-bottom-5">General</h1>
                <p className="tab-subtitle-1 grey">
                  Configure the global settings for your organization. This
                  will be applied to all employees within the organization.{" "}
                </p>
              </div>
              <div>
                {editable ? (
                  <div>
                    <NormalButton
                      type={5}
                      buttonText="Cancel"
                      onClick={onCancel}
                    />
                    <NormalButton
                      buttonText="Save"
                      type={13}
                      onClick={onSave}
                    />
                  </div>
                ) : (
                  <NormalButton
                    type={13}
                    icon={editIcon}
                    buttonText="Edit"
                    onClick={() => setEditable(true)}
                  />
                )}
              </div>
            </div>
            <div className="m-top-20">
              {orgSettings.allowances && (
                <div className="config-container">
                  <Row gutter={[100, 28]}>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Does your company enable gratuity?
                        </p>
                        <InfoBtn
                          infoText={infoTextForGeneral["gratuity"]}
                        />
                      </div>
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                gratuity: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.gratuity}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.gratuity && "Yes"}
                          {!orgSettings.gratuity && "No"}
                        </div>
                      )}
                    </Col>
                    {orgSettings.gratuity && (
                      <Col sm={24} md={14} lg={14} xl={14}>
                        <div className="flex align-center justify-start">
                          <p className="font-weight-global-700 font-size-global-18">
                            What is the gratuity tenure followed by the
                            organisation ?
                          </p>
                          <InfoBtn
                            infoText={infoTextForGeneral["gratuityTenure"]}
                          />
                        </div>
                        {editable ? (
                          <InputNumberComponent
                            min={1}
                            max={60}
                            value={orgSettings.gratuityTenure}
                            labeltext="Tenure"
                            margin={[20, 0, 20, 0]}
                            disabled={!orgSettings.gratuity || !editable}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                gratuityTenure: e,
                              })
                            }
                          />
                        ) : (
                          <div className="app-input-value">
                            {orgSettings.gratuity &&
                              orgSettings.gratuityTenure}
                            {!orgSettings.gratuity && "0"}
                          </div>
                        )}
                      </Col>
                    )}
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Does your company enable LWF?
                        </p>
                      </div>
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                lwfEligible: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.lwfEligible}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.lwfEligible && "Yes"}
                          {!orgSettings.lwfEligible && "No"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Does your company enable PF?
                        </p>
                        <InfoBtn
                          infoText={infoTextForGeneral["enablePF"]}
                        />
                      </div>
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                pfEnabled: e.target.value,
                                pfContributionType: !e.target.value
                                  ? true
                                  : orgSettings.pfContributionType,
                                orgPFContribution: !e.target.value
                                  ? false
                                  : orgSettings.orgPFContribution,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.pfEnabled}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.pfEnabled && "Yes"}
                          {!orgSettings.pfEnabled && "No"}
                        </div>
                      )}
                    </Col>
                    {orgSettings.pfEnabled && (
                      <Col sm={24} md={24} lg={24} xl={24}>
                        <p className="font-weight-global-700 font-size-global-18">
                          PF Configuration Type
                        </p>
                        {editable ? (
                          <div className="app-radio-container">
                            <Radio.Group
                              options={pfContributionType}
                              onChange={(e) =>
                                setOrgSettings({
                                  ...orgSettings,
                                  pfContributionType: e.target.value,
                                  orgPFContribution: e.target.value
                                    ? false
                                    : orgSettings.orgPFContribution,
                                })
                              }
                              disabled={!editable}
                              value={orgSettings.pfContributionType}
                            />
                          </div>
                        ) : (
                          <div className="app-input-value">
                            {orgSettings.pfContributionType &&
                              "Mool Configuration"}
                            {!orgSettings.pfContributionType &&
                              "Custom Configuration"}
                          </div>
                        )}
                      </Col>
                    )}
                    {!orgSettings.pfContributionType && (
                      <Col sm={24} md={24} lg={24} xl={24}>
                        <p className="font-weight-global-700 font-size-global-18">
                          Organisation PF Contribution
                        </p>
                        {editable ? (
                          <div className="app-radio-container">
                            <Radio.Group
                              options={orgPFContribution}
                              onChange={(e) =>
                                setOrgSettings({
                                  ...orgSettings,
                                  orgPFContribution: e.target.value,
                                })
                              }
                              disabled={!editable}
                              value={orgSettings.orgPFContribution}
                            />
                          </div>
                        ) : (
                          <div className="app-input-value">
                            {orgSettings.orgPFContribution &&
                              "12% Rule"}
                            {!orgSettings.orgPFContribution &&
                              "Minimum (INR 1800)"}
                          </div>
                        )}
                        {orgSettings.orgPFContribution && <>
                          {editable ? <>
                            <div className="app-radio-container">
                              <Radio.Group
                                options={percentage12PFContribution}
                                onChange={(e) => {
                                  setOrgSettings({
                                    ...orgSettings,
                                    pfCalculatedOnBasic: e.target.value,
                                  })
                                }
                                }
                                disabled={!editable}
                                value={orgSettings.pfCalculatedOnBasic}
                              />
                            </div>
                          </>
                            :
                            <div className="app-input-value">
                              {orgSettings.pfCalculatedOnBasic &&
                                "12% of Basic"}
                              {!orgSettings.pfCalculatedOnBasic &&
                                "12% of (Gross - HRA)"}
                            </div>
                          }
                        </>
                        }
                      </Col>
                    )}
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Variable Above CTC?
                        </p>
                        <InfoBtn
                          infoText={infoTextForGeneral["variableAboveCTC"]}
                        />
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                variableAboveCTC: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.variableAboveCTC}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.variableAboveCTC && "Yes"}
                          {!orgSettings.variableAboveCTC && "No"}
                        </div>
                      )}
                    </Col>
                    {orgSettings.variableAboveCTC && (
                      <Col sm={24} md={24} lg={24} xl={24}>
                        <div className="flex align-center justify-start">
                          <p className="font-weight-global-700 font-size-global-18">
                            Variable Payout Month
                          </p>
                          <InfoBtn
                            infoText={
                              infoTextForGeneral["varablePayoutMonth"]
                            }
                          />
                        </div>
                        {editable ? (
                          <DropdownComponent
                            margin={[20, 0, 20, 0]}
                            value={orgSettings.variablePayoutMonth}
                            options={config.monthList}
                            labeltext="Select Month"
                            filterOption={(input, option) =>
                              option.children &&
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            disabled={!editable}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                variablePayoutMonth: e,
                              })
                            }
                          />
                        ) : (
                          <div className="app-input-value">
                            {
                              config.monthList[
                              orgSettings.variablePayoutMonth
                              ]
                            }
                          </div>
                        )}
                      </Col>
                    )}
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Salary Cycle Date
                        </p>
                      </div>
                      {editable ? (
                        <DropdownComponent
                          labeltext="Select Date"
                          margin={[20, 0, 20, 0]}
                          value={orgSettings.salaryCycleDate}
                          options={SalaryCycleDateArray.map((val) => val)}
                          values={SalaryCycleDateArray.map(
                            (index) => SalaryCycleDateObject[index]
                          )}
                          disabled={!editable}
                          onChange={(e) =>
                            setOrgSettings({
                              ...orgSettings,
                              salaryCycleDate: e,
                            })
                          }
                        />
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.salaryCycleDate === -1 &&
                            "End of each month"}
                          {orgSettings.salaryCycleDate !== -1 &&
                            `${orgSettings.salaryCycleDate
                            }-${orgSettings.salaryCycleDate - 1}`}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Salary Cycle type
                        </p>
                      </div>
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={monthConfig}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isCurrentMonth: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.isCurrentMonth}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isCurrentMonth && "Next Month Cycle"}
                          {!orgSettings.isCurrentMonth &&
                            "Current Month Cycle"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Opt for Auto Tax Reconciliation?{" "}
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                autoRebalance: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.autoRebalance}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.autoRebalance && "Yes"}
                          {!orgSettings.autoRebalance && "No"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Is salary breakup calculated on CTC or Gross?
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={CTC_GROSS}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isEmployerContributionIncludedInCTC:
                                  e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={
                              orgSettings.isEmployerContributionIncludedInCTC
                            }
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isEmployerContributionIncludedInCTC &&
                            "CTC"}
                          {!orgSettings.isEmployerContributionIncludedInCTC &&
                            "GROSS"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Is statutory bonus included in salary?
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={STATUTORY_BONUS_OPTION}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isStatutoryBonusIncludedInSalary:
                                  e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={
                              orgSettings.isStatutoryBonusIncludedInSalary
                            }
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isStatutoryBonusIncludedInSalary &&
                            "Yes"}
                          {!orgSettings.isStatutoryBonusIncludedInSalary &&
                            "No"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Statutory bonus is calculated on
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={StatutoryBonusIncludeHRA}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isStatutoryBonusIncludeHRA: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.isStatutoryBonusIncludeHRA}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isStatutoryBonusIncludeHRA &&
                            "Basic + HRA"}
                          {!orgSettings.isStatutoryBonusIncludeHRA &&
                            "Basic"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Is WeeklyOff Excluded In LOP?
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isWeekOffsExcludedInLOP: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.isWeekOffsExcludedInLOP}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isWeekOffsExcludedInLOP && "Yes"}
                          {!orgSettings.isWeekOffsExcludedInLOP && "No"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Is Holidays Excluded In LOP?
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isHolidayExcludedInLOP: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.isHolidayExcludedInLOP}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isHolidayExcludedInLOP && "Yes"}
                          {!orgSettings.isHolidayExcludedInLOP && "No"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Is statutory bonus included In PF Salary?
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isStatutoryBonusIncludedInPF:
                                  e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.isStatutoryBonusIncludedInPF}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isStatutoryBonusIncludedInPF &&
                            "Yes"}
                          {!orgSettings.isStatutoryBonusIncludedInPF &&
                            "No"}
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          Is LOP calculation included in CTC?
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={options}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isLopCalculatedFromCTC: e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={orgSettings.isLopCalculatedFromCTC}
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isLopCalculatedFromCTC && "Yes"}
                          {!orgSettings.isLopCalculatedFromCTC && "No"}
                        </div>
                      )}
                    </Col>

                    <Col sm={24} md={24} lg={24} xl={24}>
                      <div className="flex align-center justify-start">
                        <p className="font-weight-global-700 font-size-global-18">
                          What type of category does your ticket fall into ?
                        </p>
                      </div>{" "}
                      {editable ? (
                        <div className="app-radio-container">
                          <Radio.Group
                            className="app-checkbox"
                            options={[
                              { label: "Airline/Railways", value: true },
                              { label: "Others", value: false },
                            ]}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                isEmployeeOfAirlinesOrRailways:
                                  e.target.value,
                              })
                            }
                            disabled={!editable}
                            value={
                              orgSettings.isEmployeeOfAirlinesOrRailways
                            }
                          />
                        </div>
                      ) : (
                        <div className="app-input-value">
                          {orgSettings.isEmployeeOfAirlinesOrRailways &&
                            "Airline/Railways"}
                          {!orgSettings.isEmployeeOfAirlinesOrRailways &&
                            "Others"}
                        </div>
                      )}
                    </Col>
                    {orgSettings.overtimeSetting && (
                      <Col sm={24} md={24} lg={24} xl={24}>
                        <div className="flex align-center justify-start">
                          <p className="font-weight-global-700 font-size-global-18">
                            Overtime Configuration
                          </p>
                        </div>
                        <Row>
                          <Col sm={24} md={24} lg={12} xl={12}>
                            {editable ? (
                              <DropdownComponent
                                margin={[20, 0, 20, 0]}
                                value={
                                  orgSettings.overtimeSetting[
                                  "component"
                                  ] || "Basic"
                                }
                                options={config.fnfCalculationBasis}
                                labeltext="Select Component"
                                disabled={!editable}
                                onChange={(e) => {
                                  setOrgSettings({
                                    ...orgSettings,
                                    overtimeSetting: {
                                      ...orgSettings.overtimeSetting,
                                      component:
                                        config.fnfCalculationBasis[e],
                                    },
                                  });
                                }}
                              />
                            ) : (
                              <div className="app-input-value">
                                <DropdownComponent
                                  margin={[20, 0, 20, 0]}
                                  value={
                                    orgSettings.overtimeSetting[
                                    "component"
                                    ] || "Basic"
                                  }
                                  options={config.fnfCalculationBasis}
                                  labeltext="Select Component"
                                  disabled={!editable}
                                  onChange={(e) => {
                                    setOrgSettings({
                                      ...orgSettings,
                                      overtimeSetting: {
                                        ...orgSettings.overtimeSetting,
                                        component:
                                          config.fnfCalculationBasis[e],
                                      },
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </Col>
                          <Col sm={24} md={24} lg={12} xl={12}>
                            {editable ? (
                              <InputNumberComponent
                                min={1}
                                max={60}
                                value={
                                  orgSettings.overtimeSetting["factor"] || 1
                                }
                                labeltext="Factor"
                                margin={[20, 0, 20, 0]}
                                disabled={!editable}
                                onChange={(e) =>
                                  setOrgSettings({
                                    ...orgSettings,
                                    overtimeSetting: {
                                      ...orgSettings.overtimeSetting,
                                      factor: e,
                                    },
                                  })
                                }
                              />
                            ) : (
                              <div className="app-input-value">
                                <InputNumberComponent
                                  min={1}
                                  max={60}
                                  value={
                                    orgSettings.overtimeSetting["factor"] ||
                                    1
                                  }
                                  labeltext="Factor"
                                  margin={[20, 0, 20, 0]}
                                  disabled={!editable}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {orgSettings.overtimeSetting && (
                      <Col sm={24} md={24} lg={24} xl={24}>
                        <div className="flex align-center justify-start">
                          <p className="font-weight-global-700 font-size-global-18">
                            Total working hours of the organisation per day{" "}
                          </p>
                        </div>
                        {editable ? (
                          <DropdownComponent
                            labeltext="Working hour"
                            margin={[20, 0, 20, 0]}
                            value={
                              orgSettings.overtimeSetting["workingHours"] ||
                              8
                            }
                            options={workingHoursArray.map((val) => val)}
                            values={workingHoursArray.map(
                              (index) => workingHoursArray[index - 1]
                            )}
                            disabled={!editable}
                            onChange={(e) =>
                              setOrgSettings({
                                ...orgSettings,
                                overtimeSetting: {
                                  ...orgSettings.overtimeSetting,
                                  workingHours: e,
                                },
                              })
                            }
                          />
                        ) : (
                          <div className="app-input-value">
                            <DropdownComponent
                              labeltext="Working hours"
                              margin={[20, 0, 20, 0]}
                              value={
                                orgSettings.overtimeSetting[
                                "workingHours"
                                ] || 8
                              }
                              options={workingHoursArray.map((val) => val)}
                              values={workingHoursArray.map(
                                (index) => workingHoursArray[index - 1]
                              )}
                              disabled={!editable}
                            />
                          </div>
                        )}
                      </Col>
                    )}
                  </Row>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
  );
};

const GeneralContainer = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(General);

export default GeneralContainer;
