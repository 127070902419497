import organisation from "../../redux/modules/organisation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import activeMcclose from "../../assets/icons/activeIcons/bdia.svg";
import { HeaderComponent } from "../Common";
import { Tabs, notification } from "antd";
import { useEffect, useState } from "react";
import { deleteMonthClose } from "../../apis/organisation";
import Bonus from "./Bonus";
import Accident from "./Accident";
// import DamageAndLoss from "./DamageAndLoss";
import Fine from "./Fine";
import General from "./General";
import Overtime from "./Overtime";
import OvertimeLop from "./OvertimeLop";
import Custom from "./Custom";
import LoanAndSalary from "./LoansAndSalaries";
import Arrears from "./Arrears";
import payrollInputsBgImage from "../../assets/dummyPages/payrollInputsBgImage.png"
import TrialPage from "../Common/TrialComponent";

const { TabPane } = Tabs;

const MonthClose = ({ monthCloseFields, user, employeeList, organisation, organisationSettings, isTrial }) => {
  const [general, setGeneral] = useState([]);
  const [accident, setAccident] = useState([]);
  const [fine, setFine] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const [lopOvertime, setLOPOvertime] = useState([]);
  // const [damage, setDamage] = useState([]);
  const [overtime, setOverTime] = useState([]);
  const [arrears, setArrears] = useState([]);
  const [custom, setCustom] = useState([]);
  const [loansAndSalaries, setLoansAndSalaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [financialYearList, setFinancialYearList] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    organisation.updateMonthClose({ organisationId: user.organisationId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  useEffect(() => {
    let startFYear = 2015;
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let resFinancialYearList = [];
    for (let i = startFYear; i < currentYear + 1; i++) {
      let nextYear = i + 1;
      let tempFYear = `${i}-${nextYear}`;
      resFinancialYearList.push(tempFYear);
    }
    setFinancialYearList([...resFinancialYearList]);
  }, [user]);

  const startRefresh = () => {
    setIsRefresh(!isRefresh);
  };

  const deleteData = async (id) => {
    if (!id || loading) {
      return;
    }
    setLoading(true);
    let res = await deleteMonthClose(id);
    if (res.data.success) {
      organisation.updateMonthClose({ organisationId: user.organisationId });
      notification.success({ message: "Successfully deleted month close" });
    } else {
      notification.error({ message: "Sorry unable to delete the month close" });
    }
    setLoading(false);
  };

  useEffect(() => {
    let gnl = [];
    let acc = [];
    let fin = [];
    let bon = [];
    let dam = [];
    let ovr = [];
    let arr = [];
    let lo = [];
    let co = [];
    let ls = [];

    monthCloseFields.forEach((val) => {
      switch (val.details) {
        case "Bonuses": { bon.push(val); break; }
        case "Arrears": { arr.push(val); break; }
        case "DamageOrLoss": { dam.push(val); break; }
        case "Fine": { fin.push(val); break; }
        case "Overtime": { ovr.push(val); lo.push(val); break; }
        case "Accident": { acc.push(val); break; }
        case "LOP_Debit": { lo.push(val); break; }
        case "Others_Credits": { co.push(val); break; }
        case "Others_Debits": { co.push(val); break; }
        case "Loans": { ls.push(val); break; }
        case "AdvanceSalary": { ls.push(val); break; }
        default: { gnl.push(val); break; }
      }
    });
    setGeneral([...gnl]);
    setAccident([...acc]);
    setFine([...fin]);
    setBonuses([...bon]);
    // setDamage([...dam])
    setLOPOvertime([...lo]);
    setOverTime([...ovr]);
    setCustom([...co]);
    setLoansAndSalaries([...ls])
    setArrears([...arr])
  }, [monthCloseFields])

  useEffect(() => {
    document.title = "Payroll | Payroll Inputs";
  });

  return (
    isTrial ? <TrialPage user={user} trialMessage={"Automated calculations for Bonus, Arrears, LOP etc. "} bgImage={payrollInputsBgImage}
    /> :
      <div className="site-layoyt">
        <HeaderComponent
          show={false}
          showMoolCoins={true}
          name="Payroll Inputs"
          icon={activeMcclose}
        />
        <div className="month-close-container">
          <Tabs defaultActiveKey="1" style={{ marginLeft: "10px" }}>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">General</span>
                </div>
              }
              key="1"
            >
              <General
                user={user}
                employeeList={employeeList}
                monthCloseFields={general}
                organisation={organisation}
                deleteData={deleteData}
                loading={loading}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Arrears</span>
                </div>
              }
              key="2"
            >
              <Arrears
                user={user}
                organisationSettings={organisationSettings}
                employeeList={employeeList}
                monthCloseFields={arrears}
                organisation={organisation}
                deleteData={deleteData}
                startRefresh={startRefresh}
                loading={loading}
                financialYearList={financialYearList}
                name={"Custom Month Close"}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Bonus</span>
                </div>
              }
              key="3"
            >
              <Bonus
                user={user}
                employeeList={employeeList}
                monthCloseFields={bonuses}
                organisation={organisation}
                deleteData={deleteData}
                loading={loading}
                startRefresh={startRefresh}
                financialYearList={financialYearList}
                name={"Bonus"}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Accident</span>
                </div>
              }
              key="4"
            >
              <Accident
                user={user}
                employeeList={employeeList}
                monthCloseFields={accident}
                organisation={organisation}
                deleteData={deleteData}
                loading={loading}
                startRefresh={startRefresh}
                financialYearList={financialYearList}
                name={"Accident"}
              />
            </TabPane>
            {/* <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name">Damage and Loss</span>
              </div>
            }
            key="4"
          >
            <DamageAndLoss
              user={user}
              employeeList={employeeList}
              monthCloseFields={damage}
              organisation={organisation}
              deleteData={deleteData}
              loading={loading}
              financialYearList={financialYearList}
            />
          </TabPane> */}
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Fine</span>
                </div>
              }
              key="5"
            >
              <Fine
                user={user}
                employeeList={employeeList}
                monthCloseFields={fine}
                organisation={organisation}
                deleteData={deleteData}
                loading={loading}
                startRefresh={startRefresh}
                financialYearList={financialYearList}
                name={"Fine"}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Overtime</span>
                </div>
              }
              key="6"
            >
              <Overtime
                user={user}
                employeeList={employeeList}
                monthCloseFields={overtime}
                organisation={organisation}
                deleteData={deleteData}
                startRefresh={startRefresh}
                organisationSettings={organisationSettings}
                loading={loading}
                financialYearList={financialYearList}
                name={"Ovetime"}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Loss Of Pay & Overday</span>
                </div>
              }
              key="7"
            >
              <OvertimeLop
                user={user}
                employeeList={employeeList}
                monthCloseFields={lopOvertime}
                organisation={organisation}
                organisationSettings={organisationSettings}
                deleteData={deleteData}
                loading={loading}
                startRefresh={startRefresh}
                financialYearList={financialYearList}
                name={"Loss Of Pay & Overtime"}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Custom</span>
                </div>
              }
              key="8"
            >
              <Custom
                user={user}
                employeeList={employeeList}
                monthCloseFields={custom}
                organisation={organisation}
                deleteData={deleteData}
                loading={loading}
                startRefresh={startRefresh}
                financialYearList={financialYearList}
                name={"Custom Month Close"}
              />
            </TabPane>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Loans & Advance Salaries</span>
                </div>
              }
              key="9"
            >
              <LoanAndSalary
                user={user}
                employeeList={employeeList}
                monthCloseFields={loansAndSalaries}
                organisation={organisation}
                deleteData={deleteData}
                startRefresh={startRefresh}
                loading={loading}
                financialYearList={financialYearList}
                name={"Custom Month Close"}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
  );
};

MonthClose.propTypes = {
  user: PropTypes.object.isRequired,
  monthCloseFields: PropTypes.array.isRequired,
};

const MonthCloseContainer = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    monthCloseFields: state.organisation.monthCloseFields,
    organisationSettings: state.organisation.organisationSettings,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisation: organisation.getActions(dispatch),
  })
)(MonthClose);

export default MonthCloseContainer;
