import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAllTransactionHistory, getCurrentReim,
  getFNF,
  getOrganisationFreezeInfo,
  getPayslipCounter,
  getReportsData,
  updatePayslipFreeze
} from "../../../apis/organisation";
import { verifyYes } from "../../../assets/common/verifiedLogos";
import organisation from "../../../redux/modules/organisation";
import {
  checkFinancialYear, numformatter,
  sumOfAll
} from "../../../utils/config";
import { NormalButton } from "../../ComponentLibrary";
import EmployeeSummary from "./EmployeeSummary";
import ProcessedPayroll from "./ProcessedPayroll";
import TransactMoney from "./TransactMoney";


const PayrollMonths = ({
  user,
  organisationReducer,
  payout,
  employeeList,
  financialYear,
  month,
}) => {
  const [payslips, setPayslips] = useState([]);
  const [reimbursement, setReimbursement] = useState([]);
  const [fnfs, setFnfs] = useState([]);
  const [orgPayslipInfo, setOrgPayslipInfo] = useState({});
  const [transferType, setTransferType] = useState(0);
  const [transactionList, setTransactionList] = useState([]);
  const [transactionModal, setTransactionModal] = useState(false);
  const [payroll, setPayroll] = useState({
    month: month,
    year: month < 3 ? financialYear.split("-")[1] : financialYear.split("-")[0],
    currFinancialYear: financialYear,
    totalEarnings: 0,
    totalPayslipsGenerated: 0,
    payrollFreeze: false,
    payrollAvailable: false,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [isFreezed, setIsFreezed] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [processedPayrolls, setProcessedPayrolls] = useState({
    grossSalary: 0,
    incomeTax: 0,
    professionalTax: 0,
    employeePF: 0,
    employerPF: 0,
    expenses: 0,
    reimbursement: 0,
    bonus: 0,
  });
  const [modal, setModal] = useState(false);
  const { financialYear: currentFinancialYear } = checkFinancialYear();

  useEffect(() => {
    getCurrentReim(`${user.organisationId}/${month}/${financialYear}`).then(
      (res) => {
        if (res.status === 200) {
          setReimbursement(res.data);
        }
      }
    );

    // FNF History
    const year = month < 3 ? financialYear.split("-")[1] : financialYear.split("-")[0];
    getFNF(
      `${user.organisationId}/${month}/${year}`
    ).then((res) => {
      const { data } = res;
      let fnf = data.map((val) => {
        let earning =
          parseInt(val.leaveEncashment ?? 0) +
          parseInt(val.gratuityAmount ?? 0) +
          parseInt(val.otherCreditAmount ?? 0) +
          parseInt(val.pfAmount ?? 0);
        let deduction =
          parseInt(val.lossOfPay ?? 0) +
          parseInt(val.otherDebitAmount ?? 0) +
          parseInt(val.taxDeducted ?? 0);
        return {
          date: new Date(val.initiationDate).toDateString(),
          lastDayOfWorking: new Date(val.lastDayOfWorking).toDateString(),
          user: val.user,
          earning,
          deduction,
          takehome: earning - deduction,
          salaryTakeHome: val.takeHome,
        };
      });
      setFnfs(fnf);
    });
    getPayslipFreeze();
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialYear, month, user.organisationId]);

  useEffect(() => {
    if (payroll) {
      let gross = 0,
        iTax = 0,
        empPf = 0,
        employerPf = 0,
        rem = 0,
        bonus = 0;
      let proffTax = 0;
      payslips.forEach((item) => {
        const { finalPayslip } = item;
        if (finalPayslip) {
          gross +=
            item.user.regimeOptedStatus === 1
              ? finalPayslip.newRegime.takeHome
              : finalPayslip.oldRegime.takeHome;
          iTax +=
            item.user.regimeOptedStatus === 1
              ? finalPayslip.newRegime.incomeTax
              : finalPayslip.oldRegime.incomeTax;
          empPf +=
            finalPayslip.employee_PF !== undefined
              ? finalPayslip.employee_PF
              : 0;
          employerPf +=
            finalPayslip.employer_PF !== undefined
              ? finalPayslip.employer_PF
              : 0;
          rem +=
            finalPayslip.reimbursements !== undefined
              ? sumOfAll(finalPayslip.reimbursements)
              : 0;
          bonus += sumOfAll(finalPayslip.bonuses);
          proffTax += finalPayslip.proffTax;
        }
      });
      setProcessedPayrolls({
        grossSalary: numformatter(gross),
        incomeTax: numformatter(iTax),
        professionalTax: numformatter(proffTax),
        employeePF: numformatter(empPf),
        employerPF: numformatter(employerPf),
        reimbursement: numformatter(rem),
        bonus: numformatter(bonus),
      });
    }
  }, [payslips, payroll]);

  useEffect(() => {
    if (payroll) {
      setIsFreezed(payroll.payrollFreeze);
    }
  }, [payroll]);

  const getPayslipFreeze = async () => {
    getPayslipCounter(`${user.organisationId}/${month}/${financialYear}`).then(
      (res) => {
        setOrgPayslipInfo({ ...res.data });
      }
    );

    getFreezeDetails();
  };

  const getFreezeDetails = () => {
    getOrganisationFreezeInfo(
      `${user.organisationId}/${financialYear}/${month}`
    ).then((res) => {
      if (res.data) {
        setPayroll({ ...res.data, payrollAvailable: true });
      }
    });
    getReportsData(`${user.organisationId}/${month}/${financialYear}`).then(
      (res) => {
        setPayslips(res.data);
      }
    );
  };

  const getTransactions = async () => {
    let fYearList = financialYear.split("-");
    let res = await getAllTransactionHistory(
      user.organisationId,
      month,
      month < 3 ? fYearList[1] : fYearList[0],
    );

    if (res.status === 200 && res.data) {
      let arr = res.data.filter(item => item.type !== -1)
      setTransactionList(arr);
    } else {
      setTransactionList(res.data);
    }
  };

  const updatePayrollFreeze = async () => {
    let res = await updatePayslipFreeze({
      organisationId: user.organisationId,
      financialYear,
      month,
      status: true,
    });
    if (res.data.success) {
      message.success("Successfully freezed the selected month's payroll");
      await updatePayrollData();
      getFreezeDetails();
    } else {
      message.error("unable to update the payroll freeze");
    }
  };

  const confirmFreeze = () => {
    if (orgPayslipInfo.totalNonGeneratedPayslips <= 0) {
      Modal.confirm({
        title: `Are you sure you want to freeze the payroll for the selected month`,
        icon: <ExclamationCircleOutlined />,
        okText: "Confirm",
        okButtonProps: {
          type: "primary",
          danger: true,
        },
        cancelText: "Cancel",
        onOk: () => updatePayrollFreeze(),
      });
    } else {
      message.error("Please run payroll for every employee");
    }
  };

  const updatePayrollData = async () => {
    organisationReducer.freezeResult(`${user.organisationId}/${financialYear}`);
  };

  return (
    <div className="app-main-content-container">
      <div className="justify-end flex mb-4">
        {(isFreezed || financialYear !== currentFinancialYear) && (
          <div className="btn-green">
            <img
              alt=""
              src={verifyYes}
              className="status-logo"
              style={{ width: "20px" }}
            ></img>
            <h1 className="m-left-6 color-green font-size-18">Processed</h1>
          </div>
        )}
        {payroll.payrollAvailable &&
          !isFreezed &&
          financialYear === currentFinancialYear && (
            <NormalButton
              type={13}
              buttonText="Freeze Payroll"
              onClick={confirmFreeze}
            />
          )}
      </div>
      <ProcessedPayroll
        payroll={payroll}
        processedPayrolls={processedPayrolls}
      />
      <TransactMoney
        transferList={transactionList}
        listTransaction={
          transferType === 0
            ? payslips
            : transferType === 1
              ? reimbursement
              : transferType === 2
                ? fnfs
                : []
        }
        user={user}
        transactionModal={transactionModal}
        setTransactionModal={setTransactionModal}
        updateTransaction={getTransactions}
        transactionType={transferType}
        month={month}
        year={
          month < 3 ? financialYear.split("-")[1] : financialYear.split("-")[0]
        }
      />
      {/* <Tabs defaultActiveKey="1" size="large">
        <TabPane tab="(Payroll) Summary" key="1"> */}
      <div style={{ marginTop: "4rem" }}>
        <EmployeeSummary
          payroll={payroll}
          payslips={payslips}
          employeeList={employeeList}
          month={month}
          financialYear={financialYear}
          user={user}
          organisationReducer={organisationReducer}
          orgPayslipInfo={orgPayslipInfo}
          getPayslipFreeze={getPayslipFreeze}
          setTransactionModal={setTransactionModal}
          setTransferType={setTransferType}
          payout={payout}
          isFreezed={isFreezed}
          currentFinancialYear={currentFinancialYear}
          setModal={setModal}
          modal={modal}
          updatePayrollData={updatePayrollData}
          confirmFreeze={confirmFreeze}
          buttonDisabled={buttonDisabled}
          setModalVisible={setModalVisible}
          setButtonDisabled={setButtonDisabled}
          modalVisible={modalVisible}
          processedPayrolls={processedPayrolls}
        />
      </div>
      {/* </TabPane>
        <div style={{marginTop:"4rem"}}>
          <EmployeeSummary
            payroll={payroll}
            payslips={payslips}
            employeeList={employeeList}
            month={month}
            financialYear={financialYear}
            user={user}
            organisationReducer={organisationReducer}
            orgPayslipInfo={orgPayslipInfo}
            getPayslipFreeze={getPayslipFreeze}
            setTransactionModal={setTransactionModal}
            setTransferType={setTransferType}
            payout={payout}
            isFreezed={isFreezed}
            currentFinancialYear={currentFinancialYear}
            setModal={setModal}
            modal={modal}
            updatePayrollData={updatePayrollData}
            confirmFreeze={confirmFreeze}
            buttonDisabled={buttonDisabled}
            setModalVisible={setModalVisible}
            setButtonDisabled={setButtonDisabled}
            modalVisible={modalVisible}
            processedPayrolls={processedPayrolls}
          />
          </div>
        {/* </TabPane>
        <TabPane tab="Full And Final" key="2">
          <FnFHistory
            fnfs={fnfs}
            month={month}
            user={user}
            financialYear={financialYear}
            organisationId={user.organisationId}
          />
        </TabPane>
        <TabPane tab="Reimbursements" key="3">
          <ReimbursementHistory
            reimbursement={reimbursement}
            month={month}
            payout={payout}
            setTransactionModal={setTransactionModal}
            setTransferType={setTransferType}
            isFreezed={isFreezed}
            currentFinancialYear={currentFinancialYear}
            payroll={payroll}
            financialYear={financialYear}
            organisationId={user.organisationId}
          />
        </TabPane>
        <TabPane tab="Transactions" key="4">
          <TransactionHistory transactions={transactionList} />
        </TabPane>
      </Tabs> */}
    </div>
  );
};

const PayrollMonthsConnect = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    payout: state.organisation.payout,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(PayrollMonths);

export default PayrollMonthsConnect;
