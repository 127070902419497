import { notification, Spin, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { getExistingAuditRecord, getPaymentStatus, initiatePayment } from "../../../../apis/tools";
import editIcon from "../../../../assets/editIcon.svg";
import greenCheck from "../../../../assets/greenCheck.svg";
import greenInner from "../../../../assets/greenInner.svg";
import greenOutter from "../../../../assets/greenOutter.svg";
import left1 from "../../../../assets/left1.svg";
import left2 from "../../../../assets/left2.svg";
import left3 from "../../../../assets/left3.svg";
import redDanger from "../../../../assets/redDanger.svg";
import redInner from "../../../../assets/redInner.svg";
import redOutter from "../../../../assets/redOutter.svg";
import yellowExclamation from "../../../../assets/yellowExclamation.svg";
import yellowInner from "../../../../assets/yellowInner.svg";
import yellowOutter from "../../../../assets/yellowOutter.svg";
import { monthList, rootUrl } from '../../../../utils/config';
import NormalButton from "../../../ComponentLibrary/ButtonComponents/NormalButton";
import UploadNewModal from "../UploadNewModal";
import "./AuditValidationPage.less";
import {
    ReloadOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";


const AuditValidationPage = ({ currentRecord }) => {
    const [existRecord, setExistRecord] = useState({ ...currentRecord });
    const [pageState, setPageState] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    // const history = useHistory();
    const columns = [
        {
            title: "ID",
            dataIndex: "employeeId",
            key: "employeeId",
            width: "20%"
        },
        {
            title: "Name",
            dataIndex: "displayName",
            key: "displayName",
            width: "20%"
        },
        {
            title: "",
            dataIndex: "employeeId",
            key: "errors",
            render: (key, data) => {
                return (<><span style={{ color: "#EF940B" }}>{data?.config?.warnings} Warnings</span><span style={{ color: "#F66B6B" }}> | {data?.config?.errors} Errors</span></>)
            }
        },
    ];


    useEffect(() => {
        checkPayment();
        setLoading(true);
        getExistingAuditRecord({ organisationId: currentRecord.organisationId, financialYear: currentRecord.financialYear, month: currentRecord.month, }).then(res => {
            if (res.data.data) {
                setExistRecord(res.data.data);
                setLoading(false);
            } else {
                setExistRecord({});
                setLoading(false);
            }
        }).catch(err => {
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRecord, refresh]);

    const checkPayment = async () => {
        setLoading(true);
        await getPaymentStatus({ organisationId: currentRecord.organisationId, financialYear: currentRecord.financialYear, month: currentRecord.month, });
        setLoading(false);
    }

    const makePayment = () => {
        initiatePayment({
            organisationId: currentRecord.organisationId, financialYear: currentRecord.financialYear, month: currentRecord.month,
            returnURL: `${process.env.REACT_APP_VETAN_FRONTEND_URL}/org/utility/audit?orderID={order_id}`
        })
            .then((response) => {
                const paymentSessionId = response?.data?.data?.paymentSessionID;
                const cashfree = new window.Cashfree(paymentSessionId);
                cashfree.redirect();
            })
            .catch(() => {
                notification.error({
                    message:
                        'Sorry, Currently due to some issues, we can not proceed with your payment.',
                });
            });
    };


    const downloadDocument = async () => {
        let res = await axios({
            url: `${rootUrl}/api/audit/valid_report/${currentRecord.organisationId}/${currentRecord.month}/${currentRecord.financialYear}`, //your url
            method: 'GET',
            responseType: 'blob',
        })
        const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = urlDownload;
        link.setAttribute('download', "Valid_Audit_Report_" + monthList[currentRecord.month] + ".xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // history.push('/org/utility/audit');
    }

    function getErrorsExceptListed(errors) {
        let sum = 0;
        for (let errorKey in errors) {
            if (!["incomeTaxStatus", "employeeESICStatus", "employeePFStatus", "proffTaxStatus", "employeeLWFStatus", "statutoryBonusStatus", "minWageActStatus"].includes(errorKey)) {
                sum += errors[errorKey];
            }
        }
        return sum;
    }

    return (
        <Spin spinning={loading}>
            <div>
                <div style={{ margin: "2em", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <h1 className="font-weight-global-700 font-size-global-24 m-left-10">Your {monthList[currentRecord.month]}'s audit list</h1>
                    </div>
                    <div className="flex items-center">
                        {pageState ?
                            <NormalButton buttonText={"Upload Document"} type={25} icon={editIcon} onClick={() => { setPageState(0) }} /> :
                            <div>
                                <UploadNewModal setPageState={setPageState} organisationId={currentRecord.organisationId} month={currentRecord.month} financialYear={currentRecord.financialYear} />
                            </div>
                        }
                        <NormalButton buttonText={"Refresh"} antIcon={<ReloadOutlined style={{ marginRight: "10px" }} />} type={7} onClick={() => { setRefresh(!refresh); }} />
                    </div>
                </div>
                <div style={{ backgroundColor: "white", marginLeft: "3em", marginRight: "3em", borderRadius: "10px" }}>
                    <div style={{ paddingTop: "5em", paddingLeft: "2em", paddingRight: "2em", paddingBottom: "5em" }}>

                        <div style={{ display: "flex", alignContent: "center" }}>
                            <div className="entry-div border-1">
                                <div className="image-container">
                                    <img className="outter" src={greenOutter} alt="" />
                                    <img className="inner" src={greenInner} alt="" />
                                    <img className="icon" src={greenCheck} alt="" />
                                </div>
                                <div className="entry-types m-top-10 m-left-10">
                                    <p> Correct entries</p>
                                    <h2> {currentRecord?.config?.totalSuccess ?? 0}</h2>
                                </div>
                            </div>

                            <div className="entry-div border-2">
                                <div className="image-container">
                                    <img className="outter" src={yellowOutter} alt="" />
                                    <img className="inner" src={yellowInner} alt="" />
                                    <img className="icon" src={yellowExclamation} alt="" />
                                </div>
                                <div className="entry-types m-top-10  m-left-10">
                                    <p> Warnings</p>
                                    <h2> {currentRecord?.config?.totalWarnings ?? 0}</h2>
                                </div>
                            </div>

                            <div className="entry-div border-3">
                                <div className="image-container" >
                                    <img className="outter" src={redOutter} alt="" />
                                    <img className="inner" src={redInner} alt="" />
                                    <img className="icon" src={redDanger} alt="" />
                                </div>
                                <div className="entry-types m-top-10">
                                    <p> Errors</p>
                                    <h2> {currentRecord?.config?.totalErrors ?? 0}</h2>
                                </div>
                            </div>

                        </div>
                        <div style={{ marginTop: "50px" }}>
                            <p style={{ fontWeight: "700", fontSize: "1.5rem" }}> Paid Suggestions</p>
                            <div style={{ color: "#667085", fontSize: "1.1rem" }}>
                                We found <b> {currentRecord?.config?.totalErrors ?? 0} calculation issues</b> in the calculation sheet available only for paid users.
                            </div>
                            <div className="grid-div" style={{ marginTop: "2em", fontSize: "1.1rem" }}>
                                <div className="suggestions">
                                    <div className="row">
                                        <div className="column">
                                            <div className="smallCircle " style={{ marginRight: "10px" }}>{currentRecord?.config?.errors["incomeTaxStatus"] ?? 0}</div><div>TDS Calculation</div>
                                        </div >
                                        <div className="column">
                                            <div className="smallCircle " style={{ marginRight: "10px" }}>{currentRecord?.config?.errors["employeeESICStatus"] ?? 0}</div>   <div>ESIC Calculation</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="column">
                                            <div className="smallCircle " style={{ marginRight: "10px" }}>{currentRecord?.config?.errors["employeePFStatus"] ?? 0}</div>   <div>PF Calculation</div>
                                        </div>
                                        <div className="column">
                                            <div className="smallCircle " style={{ marginRight: "10px" }}>{currentRecord?.config?.errors["proffTaxStatus"] ?? 0}</div>   <div>Professional Tax calculations</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="column">
                                            <div className="smallCircle " style={{ marginRight: "10px" }}>{currentRecord?.config?.errors["employeeLWFStatus"] ?? 0}</div>   <div>LWF Calculation</div>
                                        </div>
                                        <div className="column">
                                            <div className="smallCircle " style={{ marginRight: "10px" }}>{currentRecord?.config?.errors["statutoryBonusStatus"] ?? 0}</div>   <div>Statutory Bonus calculations</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="column">
                                            <div className="smallCircle " style={{ marginRight: "10px" }}>{currentRecord?.config?.errors["minWageActStatus"] ?? 0}</div>   <div>Min-Wages Calculation</div>
                                        </div>
                                        <div className="column">
                                            <div className="smallCircle " style={{ marginRight: "10px" }}>{getErrorsExceptListed(currentRecord?.config?.errors)}</div>   <div>Other Errors</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="error-found-div">
                                    <span className="leftImageContainer">
                                        <img src={left3} alt="3" />
                                        <img src={left2} alt="2" />
                                        <img src={left1} alt="1" />
                                    </span>
                                    <span style={{ zIndex: "3", position: "relative" }} className="error-found-div-text">{currentRecord?.config?.totalErrors ?? 0} errors found !</span>
                                    <span className="rightImageContainer">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 style={{ marginTop: "1em", fontWeight: "700", fontSize: "1.5rem" }}>List of employee having errors</h1>
                            <div >
                                <Table
                                    style={{ marginTop: "1.5rem" }}
                                    columns={columns}
                                    dataSource={Object.values(currentRecord.employees)}
                                    pagination={false}
                                    scroll={{ y: 520 }}
                                />
                                <div className="m-top-20">
                                    {
                                        existRecord.status >= 2 ? <NormalButton type={13} buttonText={"Download"} onClick={downloadDocument} /> : <NormalButton type={13} buttonText={"Pay and Download"} onClick={makePayment} disabled={Object.values(currentRecord.employees).length <= 0} />
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Spin>
    )
}
export default AuditValidationPage;