import React, { useState } from "react";
import {
  Skeleton,
  Row,
  Col,
} from "antd";
import config, { monthList, rootUrl } from "../../../utils/config";
import Modal from "antd/lib/modal/Modal";
import axios from "../../../apis";
import { NormalButton } from "../../ComponentLibrary";
import { getPayslipHTML } from "../../../apis/organisation";
import { useEffect } from "react";

const PayslipAndForms = ({ payslips, user }) => {
 
  const [showModal, setShowModal] = useState(false);
  const [clickedPayslip, setClickedPayslip] = useState(null);
  const [payslipHtml, setPayslipHtml] = useState(null);
  const [newPayslips, setNewPayslips] = useState([]);



  useEffect(
    () => {
      // Sort payslip by month
      let newPayslipsData = [];
      for (let i = 3; i < 12; i++) {
        const payslip = payslips.filter((a) => a.month === i);
        if (payslip.length) {
          newPayslipsData.push(...payslip)
        }
      }
      for (let i = 0; i < 3; i++) {
        const payslip = payslips.filter((a) => a.month === i);
        if (payslip.length) {
          newPayslipsData.push(...payslip)
        }
      }
      setNewPayslips(newPayslipsData)
    },
    [payslips]
  );

  const handleViewClick = async (payslipData) => {
    setShowModal(true);
    setClickedPayslip(payslipData);
    let { financialYear, month } = payslipData
    let payslipHTML = await getPayslipHTML(`${user._id}/${month}/${financialYear}`);
    if(payslipHTML.status !== 200)
    {
      setShowModal(false);
      setPayslipHtml(null);
      setClickedPayslip(null);
      return;
    }
    setPayslipHtml(payslipHTML.data);
  };

  const handleAction = async (url) => {
    let res = await axios({
      url, //your url
      method: 'GET',
      responseType: 'blob',
    })
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', `payslip-${monthList[clickedPayslip.month]}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div className="employee-details-container">
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="information-section">
            <div className="section-header">
              <p className="information-category-heading">Payslip</p>
            </div>
            <div className="monthly-payslip-container">
              <Skeleton loading={!payslips} active>
                {newPayslips.length > 0 &&
                  newPayslips.map((val) => {
                    return (
                      <div className="monthly-payslips">
                        <span>
                          {config.monthList[val.month] +
                            " " +
                            val.financialYear}
                        </span>
                        <NormalButton
                          buttonText={"View"}
                          type={13}
                          onClick={() => handleViewClick(val)}
                          className="pay-silp-view-button"
                        />
                        
                      </div>
                    );
                  })}
              </Skeleton>
            </div>
          </div>
          <Modal
            footer={null}
            centered
            visible={showModal}
            width={800}
            onCancel={() => {
              setShowModal(false);
              setPayslipHtml(null);
            }}
          >
            <NormalButton type={13} buttonText="Download Payslip" onClick={() => handleAction(`${rootUrl}/api/payslip/pdf/${clickedPayslip.user._id}/${clickedPayslip.financialYear}/${clickedPayslip.month}`)} className="p-4 m-top-8" />
                
            <div
              dangerouslySetInnerHTML={{ __html: payslipHtml }}
              id="current_payslip"
            ></div>
          </Modal>
        </Col>
        <p className="divider"></p>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="information-section">
            <div className="section-header">
              <p className="information-category-heading">Forms</p>
            </div>
            <div className="monthly-payslip-container">
              <p>No Form16 document is available for you right now</p>
            </div>
            <p className="divider"></p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PayslipAndForms;