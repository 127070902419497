/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Spin,
  message,
  Upload,
  notification,
  Table,
  Progress,
  Row,
  Col,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import config, { checkFinancialYear } from "../../utils/config";
import uploadLogo from "../../assets/icons/savefile.svg";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  createLog,
  updateAddress,
  updateBankInfo,
  updateContactInfo,
  updateCredentials,
  updateEducationAndQualification,
  updateEmergencyContact,
  updateIpAddress,
  updateNominee,
  updatePersonalInfo,
} from "../../apis/user";
import { updateRegimeOptedStatus } from "../../apis/employee";
import {
  houseRentUpdate,
  updateCTC,
  updateEmployeeId,
  updateJoiningDate,
  updateLeaves,
} from "../../apis/organisation";
import { NormalButton } from "../ComponentLibrary";

const { Dragger } = Upload;

const updateFunctions = {
  nominee: updateNominee,
  educationAndQualification: updateEducationAndQualification,
  familyDetails: updatePersonalInfo,
  emergencyContact: updateEmergencyContact,
  address: updateAddress,
  bankInfo: updateBankInfo,
  contactInfo: updateContactInfo,
  credentials: updateCredentials,
  ipAddress: updateIpAddress,
  regime: updateRegimeOptedStatus,
  ctc: updateCTC,
  hra: houseRentUpdate,
  leave: updateLeaves,
  doj: updateJoiningDate,
  employeeId: updateEmployeeId,
};

const csvDownloadURL = {
  nominee: "nominee",
  educationAndQualification: "educationAndQualification",
  familyDetails: "familyDetails",
  emergencyContact: "emergencyContact",
  address: "address",
  bankInfo: "bankInfo",
  contactInfo: "contactInfo",
  credentials: "credentials",
  ipAddress: "ip",
  regime: "regime",
  ctc: "ctc",
  leave: "leave_credit",
  hra: "hra",
  doj: "joining_date",
  employeeId: "employee_id",
};

const uploadAPI = {
  nominee: "parser/nominee",
  educationAndQualification: "parse/education_qualification",
  familyDetails: "parser/family_details",
  emergencyContact: "parser/emergency_contact",
  address: "parser/address",
  bankInfo: "parser/bank_info",
  contactInfo: "parser/contact_info",
  credentials: "parser/credentials",
  ipAddress: "parser/ip_address",
  regime: "parser/regime_selection",
  ctc: "parser/ctc",
  leave: "parser/leave_credit",
  hra: "parser/hra",
  doj: "parser/joining_date",
  employeeId: "parser/employee_id",
};

const subField = {
  nominee: "nominee",
  educationAndQualification: "educationAndQualification",
  familyDetails: "familyDetails",
  emergencyContact: "emergencyContact",
  address: "",
  bankInfo: "bankInfo",
  contactInfo: "contactInfo",
  credentials: "credentials",
  ipAddress: "",
  regime: "",
  ctc: "",
  leave: "",
  hra: "",
  doj: "",
  employeeId: "",
};

const fileTypes = {
  nominee: "xlsx",
  educationAndQualification: "csv",
  familyDetails: "xlsx",
  emergencyContact: "xlsx",
  address: "xlsx",
  bankInfo: "xlsx",
  contactInfo: "xlsx",
  credentials: "xlsx",
  ipAddress: "xlsx",
  regime: "xlsx",
  ctc: "xlsx",
  leave: "xlsx",
  hra: "xlsx",
  doj: "xlsx",
  employeeId: "xlsx",
};

const PersonalInformation = ({
  user,
  type,
  name,
  handleAction,
  employeeList,
  commonColumn,
}) => {
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [completed, setCompleted] = useState(0);

  const heading = [`Upload ${name}`, "Verify and Update"];
  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/parse/contact_info`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setData([...response]);
      }
    },
  };

  const reset = () => {
    setData([]);
    setPageState(0);
    setCompleted(0);
    setIsLoading(false);
  };

  const searchUserFromUsername = username => {
    for (let emp of employeeList) {
      if (emp.username === username) {
        return emp;
      }
    }
    return null;
  };

  const updateEmployeeDetails = async () => {
    if (data.length === 0) {
      message.error("Please Upload The File");
      return;
    }
    setIsLoading(true);
    let totalSuccess = 0;
    for (const element of data) {
      let emp = searchUserFromUsername(element.username);
      if (!emp) continue;
      if (subField[type] === "") {
        await updateFunctions[type]({
          id: emp._id,
          ...element,
        });
      } else {
        await updateFunctions[type]({
          id: emp._id,
          ...element[subField[type]],
        });
      }

      totalSuccess += 1;
      setCompleted(totalSuccess);
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organiation user updated ${data.length} number of ${type}`,
      category: 15,
    });
    notification.success({
      message: "Successfully uploaded the employee details",
    });
    setIsLoading(false);
    reset();
  };

  return (
    <div className="">
      {isLoading && pageState === 1 && (
        <Progress
          percent={Math.round((completed / data.length) * 100)}
          size="small"
          status="active"
        />
      )}
      <Spin size="large" spinning={isLoading}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="flex align-center">
              {pageState !== 0 && (
                <ArrowLeftOutlined
                  onClick={() => {
                    let page = pageState;
                    page = page - 1;
                    setPageState(page);
                  }}
                  style={{ marginRight: 10 }}
                  className="arrow-left-button font-weight-700"
                />
              )}
              <h3 className="font-weight-global-700 font-size-24">{heading[pageState]}</h3>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {pageState === 1 && (
              <div className="flex w-full justify-end">
                <NormalButton
                  onClick={reset}
                  type={5}
                  buttonText="Cancel"
                  className="cancel-button"
                  style={{
                    border: "none",
                    boxShadow: "none",
                    marginRight: 10,
                  }}
                />
                  <NormalButton
                    type={13}
                    buttonText={`Update ${name}`}
                    onClick={updateEmployeeDetails}
                    className="submit-button"
                    style={{
                      marginRight: "0px",
                      padding: "0px 24px",
                    }}
                  />
              </div>
            )}
          </Col>
        </Row>
        {pageState === 0 && (
          <UploadDocument
            type={type}
            name={name}
            handleAction={handleAction}
            platforms={{
              ...uploadProps,
              action: `${config.rootUrl}/api/${uploadAPI[type]}`,
            }}
            user={user}
            downloadURL={csvDownloadURL[type]}
          />
        )}
        {pageState === 1 && (
          <VerifyAndUpload
            data={data}
            type={type}
            commonColumn={commonColumn}
          />
        )}
      </Spin>
    </div>
  );
};

const UploadDocument = ({
  platforms,
  user,
  downloadURL,
  handleAction,
  name,
  type,
}) => {
  return (
    <Content className="bulk-wrapper-left">
      <div>
        <p className="paper-title">
          Download Sample in{" "}
          <a
            className="download-employee-list"
            onClick={() =>
              handleAction(
                `${config.rootUrl}/api/generate/document/${downloadURL}/${
                  user.organisationId
                }/${type === "ctc" ? checkFinancialYear().financialYear : ""}`,
                `${name}.${fileTypes[type]}`
              )
            }
          >
            .{fileTypes[type]} format
          </a>{" "}
          file to ensure that you have the correct file ready to import.
        </p>
      </div>
      <div>
        <Dragger
          {...platforms}
          showUploadList={false}
          height={300}
          className="bulk-wrapper-dragger"
        >
          <p className="ant-upload-drag-icon">
            <img alt="" src={uploadLogo} height="80" width="80" />
          </p>

          <p className="ant-upload-text">Drag and drop CSV file</p>
          <p className="ant-upload-hint">
            or <u>Click Here</u> to upload
          </p>
        </Dragger>
      </div>
    </Content>
  );
};

const VerifyAndUpload = ({ data, type, commonColumn }) => {
  const widthTwo = 150;
  const dateFilter = (da) => (
    <p>{isNaN(new Date(da).getTime()) ? "" : new Date(da).toDateString()}</p>
  );
  const tableData = {
    employeeId: [
      ...commonColumn,
      {
        title: "Current Employee ID",
        dataIndex: "currentEmployeeId",
      },
      {
        title: "Updated Employee ID",
        dataIndex: "employeeId",
      },
    ],
    leave: [
      ...commonColumn,
      {
        title: "Total Leaves",
        dataIndex: ["totalNoOfLeaves"],
      },
    ],
    doj: [
      ...commonColumn,
      {
        title: "Date Of Joining",
        dataIndex: ["dateOfJoining"],
      },
    ],
    ctc: [
      ...commonColumn,
      {
        title: "Cost To Company",
        dataIndex: ["ctc"],
      },
    ],
    hra: [
      ...commonColumn,
      {
        title: "Living In Metro",
        dataIndex: ["houseRent", "isMetro"],
        key: "isMetro",
        render: (val) => <span>{val ? "Yes" : "No"}</span>,
      },
      {
        title: "Rent Per Month",
        dataIndex: ["houseRent", "rent"],
        key: "rent",
      },
    ],
    ipAddress: [
      ...commonColumn,
      {
        title: "IP Address",
        dataIndex: ["ip"],
        width: widthTwo,
      },
    ],
    regime: [
      ...commonColumn,
      {
        title: "Regime Opted Status",
        dataIndex: ["regimeOptedStatus"],
        width: widthTwo,
        render: (val) => <p>{val === 1 ? "New Regime" : "Old Regime"}</p>,
      },
    ],
    nominee: [
      ...commonColumn,
      {
        title: "Nominee One Name",
        dataIndex: ["nominee", "nomineeOne", "name"],
        width: widthTwo,
      },
      {
        title: "Nominee One Relationsships",
        dataIndex: ["nominee", "nomineeOne", "relationship"],
        width: widthTwo,
      },
      {
        title: "Nominee One Date Of Birth",
        dataIndex: ["nominee", "nomineeOne", "dateOfBirth"],
        width: widthTwo,
        render: (da) => dateFilter(da)
      },
      {
        title: "Nominee One Aadhaar",
        dataIndex: ["nominee", "nomineeOne", "aadhaar"],
        width: widthTwo,
      },
      {
        title: "Nominee Two Name",
        dataIndex: ["nominee", "nomineeTwo", "name"],
        width: widthTwo,
      },
      {
        title: "Nominee Two Relationsships",
        dataIndex: ["nominee", "nomineeTwo", "relationship"],
        width: widthTwo,
      },
      {
        title: "Nominee Two Date Of Birth",
        dataIndex: ["nominee", "nomineeTwo", "dateOfBirth"],
        width: widthTwo,
        render: (da) => dateFilter(da)
      },
      {
        title: "Nominee Two Aadhaar",
        dataIndex: ["nominee", "nomineeTwo", "aadhaar"],
        width: widthTwo,
      },
      {
        title: "Nominee Three Name",
        dataIndex: ["nominee", "nomineeThree", "name"],
        width: widthTwo,
      },
      {
        title: "Nominee Three Relationships",
        dataIndex: ["nominee", "nomineeThree", "relationship"],
        width: widthTwo,
      },
      {
        title: "Nominee Three Date Of Birth",
        dataIndex: ["nominee", "nomineeThree", "dateOfBirth"],
        width: widthTwo,
        render: (da) => dateFilter(da)
      },
      {
        title: "Nominee Three Aadhaar",
        dataIndex: ["nominee", "nomineeThree", "aadhaar"],
        width: widthTwo,
      },
    ],
    educationAndQualification: [
      ...commonColumn,
      {
        title: "Degree",
        dataIndex: ["degree"],
        key: "degree",
        width: widthTwo,
      },
      {
        title: "College Name",
        dataIndex: ["collegeName"],
        key: "collegeName",
        width: widthTwo,
      },
      {
        title: "University",
        dataIndex: ["University"],
        key: "University",
        width: widthTwo,
      },
      {
        title: "Start Date",
        dataIndex: ["startDate"],
        key: "startDate",
        width: widthTwo,
        render: (da) => dateFilter(da)
      },
      {
        title: "End Date",
        dataIndex: ["endDate"],
        key: "endDate",
        width: widthTwo,
        render: (da) => dateFilter(da)
      },
    ],
    familyDetails: [
      ...commonColumn,
      {
        title: "Phone Number",
        dataIndex: ["familyDetails", "phoneNumber"],
        key: "phoneNumber",
        width: widthTwo,
      },
      {
        title: "DOB",
        dataIndex: ["familyDetails", "dateOfBirth"],
        key: "dateOfBirth",
        width: widthTwo,
        render: (da) => dateFilter(da)
      },
      {
        title: "Gender",
        dataIndex: ["familyDetails", "gender"],
        key: "gender",
        width: widthTwo,
      },
      {
        title: "Marital Status",
        dataIndex: ["familyDetails", "maritalStatus"],
        key: "maritalStatus",
        width: widthTwo,
      },
      {
        title: "Spouse name",
        dataIndex: ["familyDetails", "spouseName"],
        key: "maritalStatus",
        width: widthTwo,
      },
      {
        title: "Spouse Employment Status",
        dataIndex: ["familyDetails", "spouseEmploymentStatus"],
        key: "spousEmployementStatus",
        width: widthTwo,
        render: (da) => <p>{da ? "Employed" : "Not Employed"}</p>,
      },
      {
        title: "Father's Name",
        dataIndex: ["familyDetails", "fathersName"],
        key: "fathersName",
        width: widthTwo,
      },
      {
        title: "Father's DOB",
        dataIndex: ["familyDetails", "fathersDOB"],
        key: "fathersDOB",
        width: widthTwo,
        render: (da) => dateFilter(da)
      },
      {
        title: "Is father Disabled?",
        dataIndex: ["familyDetails", "isFatherDisabled"],
        key: "isFatherDisabled",
        width: widthTwo,
        render: (_data) => <p>{_data ? "Yes" : "No"}</p>,
      },
      {
        title: "Monther's Name",
        dataIndex: ["familyDetails", "mothersName"],
        key: "mothersName",
        width: widthTwo,
      },
      {
        title: "Mother's DOB",
        dataIndex: ["familyDetails", "mothersDOB"],
        key: "mothersDOB",
        width: widthTwo,
        render: (da) => dateFilter(da)
      },
      {
        title: "Is Mother Disabled?",
        dataIndex: ["familyDetails", "isMotherDisabled"],
        key: "isMotherDisabled",
        width: widthTwo,
        render: (_data) => <p>{_data ? "Yes" : "No"}</p>,
      },
    ],
    emergencyContact: [
      ...commonColumn,
      {
        title: "Emergency Contact Name",
        dataIndex: ["emergencyContact", "name"],
        key: "name",
        width: widthTwo,
      },
      {
        title: "Relationship",
        dataIndex: ["emergencyContact", "relationship"],
        key: "relationship",
        width: widthTwo,
      },
      {
        title: "Address",
        dataIndex: ["emergencyContact", "address"],
        key: "address",
        width: widthTwo,
      },
      {
        title: "Contact Number",
        dataIndex: ["emergencyContact", "contactNo"],
        key: "contactNo",
        width: widthTwo,
      },
      {
        title: "Country Code",
        dataIndex: ["emergencyContact", "countryCode"],
        key: "countryCode",
        width: widthTwo,
      },
    ],
    address: [
      ...commonColumn,
      {
        title: "Present Address One",
        dataIndex: ["address", "present", "addressLineOne"],
        key: "addressLineOne",
        width: widthTwo,
      },
      {
        title: "Present Address Two",
        dataIndex: ["address", "present", "addressLineTwo"],
        key: "addressLineTwo",
        width: widthTwo,
      },
      {
        title: "Present Address Landmark",
        dataIndex: ["address", "present", "landmark"],
        key: "landmark",
        width: widthTwo,
      },
      {
        title: "Present Address City",
        dataIndex: ["address", "present", "city"],
        key: "city",
        width: widthTwo,
      },
      {
        title: "Present Address Pincode",
        dataIndex: ["address", "present", "pincode"],
        key: "pincode",
        width: widthTwo,
      },
      {
        title: "Present Address State",
        dataIndex: ["address", "present", "state"],
        key: "state",
        width: widthTwo,
      },
      {
        title: "Present Address Country",
        dataIndex: ["address", "present", "country"],
        key: "country",
        width: widthTwo,
      },
      {
        title: "Permanent Address One",
        dataIndex: ["address", "permanent", "addressLineOne"],
        key: "addressLineOne",
        width: widthTwo,
      },
      {
        title: "Permanent Address Two",
        dataIndex: ["address", "permanent", "addressLineTwo"],
        key: "addressLineTwo",
        width: widthTwo,
      },
      {
        title: "Permanent Address Landmark",
        dataIndex: ["address", "permanent", "landmark"],
        key: "landmark",
        width: widthTwo,
      },
      {
        title: "Permanent Address City",
        dataIndex: ["address", "permanent", "city"],
        key: "city",
        width: widthTwo,
      },
      {
        title: "Permanent Address Pincode",
        dataIndex: ["address", "permanent", "pincode"],
        key: "pincode",
        width: widthTwo,
      },
      {
        title: "Permanent Address State",
        dataIndex: ["address", "permanent", "state"],
        key: "state",
        width: widthTwo,
      },
      {
        title: "Permanent Address Country",
        dataIndex: ["address", "permanent", "country"],
        key: "country",
        width: widthTwo,
      },
    ],
    bankInfo: [
      ...commonColumn,
      {
        title: "Account Number",
        dataIndex: ["bankInfo", "accountNumber"],
        key: "accountNumber",
        width: widthTwo,
      },
      {
        title: "IFSC Code",
        dataIndex: ["bankInfo", "ifsc"],
        key: "ifsc",
        width: widthTwo,
      },
      {
        title: "Bank Name",
        dataIndex: ["bankInfo", "bankName"],
        key: "bankName",
        width: widthTwo,
      },
      {
        title: "Address",
        dataIndex: ["bankInfo", "bankAddress"],
        key: "bankAddress",
        width: widthTwo,
      },
    ],
    contactInfo: [
      ...commonColumn,
      {
        title: "Phone Number",
        dataIndex: ["contactInfo", "personalPhone"],
        key: "personalPhone",
        width: widthTwo,
      },
      {
        title: "Linked In",
        dataIndex: ["contactInfo", "linkedIn"],
        key: "linkedIn",
        width: widthTwo,
      },
      {
        title: "Skype ID",
        dataIndex: ["contactInfo", "skypeId"],
        key: "skypeId",
        width: widthTwo,
      },
      {
        title: "Slack User ID",
        dataIndex: ["contactInfo", "slackUser"],
        key: "slackUser",
        width: widthTwo,
      },
      {
        title: "Personal Email",
        dataIndex: ["contactInfo", "personalEmail"],
        key: "personalEmail",
        width: widthTwo,
      },
    ],
    credentials: [
      ...commonColumn,
      {
        title: "Aadhaar Number",
        dataIndex: ["credentials", "aadhaar"],
        key: "aadhaar",
        width: widthTwo,
      },
      {
        title: "UAN Number",
        dataIndex: ["credentials", "UAN"],
        key: "UAN",
        width: widthTwo,
      },
      {
        title: "UAN Password",
        dataIndex: ["credentials", "UANPassword"],
        key: "UANPassword",
        width: widthTwo,
      },
      {
        title: "Driver License",
        dataIndex: ["credentials", "drivingLicenseNumber"],
        key: "drivingLicenseNumber",
        width: widthTwo,
      },
      {
        title: "PF No",
        dataIndex: ["credentials", "PFNo"],
        key: "PFNo",
        width: widthTwo,
      },
      {
        title: "Voter ID",
        dataIndex: ["credentials", "voterID"],
        key: "voterID",
        width: widthTwo,
      },
      {
        title: "ESIC Number",
        dataIndex: ["credentials", "esicNumber"],
        key: "esicNumber",
        width: widthTwo,
      },
      {
        title: "Passport Number",
        dataIndex: ["credentials", "passportNumber"],
        key: "passportNumber",
        width: widthTwo,
      },
    ],
  };
  return (
    <Table
      columns={tableData[type]}
      dataSource={data}
      pagination={false}
      scroll={{ x: 600, y: "65vh" }}
    />
  );
};

export default PersonalInformation;
