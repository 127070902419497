import axios from "./index";
import { payslipURL } from "../utils/env";

export async function getEmployeeLOPDetails(id, financialYear, month) {
  return axios.get(`employee/lop/details/${id}/${financialYear}/${month}`);
}

export async function askMool(data) {
  let response = await axios.post(`${payslipURL}/salary/structure/`, data);
  return response;
}
export async function proofsByCategory({ id, category }) {
  return axios.get(`proofs/by/${id}/${category}`);
}

export async function updateDeclarations(data) {
  return axios.post("employee/declarations", data);
}

export async function getPayslips(id) {
  return axios.get("employee/payslips/" + id);
}

export async function updateEula(data) {
  return axios.post("employee/eula", data);
}

export async function updateRegimeOptedStatus(data) {
  return axios.put("employee/regime", data);
}

// Include it with the application
export async function getEmployeeDeclaration(data) {
  return axios.get(`employee/declaration/${data}`);
}

export async function getEmployeeDetails(data, cancelToken) {
  return axios.get(`employee/info/${data}`, cancelToken);
}

export async function getCurrentPayslip(data) {
  return axios.get(`employee/payslip/current/${data}`);
}

export async function getPayslipByID(id, month, year) {
  return axios.get(`employee/payslip/${id}/${month}/${year}`);
}

export async function removeS3Object(data) {
  return axios.delete(`document`, { data });
}

export async function removeProof(data) {
  return axios.delete(`employee/proof/${data}`);
}

export async function addProofs(data) {
  return axios.post("employee/add/proofs", data);
}

export async function getProofs(data) {
  return axios.get(`employee/proofs/${data}`);
}

export async function addReimbursement(data) {
  return axios.post("employee/add/reimbursements", data);
}

export async function employeeReimbursement(data) {
  return axios.get(`reimbursement/employee/${data}`);
}

export async function employeeReimbursementByMonth(data) {
  return axios.get(`reimbursement/employee/${data}`);
}

export async function deleteReimbursement(data) {
  return axios.delete(`employee/reimbursements/${data}`);
}

export async function updateReimbursement(data) {
  return axios.post(`update/reimbursements`, data);
}

export async function newSupportRequest(data) {
  return axios.post("support/new/request", data);
}

export async function getSupportRequestData(data) {
  return axios.get(`support/from/requests/${data}`);
}

export async function getBroadcastMessages(data) {
  return axios.get(`get/broadcast/${data}/2`);
}

export async function updateUserDocument(data) {
  return axios.put("user/document", data);
}

export async function newSettlement(data) {
  return axios.post("org/create/settlement", data);
}

export async function newUpdates(data) {
  return axios.get("get/updates", data);
}

export async function deleteCurrentPayslip(data) {
  return axios.delete(`current/payslip/${data}`);
}

export async function get12BBApproved(organisationId, id, financialYear) {
  return axios.get(
    `/employee/approved/12BB/${organisationId}/${id}/${financialYear}`
  );
}

export async function update12BB(organisationId, body) {
  return axios.put(`/employee/update/12BB/${organisationId}`, body);
}

export async function getEmployeeOnBoarding(organisationId, id, cancelToken) {
  return axios.get(`fetch/onboarding/${organisationId}/${id}`, cancelToken);
}
export async function updateEmployeeOnBoarding(data) {
  return axios.post(`update/onboarding`, data);
}
export async function newEmployeeOnBoarding(data) {
  return axios.post(`new/onboarding`, data);
}

export async function getPreviousEmploymentData(data) {
  return axios.get(`previous/employment/${data}`);
}

export async function deletePreviousEmploymentData(data) {
  return axios.delete(`previous/employment/${data}`) // :id/:financialYear/:employerId
}

export async function getEmployeeCTC(id, financialYear) {
  return axios.get(`ctc/${id}/${financialYear}`);
}

export async function savePreviousEmployment(data) {
  return axios.post(`previous/employment`, data);
}

export async function previousEmploymentCreate(data) {
  return axios.post("previous/employment/fresh-joinee/", data);
}

export async function updatePreviousEmployment(data) {
  return axios.post("previous/employment/update/", data);
}

export async function updateLoanDetails(data) {
  return axios.put(`declaration/loan_details`, data);
}

export async function inititateEmployee(data) {
  return axios.get(`fetch/employee/attendance/by/${data}`);
}

export async function getEmployeeLeaveList(data) {
  return axios.get(`leave/logs/by/status/user/${data}`);
}

export async function requestLeave(data) {
  return axios.post(`request/leave`, data);
}

export async function punchIn(data) {
  return axios.post(`punch/in`, data);
}

export async function punchOut(data) {
  return axios.post(`punch/out`, data);
}

export async function leaveLogForManager(data) {
  return axios.get(`leave/logs/by/manager/status/${data}`);
}

export async function employeeLeaveLogs({ id, organisationId }) {
  return axios.get(`leave/logs/by/${id}/${organisationId}`);
}

export async function logLOPData(data) {
  return axios.post("log/attendance", data);
}

export async function updateAdvanceTax(data) {
  return axios.put("employee/advance/tax", data);
}

export async function updateAttendance(data) {
  return axios.get(`attendance/by/${data}`);
}

export async function generatePayslip(data) {
  return axios.post(`mool/generate/payslip`, data);
}

export async function getEmployeeOvertimeAmount(data) {
  return axios.post(`employee/overtime/amount`, data);
}
