/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Spin,
  message,
  Upload,
  notification,
  Table,
  Progress
} from "antd";
import { updateDeductions } from "../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import config from "../../utils/config";
import uploadLogo from "../../assets/icons/savefile.svg";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createLog } from "../../apis/user";
import NormalButton from "../ComponentLibrary/ButtonComponents/NormalButton";


const { Dragger } = Upload;

const Declaration = ({ user, handleAction, commonColumn }) => {
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [declarationData, setDeclarationData] = useState([]);
  const [displayDeclaration, setDisplayDeclaration] = useState([]);
  const [completed, setCompleted] = useState(0);

  const heading = ["Upload the Document", "Verify and Update"];
  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/parser/declarations`,
    headers: {
      authorization: localStorage.getItem("mool_auth")
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setDeclarationData([...response]);
        let res = response.map((val, index) => {
          return { ...val, key: index + 1 };
        });
        setDisplayDeclaration([...res]);
      }
    }
  };

  const reset = () => {
    setDeclarationData({
      financialYear: "",
      month: 0,
      employeeData: []
    });
    setPageState(0);
    setCompleted(0);
  };

  const updateDeduction = async () => {
    if (declarationData.length === 0) {
      message.error("Please Upload CSV File");
      return;
    }
    setIsLoading(true);
    let totalSuccess = 0;
    let { financialYear } = config.checkFinancialYear();
    for (const element of declarationData) {
      await updateDeductions({
        financialYear,
        organisationId: user.organisationId,
        deductions: element.declaration,
        id: element.id
      });
      totalSuccess += 1;
      setCompleted(totalSuccess);
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organiation User updated ${declarationData.length} number of declarations`,
      category: 15,
    });
    notification.success({ message: "Successfully Uploaded the declarations" });

    setIsLoading(false);
    reset();
  };

  return (
    <div className="">
      {isLoading && pageState === 1 && (
        <Progress
          percent={Math.round((completed / declarationData.length) * 100)}
          size="small"
          status="active"
        />
      )}
      <Spin size="large" spinning={isLoading}>
        <div className="flex align-center justify-between">
          <div className="flex align-center">
            {pageState !== 0 && (
              <ArrowLeftOutlined
                onClick={() => {
                  let page = pageState;
                  page = page - 1;
                  setPageState(page);
                }}
                style={{ marginRight: 10 }}
                className="arrow-left-button font-weight-700"
              />
            )}
            <h3 className="font-weight-global-700 font-size-global-24">{heading[pageState]}</h3>
          </div>

          {pageState === 1 && (
            <div className="flex justify-end">
              <NormalButton
                onClick={reset}
                type={5}
                buttonText="Cancel"
                className="cancel-button"
                style={{
                  border: "none",
                  boxShadow: "none",
                  marginRight: 10
                }}
              />
          <NormalButton
            onClick={updateDeduction}
            buttonText="Upload Declarations"
            type={13}
            className="submit-button"
            style={{
              marginRight: "0px",
              padding: "0px 24px"
            }}
          />
            </div>
          )}
        </div>
        {pageState === 0 && (
          <UploadDocument platforms={{ ...uploadProps }} user={user} handleAction={handleAction} />
        )}
        {pageState === 1 && (
          <VerifyAndUpload displayDeclaration={displayDeclaration} commonColumn={commonColumn} />
        )}
      </Spin>
    </div>
  );
};

const UploadDocument = ({ platforms, user, handleAction }) => {
  return (
    <Content className="bulk-wrapper-left">
      <div>
        <p className="paper-title">
          Download Sample in{" "}
          <a
            className="download-employee-list"
            onClick={() =>
              handleAction(
                `${config.rootUrl}/api/declaration/excel/${user.organisationId}`,
                "Declarations_sync.xlsx"
              )
            }
          >
            .csv format
          </a>{" "}
          file to ensure that you have the correct file ready to import.
        </p>
      </div>
      <div>
        <div>
          <Dragger
            {...platforms}
            showUploadList={false}
            height={300}
            className="bulk-wrapper-dragger"
          >
            <p className="ant-upload-drag-icon">
              <img alt="" src={uploadLogo} height="80" width="80" />
            </p>

            <p className="ant-upload-text">Drag and drop CSV file</p>
            <p className="ant-upload-hint">
              or <u>Click Here</u> to upload
            </p>
          </Dragger>
        </div>
      </div>
    </Content>
  );
};

const VerifyAndUpload = ({ displayDeclaration, commonColumn }) => {
  return (
    <Table
      scroll={{ x: 600, y: "65vh" }}
      columns={commonColumn}
      dataSource={displayDeclaration}
      expandable={{
        expandedRowRender: (record) => (
          <div>
            {record.declaration.map((data, _index) =>
              data.amount > 0 ? (
                <div className="flex justify-between align-center">
                  <p className="text-label-2 font-weight-700">
                    {data.section} - {data.label}
                  </p>
                  <p className="text-heading-2 font-weight-500">
                    {data.amount}
                  </p>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        ),
        rowExpandable: (record) => {
          let res = null;
          let status = record.declaration.some((val) => val.amount > 0);
          if (status) {
            res = record;
          } else {
            res = "No declaration added";
          }
          return res;
        }
      }}
      pagination={false}
    />
  );
};


export default Declaration;
