import React from "react";
import { monthList } from "../../../utils/config";

function MonthlyRentData({ data }) {
  const RowData = ({
    month,
    isMetro,
    rent,
    hra,
    basicDa,
    basicFourtyOrFifty,
    rent_minus_basic,
    hraExemption
  }) =>
    <tr>
      <td>
        {monthList[month]}
      </td>
      <td>
        {isMetro ? 50 : 40}
      </td>
      <td>
        {rent ? rent : 0}
      </td>
      <td>
        {hra}
      </td>
      <td>
        {basicDa}
      </td>
      <td>
        {basicFourtyOrFifty > 0 ? Math.round(basicFourtyOrFifty) : 0}
      </td>
      <td>
        {rent_minus_basic > 0 ? Math.round(rent_minus_basic) : 0}
      </td>
      <td>
        {Math.round(hraExemption)}
      </td>
    </tr>;



  return (
    <React.Fragment>
      <tr>
        <th>Month</th>
        <th>Metro/Non-Metro</th>
        <th>Rent Paid</th>
        <th>HRA Received</th>
        <th>Basic DA</th>
        <th>40/50 % Basic DA</th>
        <th>Rent Paid - 10% of Basic</th>
        <th>HRA Exemption</th>
      </tr>
      {Object.keys(data).map((v, i) => <RowData key={i} month={v} {...data[v]} />)}
    </React.Fragment>
  );
}

export default MonthlyRentData;
