import { Tabs } from "antd";
import SubmitFormContainer from "./generalComponents/SubmitForm";
import SectionData from "./generalComponents/sectionData.js";

const { TabPane } = Tabs;

const generalTabsData = [
  {
    key: "1",
    sectionFullData: {
      sectionName: "Section 80C ",
      sectionType: "Investment",
      limit: "Rs. 1.5 Lakhs/Financial year",
      notes: "",
      sectinDescription: "Various investments are exempted from Income Tax.",
      sectionInfo:
        "You can claim tax exemptions on expenditures and investments such as Life Insurance premium, PPF, EPF, NSC, ELSS Mutual Funds, Children school fees, Payment of Principal in Housing Loan & other eligible items. Up to Rs. 1.5 lakh every year from your total taxable income can be deducted.",
    },
    documents: [
      {
        declaration: "fiveYearFixedDepositScheduledBank",
        label: "5 Years of Fixed Deposit in Scheduled Bank",
      },
      {
        declaration: "childrenTuitionFees",
        label: "Children Tuition Fees",
      },
      {
        declaration: "depositInNSC",
        label: "Deposit in National Savings Certificate",
      },
      {
        declaration: "depositInNSS",
        label: "Deposit in National Savings Schemes",
      },
      {
        declaration: "depositInPostOfficeSavingsSchemes",
        label: "Deposit in Post Office Savings Schemes",
      },
      {
        declaration: "equityLinkedSavingsScheme",
        label: "Equity Linked Savings Scheme ( ELSS )",
      },
      {
        declaration: "infrastructureBonds",
        label: "Infrastructure Bonds",
      },
      {
        declaration: "interestReinvestedNSC",
        label: "Interest on NSC Reinvested",
      },
      {
        declaration: "kisanVikasPatra",
        label: "Kisan Vikas Patra (KVP)",
      },
      {
        declaration: "lifeInsurancePremium",
        label: "Life Insurance Premium",
      },
      {
        declaration: "longTermInfrastructureBonds",
        label: "Long term Infrastructure Bonds",
      },
      {
        declaration: "mutualFunds",
        label: "Mutual Funds",
      },
      {
        declaration: "ruralBondsNABARD",
        label: "NABARD Rural Bonds",
      },
      {
        declaration: "nationalPensionScheme",
        label: "National Pension Scheme",
      },
      {
        declaration: "schemeNHB",
        label: "NHB Scheme",
      },
      {
        declaration: "postOfficeTimeDepositForFiveYears",
        label: "Post office time deposit for 5 years",
      },
      {
        declaration: "pradhanMantriSurakshaBimaYojana",
        label: "Pradhan Mantri Suraksha Bima Yojana",
      },
      {
        declaration: "publicProvidentFund",
        label: "Public Provident Fund",
      },
      {
        declaration: "repaymentOfHousingLoanPrincipal",
        label: "Repayment of Housing loan(Principal amount)",
      },
      {
        declaration: "stampDutyAndRegistrationCharges",
        label: "Stamp duty and Registration charges",
      },
      {
        declaration: "sukanyaSamriddhiYojana",
        label: "Sukanya Samriddhi Yojana",
      },
      {
        declaration: "unitLinkedInsurancePremium",
        label: "Unit Linked Insurance Premium (ULIP)",
      },
    ],
    category: "80C",
  },
  {
    key: "2",
    sectionFullData: {
      sectionName: "Section 80D",
      sectionType: "Medical Insurance",
      limit:
        "Rs. 25000/Financial year (or) Rs. 75000/Financial year (If parent’s age is above 60 years) ",
      notes:
        "Tax deduction of 50,000 INR can be claimed on Medical Expenditure/Medical Insurance if your parents are above 60 years of age.",
      sectinDescription:
        "Tax deduction on premium paid towards medical insurance",
      sectionInfo:
        "You can experience the benefit of tax deductions on Medical Insurance Premium paid for self, spouse, dependent parents** and dependent children, Preventive Health Check-Up, and/or Medical Expenditure** incurred by you",
    },
    documents: [
      {
        declaration: "preventiveHealthCheckupDependantParents",
        label: "Preventive Health Checkup - Dependant Parents",
        limit: 5000,
      },
      {
        declaration: "medicalBillsVerySeniorCitizen",
        label: "Medical Bills - Very Senior Citizen",
        limit: 50000,
      },
      {
        declaration: "medicalInsurancePremiumBelow60",
        label: "Medical Insurance Premium For Person Below 60",
        limit: 25000,
      },
      {
        declaration: "medicalInsurancePremium60YearsAndAbove",
        label: "Medical Insurance Premium For Person 60 Years And Above",
        limit: 50000,
      },
      {
        declaration: "medicalInsurancePremiumDependantParentsBelow60",
        label: "Medical Insurance Premium - Dependant Parents Below 60",
        limit: 25000,
      },
      {
        declaration: "medicalInsurancePremiumDependantParents60YearsAbove",
        label:
          "Medical Insurance Premium - Dependant Parents 60 Years and Above",
        limit: 50000,
      },
      {
        declaration: "preventiveHealthCheckUp",
        label: "Preventive Health Check-up",
        limit: 5000,
      },
    ],
    category: "80D",
  },
  {
    key: "3",
    sectionFullData: {
      sectionName: "Section 80E",
      sectionType: "Education Loan",
      sectinDescription: "A tax deduction for Interest on Education Loan",
      sectionInfo:
        "You can avail a tax deduction on the interest paid by you on an education loan for higher studies. The loan that has been taken for your child, spouse, self or any other family member is eligible to avail tax benefits.",
      notes:
        "This exemption is available only for 8 years starting from the year in which you start repaying the loan or until the interest is fully repaid whichever is earlier.",
      limit: "Exempt upto the extent of interest paid.",
    },
    category: "80E",
    documents: [
      {
        declaration: "interestLoanHigherSelfEducation",
        label: "A tax deduction for Interest on Education Loan",
      },
    ],
  },
];

const General = ({ the12BBApproved }) => {
  return (
    <div>
      <div className="blue-tabs-deduction-info-tabs-container">
        <Tabs tabPosition="left">
          {generalTabsData.map((data) => {
            return (
              <TabPane
                className="main-content-tabs"
                tab={
                  <SectionData
                    sectionName={data.sectionFullData.sectionName}
                    sectionType={data.sectionFullData.sectionType}
                    sectionInfo={data.sectionFullData.sectinDescription}
                  />
                }
                key={`tab-${data.key}`}
              >
                <SubmitFormContainer
                  key={`container-deduction-${data.key}`}
                  sectionDocuments={data.documents ? data.documents : []}
                  sectionFullData={data.sectionFullData}
                  subCatagory={data.subCategory}
                  Category={data.category}
                  the12BBApproved={the12BBApproved}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default General;
