import { useEffect } from 'react';
import './App.less';
import React from 'react';

import {
  BrowserRouter as Router,
} from "react-router-dom";

import {
  PrivateRoute,
  PublicRoute
} from './config';

import auth from './redux/modules/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ColorGenerator from './utils/ColorGenerator';
import SDKLoaders from './components/Common/SDKLoaders';

export const BackgroundColorContext = React.createContext()


function App({ authReducer, user, isLoggedIn }) {
  useEffect(() => {
    authReducer.check()
  }, [authReducer])
  return (
    <>
      <SDKLoaders />
      <BackgroundColorContext.Provider value={ColorGenerator()}>
        <Router >
          {isLoggedIn ? user && user.role === "org" && user.registerState < 2 ? <PublicRoute user={user} /> : <PrivateRoute /> : <PublicRoute user={null} />}
        </Router>
      </BackgroundColorContext.Provider>
    </>
  );
}

App.propTypes = {
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
}

const AppContainer = connect(
  state => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn
  }),
  dispatch => ({
    authReducer: auth.getActions(dispatch)
  })
)(App);

export default AppContainer;
