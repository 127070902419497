import {
  Button
} from "antd";
import React from 'react';
import './generatedAudit.less';

import RightBracketIcon from "../../../assets/audit/rightBracketIcon.svg";
import { monthList } from "../../../utils/config";


const AuditReport = ({ currentRecord, activeErrorPage, setActiveErrorPage, setCurrentRecord }) => {
  return (
    <>
      <div className='audit_cont' style={{ width: "500px", marginTop: "20px" }} key={currentRecord.createdAt}>
        <div className='audit_head'>
          <h1 style={{ fontSize: "1.6rem", cursor: "pointer" }} onClick={() => { setActiveErrorPage(true); setCurrentRecord(currentRecord) }}>{monthList[parseInt(currentRecord.month ?? 0)]}'s audit list</h1>
          <Button onClick={() => { setActiveErrorPage(true); setCurrentRecord(currentRecord) }}>
            <p>View Details</p>
            <img src={RightBracketIcon} alt='arrow'></img>
          </Button>
        </div>

        <div className='audit_stats_cont'>
          <div className='audit_stats'>
            <p>Employee count</p>
            <h1>{Object.keys(currentRecord.employees).length}</h1>
          </div>
          <div className='audit_stats'>
            <p>Error encountered</p>
            <h1>{currentRecord?.config?.totalErrors ?? 0}</h1>
          </div>
          <div className='audit_stats'>
            <p>Audit Status</p>
            <h3 className='list_status' style={currentRecord.status === 0 ? { color: "gray" } : { color: "#13930F" }}>{currentRecord.statusDetail.length > 20 ? currentRecord.statusDetail.substring(0, 20) + "..." : currentRecord.statusDetail}</h3>
          </div>
        </div>

      </div>
    </>

  )
}
export default AuditReport;