import React, { useState, useEffect } from "react";
import ProofofInvestment from "../../../assets/ProofofInvestment.svg";
import ReimbursementTransactions from "../../../assets/ReimbursementTransactions.svg";
import blueAllowancesProofs from "../../../assets/organisationDashboard/blueAllowancesProofs.svg"
import { Link, withRouter } from "react-router-dom";
import { getProofsCount, getOrgSettlements, getLeaveLogsByStatus } from "../../../apis/organisation"
import { checkFinancialYear } from "../../../utils/config";
import { Skeleton } from 'antd';
import tasksZeroState from "../../../assets/organisationDashboard/task.png"
import { paths } from "../../../constants/routes";

const Tasks = ({ reimbursements, user, authReducer }) => {

    const [countData, setCountData] = useState({ declarations: 0, allowances: 0 })
    const [countLoading, setCountLoading] = useState(false);
    const [settlementData, setSettlementData] = useState([]);
    const[leaveCount,setLeaveCount]=useState(0);
    useEffect(() => {
        employeeSettlement();
        getCountOfProofs();
        getLeaveCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const employeeSettlement = async () => {
        let res = await getOrgSettlements(user.organisationId);
        let pendingSettlement = [];
        (res.data).forEach(element => {
            if (element.status === false) {
                pendingSettlement.push(element);
            }
        });
        setSettlementData(pendingSettlement);
    };
    let pending = 0;

    for (const element of reimbursements) {
        if (element.state === 0) {
            pending++;
        }
    }

    const getCountOfProofs = async () => {
        setCountLoading(true);
        let fyYear = checkFinancialYear().financialYear
        let res = await getProofsCount(user.organisationId, fyYear)
        setCountData(res.data)
        setCountLoading(false);
    }

    const getLeaveCount=async()=>{
        setCountLoading(true);
        //NOTE: 0 is used to represent status:pending
          let res = await getLeaveLogsByStatus(`${user.organisationId}/0`);
          setLeaveCount(res.data.length);
          setCountLoading(false);
    }

    return (
        <div className="tasks-container">
            <p className="h6-bold-18 m-bottom-6">
                Tasks
            </p>
            {
                reimbursements.length > 0 || countData.declarations > 0 || countData.allowances > 0||leaveCount>0
                    ? <>
                        <>
                            {
                                reimbursements.length > 0 && (
                                    <div className="tasks-content-container">
                                        <div className="tasks-content-left">
                                            <img src={ReimbursementTransactions} alt=""></img>
                                            <div className="task-text-container">
                                                <p className="task-type-heading"> Reimbursements : {pending}</p>
                                                <p className="task-status">Pending Approval</p>
                                            </div>
                                        </div>
                                        <Link style={{fontWeight:"600"}} to={paths.org.reimbursement} className="task-button-container">View</Link>
                                    </div>
                                )
                            }
                        </>
                        <>
                            {
                                countData.declarations > 0 && (
                                    <div className="tasks-content-container">
                                        <div className="tasks-content-left">
                                            <img src={ProofofInvestment} alt=""></img>
                                            <div className="task-text-container">
                                                <p className="task-type-heading">Proof of Investments : {countData.declarations} </p>
                                                <p className="task-status">Pending Approval</p>
                                            </div>
                                        </div>
                                        {
                                            countLoading
                                                ? <Skeleton.Button active={true} size={"small"} shape="default" />
                                                : (countData.declarations > 0) && (
                                                    <Link
                                                        style={{fontWeight:"600"}}
                                                        to={{
                                                            pathname: paths.org.investement_and_allowance,
                                                            state: {
                                                                name: "Investment"
                                                            }
                                                        }}
                                                        className="task-button-container"
                                                    >View</Link>
                                                )

                                        }
                                    </div>
                                )
                            }
                        </>
                        <>
                            {
                                countData.allowances > 0 && (
                                    <div className="tasks-content-container">
                                        <div className="tasks-content-left">
                                            <img src={blueAllowancesProofs} alt=""></img>
                                            <div className="task-text-container">
                                                <p className="task-type-heading"> Allowance Proofs : {countData.allowances}</p>
                                                <p className="task-status">Pending Approval</p>
                                            </div>
                                        </div>
                                        {
                                            countLoading
                                                ? <Skeleton.Button active={true} size={"small"} shape="default" />
                                                : (countData.allowances > 0) && (
                                                    <Link
                                                        style={{fontWeight:"600"}}
                                                        to={{
                                                            pathname: paths.org.investement_and_allowance,
                                                            state: {
                                                                name: "Allowance"
                                                            }
                                                        }}
                                                        className="task-button-container"
                                                    >View</Link>
                                                )
                                        }
                                    </div>
                                )
                            }
                        </>
                        <div className="tasks-content-container">
                            <div className="tasks-content-left">
                                <img src={ProofofInvestment} alt=""></img>
                                <div className="task-text-container">
                                    <p className="task-type-heading"> Settlements : {settlementData.length}</p>
                                    <p className="task-status">Pending Approval</p>
                                </div>
                            </div>
                            <Link style={{fontWeight:"600"}} onClick={() => authReducer.updateCurrentTab("separation")} to={{ pathname: paths.org.separation }} className="task-button-container">View</Link>
                        </div>
                        <>
                        {
                                leaveCount > 0 && (
                                    <div className="tasks-content-container">
                                        <div className="tasks-content-left">
                                            <img src={ProofofInvestment} alt=""></img>
                                            <div className="task-text-container">
                                                <p className="task-type-heading"> Leaves : {leaveCount}</p>
                                                <p className="task-status">Pending Approval</p>
                                            </div>
                                        </div>
                                        <Link style={{fontWeight:"600"}} to={paths.org.leave} className="task-button-container">View</Link>
                                    </div>
                                )
                            }
                        </>
                    </>
                    : <div className="py-36 text-align-center">
                        <img
                            alt="img"
                            style={{
                                height: 200,
                                width: 200
                            }}
                            src={tasksZeroState}></img>
                    </div>
            }

        </div>
    )
}

export default withRouter(Tasks);
