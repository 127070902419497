import {
    CloudUploadOutlined, PlusCircleOutlined
} from "@ant-design/icons";
import { Drawer, notification } from "antd";
import React, { useEffect, useState } from 'react';
import { getStateApis } from '../../../../apis/organisation';
import { getAllPaidComplianceReports, initiatePaymentForCompliance } from "../../../../apis/tools";
import { rootUrl, states } from '../../../../utils/config';
import { NormalButton } from '../../../ComponentLibrary';
import Spinner from '../../../ComponentLibrary/Spinner/Spinner';
import DropdownComponent from '../../../InputLibrary/DropdownComponent/DropdownComponent';
import { pdf } from '../../../OrganisationReport/ComplianceReport/data';
import ComplianceDisplayBlocks from './ComplianceDisplayBlocks';
const AuditComplianceReport = ({ currentRecord }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState("MH");
    const [reports, setReports] = useState(null);
    const [pageState, setPageState] = useState(0);
    const [selectedReports, setSelectedReports] = useState({});
    const [downloadState, setDownloadState] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [existReports, setExistReports] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getAllPaidComplianceReports({ organisationId: currentRecord.organisationId, financialYear: currentRecord.financialYear, month: currentRecord.month, }).then(res => {
            if (res.data.data) {
                console.log(res.data)
                setExistReports(res.data.data);
            } else {
                setExistReports({});
            }
        });
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRecord]);



    useEffect(() => {
        if (currentRecord.organisationId && currentRecord.month && currentRecord.financialYear) {
            setIsLoading(true);
            getStateApis({
                organisationId: currentRecord.organisationId,
                stateId: state,
                financialYear: currentRecord.financialYear,
                month: currentRecord.month,
            }).then(res => {
                let reportData = {}
                for (let item in res.data) {
                    reportData[state + "-" + item] = {
                        ...res.data[item],
                        state
                    };
                }
                setReports(Object.assign(reportData));
            }).catch(err => {
                setReports({});
            })
            setIsLoading(false);
        }
    }, [currentRecord, state]);

    const handleNewCompliance = () => {
        setPageState(1);
    }

    const checkPaymentForListAndDownload = () => {
        initiatePaymentForCompliance({
            organisationId: currentRecord.organisationId, financialYear: currentRecord.financialYear, month: currentRecord.month, reports: downloadState,
            returnURL: `${process.env.REACT_APP_VETAN_FRONTEND_URL}/org/utility/audit?orderID={order_id}`
        })
            .then((response) => {
                const paymentSessionId = response?.data?.data?.paymentSessionID;
                const cashfree = new window.Cashfree(paymentSessionId);
                cashfree.redirect();
            })
            .catch(() => {
                notification.error({
                    message:
                        'Sorry, Currently due to some issues, we can not proceed with your payment.',
                });
            });
    }

    return (
        <div >
            {
                pageState === 0 ?
                    (<div className='app'>
                        <div className='display-center-content-space-between' style={{ width: "100%" }}>
                            <h2>Compliance Reports</h2>
                            <div> <NormalButton buttonText={"Create New"} onClick={handleNewCompliance} type={13} /></div>
                        </div>
                        <div>
                            {
                                Object.keys(existReports).length ? Object.values(existReports).map((item, index) => {
                                    return (<ComplianceDisplayBlocks
                                        key={`doc-value-${index}`}
                                        margin={[15, 0]}
                                        bgcolor={"#f4f4f6"}
                                        width={"95%"}
                                        padding={[10, 20]}
                                        borderRadius={"5px"}
                                        iconTextColor={"white"}
                                        iconFontSize={"0.8rem"}
                                        iconWidth={"2rem"}
                                        displayText={item.label + "-" + item.state}
                                        downloadLink={`${rootUrl}/api${item.api}`}
                                        downloadType={[pdf]}
                                        setIsAPILoading={setIsLoading}
                                        status={false}
                                        selectedValue={{}}
                                        selectedReports={{}}
                                        setSelectedReports={() => { }}
                                        allReports={{}}
                                    />)
                                }) : <div className='m-top-50 flex justify-center align-center' style={{ marginTop: "40px", flexDirection: "column" }}>
                                    <h2>No Records Created</h2>
                                    <PlusCircleOutlined style={{ fontSize: "9rem", marginTop: "15px", color: "gray" }} onClick={handleNewCompliance} />
                                </div>
                            }
                        </div>
                    </div>)
                    :
                    (<div className="site-layout app-main-content-container paper-wrapper" style={{ width: '100%' }}>
                        <Drawer width={"500px"} title="Selection Previewer" visible={drawerOpen} onClose={() => { setDrawerOpen(false); }}>
                            {
                                Object.keys(downloadState).map(item => {
                                    return (
                                        <ComplianceDisplayBlocks
                                            key={`doc-value-${item}`}
                                            margin={[15, 10]}
                                            bgcolor={"#f4f4f6"}
                                            width={"95%"}
                                            padding={[10, 20]}
                                            borderRadius={"5px"}
                                            iconTextColor={"white"}
                                            iconFontSize={"0.8rem"}
                                            iconWidth={"2rem"}
                                            displayText={downloadState[item].label + "-" + downloadState[item].state}
                                            downloadLink={`${rootUrl}/api${downloadState[item].api}`}
                                            downloadType={[pdf]}
                                            setIsAPILoading={setIsLoading}
                                            status={true}
                                            selectedValue={item}
                                            selectedReports={downloadState}
                                            setSelectedReports={setDownloadState}
                                            allReports={downloadState} />
                                    )
                                })
                            }
                            <NormalButton buttonText={"Pay and download"} margin={[20, 20, 20, 20]} type={13} onClick={() => {
                                checkPaymentForListAndDownload()
                            }} />
                        </Drawer>
                        <Spinner spinning={isLoading} size="large">
                            <div className="flex align-center justify-between">
                                <div>
                                    <h1 className="font-size-global-24 font-weight-global-700 m-top-16 m-left-12">Compliance Reports</h1>
                                    <DropdownComponent
                                        className="general-ant-field-select"
                                        labeltext={"State"}
                                        margin={[10, 0, 10, 10]}
                                        size={"large"}
                                        value={state}
                                        values={Object.keys(states).map(val => states[val])}
                                        options={Object.keys(states)}
                                        showSearch
                                        onChange={(e) => {
                                            setState(e);
                                            setSelectedReports({});
                                            setReports({});
                                        }}
                                    />
                                </div>
                                <div className="flex align-center justify-end w-quater-past-half">
                                    <div>
                                        <NormalButton buttonText={<span><CloudUploadOutlined /> {Object.keys(downloadState).length} Selected</span>} type={18} padding={[12, 20, 12, 20]} onClick={() => {
                                            setDrawerOpen(true);
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-start m-top-30">
                                <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                                    {reports &&
                                        Object.keys(reports).map((val, index) => (
                                            <ComplianceDisplayBlocks
                                                key={`doc-value-${index}`}
                                                margin={[15, 10]}
                                                bgcolor={"#f4f4f6"}
                                                width={"30%"}
                                                padding={[10, 20]}
                                                borderRadius={"5px"}
                                                iconTextColor={"white"}
                                                iconFontSize={"0.8rem"}
                                                iconWidth={"2rem"}
                                                displayText={reports[val].label}
                                                downloadLink={`${rootUrl}/api${reports[val].api}`}
                                                downloadType={[pdf]}
                                                setIsAPILoading={setIsLoading}
                                                status={true}
                                                selectedValue={val}
                                                selectedReports={selectedReports}
                                                setSelectedReports={setSelectedReports}
                                                allReports={reports}
                                            />
                                        ))}
                                </div>
                            </div>
                            <div className="m-top-10">
                                <NormalButton buttonText={"Add to download"} type={13} padding={[12, 20, 12, 20]} onClick={() => { setDownloadState({ ...downloadState, ...selectedReports }) }} />
                                <NormalButton buttonText={"Cancel"} type={3} padding={[12, 20, 12, 20]} onClick={() => {
                                    setSelectedReports({});
                                    setPageState(0);
                                }} />
                            </div>
                        </Spinner>
                    </div>)
            }</div>
    )
};



export default AuditComplianceReport;