import React, { useEffect, useState } from 'react'
import { getAllRecordsForFY } from '../../../apis/tools';
import AuditReport from './AuditReport';
import {
    ArrowLeftOutlined,
} from "@ant-design/icons";
import { NormalButton } from '../../ComponentLibrary';
import { useHistory } from 'react-router-dom';
import AuditValidationPage from '../AuditErrorValidation/index';
const AuditReports = ({ user, financialYear }) => {
    const [allReports, setAllReports] = useState([]);
    const history = useHistory();
    const [activeErrorPage, setActiveErrorPage] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});

    useEffect(() => {
        if (user.organisationId && financialYear) {
            getAllRecordsForFY({ organisationId: user.organisationId, financialYear }).then(res => {
                setAllReports(res.data.data);
            })
        }
    }, [financialYear, user.organisationId]);

    return (
        <div>
            <div className='flex  align-center m-bottom-20'>
                <NormalButton type={25} buttonText={<ArrowLeftOutlined />} onClick={() => { history.push('/org/utility/audit') }} />
                <h2 className='m-left-20' >Audit Report</h2>
            </div>
            {!activeErrorPage ? <div className='audit_list_cont m-left-20'>
                {allReports.map((item, index) => {
                    return <AuditReport key={"audit-month-" + index} currentRecord={item} setActiveErrorPage={setActiveErrorPage} activeErrorPage={activeErrorPage} setCurrentRecord={setCurrentRecord} />
                })}
            </div> : <AuditValidationPage currentRecord={currentRecord} user={user} />}
        </div>
    )
}

export default AuditReports