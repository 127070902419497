import { DownloadOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "../../../apis";
import { numformatter, rootUrl } from "../../../utils/config";
import { NormalButton } from "../../ComponentLibrary";
// import SendMailModal from "./SendMailModal";
import { useEffect, useState } from "react";
import MonthCloseProcess from "../../MonthCloseProcess/MonthCloseProcess";
import OverridePayslipContainer from "./OverridePayslip";
import SendNewMailModal from "./SendNewMailModal";

const EmployeeSummary = ({
  payslips,
  month,
  financialYear,
  isFreezed,
  getPayslipFreeze,
  setTransactionModal,
  setTransferType,
  payout,
  currentFinancialYear,
  setModal,
  modal,
  updatePayrollData,
  buttonDisabled,
  setModalVisible,
  setButtonDisabled,
  modalVisible,
  employeeList,
  orgPayslipInfo,
  payroll,
  user,
}) => {
  const [overrideModal, setOverrideModal] = useState(false);
  const [blockedEmployeeList, setBlockedEmployeeList] = useState();

  useEffect(() => {
    axios
      .get(
        `/employees/blocked/payroll/${user.organisationId}/${month}/${financialYear}`
      )
      .then((res) => {
        setBlockedEmployeeList(res.data.data);
      });
  }, [payslips, user, month, financialYear]);
  const columns = [
    {
      title: "ID",
      dataIndex: "employeeId",
      key: "employeeId",
      render: (_data, row) => (
        <div>
          {row.user.currentOrganisationMeta.employeeId !== undefined
            ? row.user.currentOrganisationMeta.employeeId
            : "N/A"}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (_data, row) => (
        <div>
          {row.user.displayName !== undefined ? row.user.displayName : "N/A"}
        </div>
      ),
    },
    {
      title: "Gross Pay (in INR)",
      dataIndex: ["finalPayslip", "totalEarnings"],
      key: "grossPay",
      render: (_data, row) => (
        <div>{numformatter(row.finalPayslip && row.finalPayslip.totalEarnings ? row.finalPayslip.totalEarnings : 0)}</div>
      ),
    },
    {
      title: "TAX (in INR)",
      dataIndex: ["finalPayslip", "oldRegime", "incomeTax"],
      key: "tax",
      render: (_data, row) => (
        <div>
          {row.user.regimeOptedStatus === 1
            ? numformatter(row.finalPayslip && row.finalPayslip.newRegime && row.finalPayslip.newRegime.incomeTax ? row.finalPayslip.newRegime.incomeTax : 0)
            : numformatter(row.finalPayslip && row.finalPayslip.oldRegime && row.finalPayslip.oldRegime.incomeTax ? row.finalPayslip.oldRegime.incomeTax : 0)}
        </div>
      ),
    },
    {
      title: "Professional Tax(in INR)",
      dataIndex: ["finalPayslip", "proffTax"],
      key: "proffTax",
      render: (_data, row) => (
        <div>{numformatter(row.finalPayslip && row.finalPayslip.proffTax ? row.finalPayslip.proffTax : 0)}</div>
      ),
    },
    {
      title: "Total Deduction (in INR)",
      dataIndex: "deduction",
      key: "deduction",
      render: (_data, row) => (
        <div>
          {row.user.regimeOptedStatus === 1
            ? numformatter(row.finalPayslip && row.finalPayslip.newRegime && row.finalPayslip.newRegime.deductions ? row.finalPayslip.newRegime.deductions : 0)
            : numformatter(row.finalPayslip && row.finalPayslip.oldRegime && row.finalPayslip.oldRegime.deductions ? row.finalPayslip.oldRegime.deductions : 0)}
        </div>
      ),
    },
    {
      title: "Net Pay (in INR)",
      dataIndex: "netpay",
      key: "netpay",
      render: (_data, row) => (
        <div>
          {row.user.regimeOptedStatus === 1
            ? numformatter(row.finalPayslip && row.finalPayslip.newRegime && row.finalPayslip.newRegime.takeHome ? row.finalPayslip.newRegime.takeHome : 0)
            : numformatter(row.finalPayslip && row.finalPayslip.oldRegime && row.finalPayslip.oldRegime.takeHome ? row.finalPayslip.oldRegime.takeHome : 0)}
        </div>
      ),
    },
    {
      title: "Payroll Status",
      dataIndex: "status",
      key: "status",
      render: (_data, row) => (
        <div>
          {isFreezed ? "Processed" : (blockedEmployeeList && blockedEmployeeList[row.user._id] ? "Blocked" : "Unblocked")}
        </div>
      ),
    },

  ];
  const handleAction = async (url) => {
          let res = await axios({
            url, //your url
            method: "GET",
            responseType: "blob",
          });
          const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = urlDownload;
          link.setAttribute("download", `payroll.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
        };;
  return (
    <div>
      <Content className="active-client-management-card app-white-card m-bottom-36">
        <div className="app-flex-container-1">
          <h1 className="app-heading-2 font-weight-global-700">
            (Payroll) Summary
          </h1>
          <div>
            {!isFreezed &&
              payroll.payrollAvailable &&
              financialYear === currentFinancialYear && (
                <>
                  <NormalButton
                    type={12}
                    buttonText="Override Payroll"
                    onClick={() => setOverrideModal(true)}
                  />
                  <OverridePayslipContainer
                    modal={overrideModal}
                    setModal={setOverrideModal}
                    financialYear={financialYear}
                    month={month}
                    getPayslipFreeze={getPayslipFreeze}
                  />
                </>
              )}
            {!isFreezed && financialYear === currentFinancialYear && (
              <>
                <NormalButton
                  type={13}
                  buttonText="Run payroll"
                  onClick={() => setModal(true)}
                />
                <MonthCloseProcess
                  modal={modal}
                  setModal={setModal}
                  updatePayrollData={updatePayrollData}
                  month={month}
                  financialYear={financialYear}
                  orgPayslipInfo={orgPayslipInfo}
                  getPayslipFreeze={getPayslipFreeze}
                />
              </>
            )}
            {isFreezed &&
              payroll.payrollAvailable &&
              payout &&
              Object.keys(payout).length > 0 &&
              payout.instanceStatus === 3 &&
              financialYear === currentFinancialYear && (
                <NormalButton
                  type={6}
                  buttonText="Process Payment"
                  onClick={() => {
                    setTransactionModal(true);
                    setTransferType(0);
                  }}
                />
              )}
            {isFreezed &&
              payroll.payrollAvailable &&
              !payroll.mailPayslip &&
              financialYear === currentFinancialYear && (
                <>
                  <NormalButton
                    disabled={buttonDisabled}
                    onClick={() => {
                      setModalVisible(true);
                      setButtonDisabled(true);
                    }}
                    buttonText={"Mail Payslips"}
                    type={13}
                  />
                  {/* <SendMailModal
                    modalVisible={modalVisible}
                    allEmp={employeeList}
                    setModalVisible={setModalVisible}
                    month={month}
                    financialYear={financialYear}
                    setButtonDisabled={setButtonDisabled}
                    updatePayrollData={updatePayrollData}
                    organisationId={user.organisationId}
                  /> */}
                  <SendNewMailModal
                    modalVisible={modalVisible}
                    allEmp={employeeList}
                    setModalVisible={setModalVisible}
                    month={month}
                    financialYear={financialYear}
                    setButtonDisabled={setButtonDisabled}
                    updatePayrollData={updatePayrollData}
                    organisationId={user.organisationId}
                  />
                </>
              )}
            <a
              href
              download
              className="m-left-10"
              onClick={() =>
                handleAction(
                  `${rootUrl}/api/payslip/excel/${user.organisationId}/${month}/${financialYear}`
                )
              }
            >
              <DownloadOutlined /> Download Excel
            </a>
          </div>
        </div>
        <Table dataSource={payslips} columns={columns} />
      </Content>
    </div>
  );
};

export default EmployeeSummary;
