import { useState,useEffect } from "react"
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { HeaderComponent } from '../Common';
import EmployeeUploadFiles from "./Components/EmployeeUploadFiles";
import UploadList from "./Components/UploadList";
import organisation from "../../redux/modules/organisation";

const AllInvestementAllowance = ({ history, location, user }) => {

    let pageData = location.state
    const [pageState, setPageState] = useState(0)
    const [employeeData, setEmployeeData] = useState({})

    useEffect(() => {
        document.title = 'Investment | Allowances';
      });

    return (
        <div className="site-layout">
            <HeaderComponent name={pageData.name} showMoolCoins={true} />
            {
                pageState === 0
                    ? (
                        <UploadList
                            history={history}
                            setPageState={setPageState}
                            pageData={pageData}
                            user={user}
                            setEmployeeData={setEmployeeData}
                        />
                    )
                    : (
                        <EmployeeUploadFiles
                            user={user}
                            pageData={pageData}
                            employeeData={employeeData}
                            setEmployeeData={setEmployeeData}
                            setPageState={setPageState}
                        />
                    )
            }
        </div>
    )
}

const AllInvestementAllowanceContainer = connect((state) => ({
    user: state.auth.user,
}),
    (dispatch) => ({
        organisationReducer: organisation.getActions(dispatch),
    })
)(AllInvestementAllowance);

export default withRouter(AllInvestementAllowanceContainer);
