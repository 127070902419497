import { Col, Row } from 'antd';
import { useState,useEffect } from 'react';
import Form16 from "./components/form16.js";
import Payslip from "./components/payslip.js";
import { HeaderComponent } from '../Common';
import activeDocument from "../../assets/icons/activeIcons/activeDocument.svg";

const EmployeeDocuments = (props) => {

    let joiningDate = new Date("2005", "11", "17");
    let todaysDate = new Date();

    let monthNames = ["April", "May", "June",
        "July", "August", "September", "October", "November", "December", "January", "February", "March"];


    const [dateOfJoining, setDateOfJoining] = useState({
        day: joiningDate.getDay(),
        month: changeMonthNumberAccordingToFinancialYear(joiningDate.getMonth()),
        year: joiningDate.getFullYear(),
        lessThanAYear: false
    });

    // eslint-disable-next-line no-unused-vars
    const [todayDate, setTodayDate] = useState({
        day: todaysDate.getDay(),
        month: changeMonthNumberAccordingToFinancialYear(todaysDate.getMonth()),
        year: todaysDate.getFullYear()
    })

    if (todaysDate.getTime() - joiningDate.getTime() < 31536000000 && dateOfJoining.lessThanAYear === false) {
        setDateOfJoining({
            ...dateOfJoining,
            lessThanAYear: true
        })
    }

    // eslint-disable-next-line no-unused-vars
    const [paySlipData, setPaySlipData] = useState({
        monthNames: monthNames,
        paySlipGenerationDate: "5th"
    })

    useEffect(() => {
        document.title = 'Employee | Documents';
      });

    return (
        <>
            <div className="site-layout">
                <HeaderComponent icon={activeDocument} name="Documents" />
                <div className="app-main-content-container">
                    <Row gutter={[20,20]}>
                        <Col sm={24} md={16}>
                            <Payslip todayDate={todayDate} dateOfJoining={dateOfJoining} paySlipData={paySlipData} />
                        </Col>
                        <Col sm={24} md={8}>
                            <Form16 todayDate={todayDate} dateOfJoining={dateOfJoining} />
                        </Col>
                    </Row>
                </div>
            </div >
        </>
    )
}

const changeMonthNumberAccordingToFinancialYear = (month) => {
    if (month > 2 && month < 12) {
        return month - 3;
    }
    else {
        return month + 9;
    }
}

export default EmployeeDocuments;