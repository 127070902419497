import { Modal } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import React, { useState } from 'react'
import UploadIcon from '../../../assets/audit/uploadIcon.svg'
import { handleAction } from '../../../constants/common_functions'
import config, { monthList } from '../../../utils/config'
import DropdownComponent from '../../InputLibrary/DropdownComponent/DropdownComponent'
import ParserValidator from '../ZeroState/ParserValidator'

const UploadNewModal = ({ organisationId, month, financialYear, setPageState }) => {
    const [currentMonth, setCurrentMonth] = useState(month);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadModal] = useState(true);
    const [parsedData, setParserData] = useState({});
    const [parsedPageState, setParserPageState] = useState(1);

    const platforms = {
        name: "file",
        action: `${config.rootUrl}/api/audit/report_parser`,
        headers: {
            authorization: localStorage.getItem("mool_auth"),
        },
        multiple: false,
        async onChange(info) {
            setIsLoading(true);
            let { response } = info.file;
            if (response) {
                setIsLoading(false);
                setParserPageState(0);
                setParserData(response.data);
            }
        },
    };
    return (
        <div>
            {
                parsedPageState === 1 ?
                    <Modal confirmLoading={isLoading} title="" visible={uploadModal} footer={[]} onCancel={() => setPageState(1)}>
                        <div className='modal_Inner_cont'>
                            <div>
                                <h1 className='upload_head'>Upload and attach files</h1>
                                <p className='p_text'>
                                    <a
                                        href
                                        className="download-employee-list"
                                        onClick={() =>
                                            handleAction(
                                                `${config.rootUrl}/api/audit/report/${organisationId}/${currentMonth}/${financialYear}`,
                                                "Audit_Report_" + monthList[currentMonth] + ".xlsx"
                                            )
                                        }
                                    >
                                        Download sample .xlsx format
                                    </a> or drag and drop</p>
                            </div>

                            <div className='modal_upload_box'>
                                <Dragger
                                    {...platforms}
                                    showUploadList={false}
                                    className="dragger"
                                >
                                    {
                                        <div className="dragger-info-container">
                                            <img src={UploadIcon} alt="upload icon" />
                                            <p className="ant-upload-text">
                                                Drag and drop your file or click here to upload
                                            </p>
                                        </div>
                                    }
                                </Dragger>
                            </div>

                            <div className='modal_input_box'>
                                <DropdownComponent disabled={true} labeltext={"Month"} width={"100%"} values={Object.keys(monthList)} options={monthList} value={monthList[currentMonth]} onChange={e => setCurrentMonth(e)} />
                            </div>

                        </div>
                    </Modal>
                    :
                    <Modal width={"700px"} title="" visible={uploadModal} footer={[]} cancelButtonProps={{}}>
                        <ParserValidator setAuditData={() => { }} parsedData={parsedData} user={{ organisationId }} setPageState={setPageState} month={currentMonth} financialYear={financialYear} />
                    </Modal>
            }
        </div >
    )
}

export default UploadNewModal