/* eslint-disable jsx-a11y/anchor-is-valid */
import { Checkbox } from 'antd';
import axios from '../../../../apis';
import { pdf } from '../../../OrganisationReport/ComplianceReport/data';

const ComplianceDisplayBlocks = ({
    margin,
    bgcolor,
    width,
    padding,
    borderRadius,
    iconTextColor,
    iconFontSize,
    iconWidth,
    displayText,
    downloadLink,
    downloadType,
    setIsAPILoading,
    status,
    selectedValue,
    selectedReports,
    setSelectedReports,
    allReports,
}) => {
    margin = margin ? margin : "[0 0]";
    bgcolor = bgcolor ? bgcolor : "white";
    width = width ? width : "100%";
    padding = padding ? padding : "[0,0]";
    borderRadius = borderRadius ? borderRadius : "0px";
    const handleAction = async (url, type) => {
        setIsAPILoading(true);
        let res = await axios({
            url, //your url
            method: "GET",
            responseType: "blob"
        });
        const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = urlDownload;
        link.setAttribute("download", `${displayText}.${type}`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setIsAPILoading(false);
    };
    const handleUnCheck = (e) => {
        if (e.target.checked) {
            selectedReports[e.target.name] = allReports[e.target.name]
        } else {
            delete selectedReports[e.target.name];
        }
        setSelectedReports(selectedReports)
        console.log({ selectedReports, name: e.target.name, checked: e.target.checked })
    }
    return (
        <div
            style={{
                margin: `${margin[0]}px ${margin[1]}px`,
                backgroundColor: bgcolor,
                width: width,
                padding: `${padding[0]}px ${padding[1]}px`,
                borderRadius: borderRadius
            }}
            className="flex align-center justify-between"
        >
            <div className="flex align-center">
                {
                    status &&
                    <Checkbox name={selectedValue} defaultChecked={selectedReports[selectedValue]?.label ? true : false} onChange={handleUnCheck} />
                }
                <p className="m-left-12 font-weight-500">
                    <div className='flex justify-center align-center'>
                        {status && <a
                            href=""
                            style={{
                                backgroundColor: pdf.color,
                                color: iconTextColor,
                                width: iconWidth,
                                height: iconWidth,
                                borderRadius: "3px",
                                fontSize: iconFontSize,
                                fontWeight: "bold"
                            }}
                            className="flex align-center justify-center m-right-12"
                        >
                            {" "}{pdf.type}
                        </a>}
                        {displayText}
                    </div>
                </p>
            </div>
            {
                !status && <div className="flex align-center justify-around">
                    <p className="m-right-12 font-weight-700">Download: </p>
                    {downloadType.map(val =>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleAction(downloadLink, val.type)
                            }}
                            style={{
                                backgroundColor: val.color,
                                color: iconTextColor,
                                width: iconWidth,
                                height: iconWidth,
                                borderRadius: "3px",
                                fontSize: iconFontSize,
                                fontWeight: "bold"
                            }}
                            className="flex align-center justify-center m-right-12"
                        >
                            {" "}{val.type}
                        </a>
                    )}
                </div>
            }
        </div>
    );
};

export default ComplianceDisplayBlocks;
