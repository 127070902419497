import { Modal, notification, Spin, Table, Select } from "antd";
import { useState, useEffect } from "react";
import { NormalButton } from "../../../ComponentLibrary";
import {
  createOrUpdateLocation,
  deleteLocation,
  updateEmployerOnBoarding,
} from "../../../../apis/organisation";
import plusSymbol from "../../../../assets/icons/whitePlus.svg"; import {
  deleteLogo,
  editLogo
} from "../../../../assets/common/editAndDeleteLogos";
import organisation from "../../../../redux/modules/organisation";
import { connect } from "react-redux";
import config, { states, statesCodes } from "../../../../utils/config";
import { HeaderComponent } from "../../../Common";
import { createLog } from "../../../../apis/user";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import { getKeyByValue, pincodeData } from "../../../../constants/common_functions";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const { Option } = Select;

const Location = ({
  location,
  organisationReducer,
  user, onboarding, isTrial }) => {
  const [
    showCreateOrUpdateLocationModal,
    setShowCreateOrUpdateLocationModal
  ] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isUpdateFunction, setIsUpdateFunction] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newLocationData, setNewLocationData] = useState({
    officeName: "",
    color: "#fff",
    address: "",
    postalCode: null,
    country: "India",
    city: "",
    state: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: "Office Name",
      dataIndex: "officeName",
      width: "30%"
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "30%"
    },
    {
      title: "ID",
      dataIndex: "locationId",
      width: "30%"
    },
    {
      title: "No. of Employees",
      dataIndex: "totalNoOfEmployees",
      width: "30%"
    },
    {
      title: "",
      dataIndex: "locationId",
      width: "30%",
      render: (locationId, rowData) => (
        <div className="flex justify-end align-center">
          <NormalButton
            type={5}
            icon={editLogo}
            margin={[3, 0]}
            onClick={() => {
              setNewLocationData({
                locationId: locationId,
                officeName: rowData.officeName,
                color: "#fff",
                address: rowData.address,
                city: rowData.city,
                postalCode: rowData.postalCode,
                state: rowData.state,
                country: rowData.country
              });
              setUpdateData(rowData);
              setShowCreateOrUpdateLocationModal(true);
              setIsUpdateFunction(true);
            }}
          />
          <NormalButton
            type={5}
            margin={[3, 0]}
            icon={deleteLogo}
            onClick={() => {
              setUpdateData(rowData);
              setShowDeleteModal(true);
            }}
          />
        </div>
      )
    },
  ];

  let onCancel = () => {
    setNewLocationData({
      officeName: "",
      color: "#fff",
      address: "",
      postalCode: null,
      country: "India",
      city: "",
      state: "MH"
    });
    setShowCreateOrUpdateLocationModal(false);
    setIsUpdateFunction(false);
    setUpdateData({});
  };

  const updateOnboarding = async (type) => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id
      });
    }
  };

  const onSave = async () => {
    if (newLocationData.officeName === "") {
      notification.error({ message: "Name cannot be empty." });
      return;
    }
    if (newLocationData.address === "") {
      notification.error({ message: "Address cannot be empty." });
      return;
    }
    if (newLocationData.city === "") {
      notification.error({ message: "City cannot be empty." });
      return;
    }
    if (!newLocationData.country) {
      notification.error({ message: "Country cannot be empty." });
      return;
    }
    if (newLocationData.state === "") {
      notification.error({ message: "State cannot be empty." });
      return;
    }
    if (!parseInt(newLocationData.postalCode)) {
      notification.error({ message: "Pincode cannot be empty." });
      return;
    }

    if (isUpdateFunction) {
      if (
        newLocationData.officeName !== updateData.officeName ||
        newLocationData.address !== updateData.address ||
        newLocationData.state !== updateData.state ||
        newLocationData.postalCode !== updateData.postalCode ||
        newLocationData.country !== updateData.country ||
        newLocationData.city !== updateData.city
      ) {
        newLocationData["locationId"] = updateData.locationId;
        setIsLoading(true);
        let res = await createOrUpdateLocation({
          ...newLocationData,
          organisationId: user.organisationId
        });
        setIsLoading(false);
        if (res.status === 200 && res.data.success) {
          await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `User has updated a location`,
            category: 14,
          });
          organisationReducer.getLocation(user.organisationId);
          notification.success({
            message: "Successfully updated the location."
          });
          onCancel();
          return;
        } else {
          notification.error({
            message: "Failed to create new location, please try again."
          });
        }
      }
    } else {
      setIsLoading(true)
      let res = await createOrUpdateLocation({
        ...newLocationData,
        organisationId: user.organisationId
      });
      setIsLoading(false)
      if (res.status === 200 && res.data.success) {
        await updateOnboarding("location");
        await createLog({
          name: user.displayName,
          userId: user._id,
          organisationId: user.organisationId,
          message: `User has created a location`,
          category: 14,
        });
        notification.success({ message: "Successfully added the location." });
        organisationReducer.getLocation(user.organisationId);
        onCancel();
        return;
      } else {
        notification.error({
          message: "Failed to create new location, please try again."
        });
      }
    }
    organisationReducer.loading(false);
  };

  const onDeleteConfirm = async () => {
    setIsLoading(true);
    let res = await deleteLocation(updateData.locationId);
    setIsLoading(false);
    if (res.status === 200 && res.data.success) {
      notification.success({ message: "Successfully deleted the location." });
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has deleted a location`,
        category: 14,
      });
      organisationReducer.getLocation(user.organisationId);
      setShowDeleteModal(false);
      setUpdateData({});
    } else {
      notification.error({
        message: "Failed to delete the location, please try again."
      });
    }
  };
  useEffect(() => {
    document.title = 'Settings | Office Locations';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={" Configure Mool as per your company policy"} bgImage={configurationsBgImage}
    /> :
    <div className="site-layout">
      <HeaderComponent show={false}
        name="Office Locations" />
      <div className="app-main-content-container paper-wrapper">

        <div>
          <h1 className="h1-bold-24">Office Location</h1>        <p className="tab-subtitle-1">Add and manage all your office locations</p>
          <Spin spinning={isLoading} size="large">
            <div className="flex justify-between align-center">
              <p className="h5-bold-20">List ({location.length})</p>
              <NormalButton
                icon={plusSymbol}
                buttonText="Add New"
                type={13}
                onClick={() => {
                  setShowCreateOrUpdateLocationModal(true);
                }}
              />
            </div>
            <Table
              style={{ fontWeight: 500, marginTop: 16 }}
              columns={columns}
              dataSource={location.reverse()}
              pagination={false}
              scroll={{ y: 520 }}
            />
          </Spin>
        </div>
        <Modal
          centered
          closable={false}
          visible={showCreateOrUpdateLocationModal}
          className="modal-container"
          footer={null}
          width={400}
          onCancel={onCancel}
          loading={isLoading}
        >
          <Spin spinning={isLoading} size="default">
            <div className="">
              <div className="flex justify-between">
                <h1 className="m-bottom-20 font-size-global-24 font-weight-global-700 modal-margin-left-global-heading">
                  {isUpdateFunction ? "Update Location" : "Add Location"}
                </h1>
              </div>
              <div style={{ marginTop: "-1em", display: "flex", justifyContent: "center" }} className="app-input-field-container-1">
                <InputFieldComponent
                  className={`input-field-container input-field`}
                  labeltext={"Name"}
                  autoFocus={true}
                  value={newLocationData.officeName}
                  onChange={(e) => {
                    setNewLocationData({
                      ...newLocationData,
                      officeName: e.target.value
                    });
                  }}
                />
              </div>
              <div style={{ marginTop: "-2em", display: "flex", justifyContent: "center" }} className="app-input-field-container-1">
                <InputFieldComponent
                  className={`input-field-container input-field`}
                  labeltext={"Address"}
                  value={newLocationData.address}
                  onChange={(e) => {
                    setNewLocationData({
                      ...newLocationData,
                      address: e.target.value
                    });
                  }}
                />
              </div>
              <div style={{ marginTop: "-2em", display: "flex", justifyContent: "center" }} className="app-input-field-container-1">
                <InputFieldComponent
                  className={`input-field-container input-field`}
                  labeltext={"City"}
                  value={newLocationData.city}
                  onChange={(e) => {
                    setNewLocationData({
                      ...newLocationData,
                      city: e.target.value
                    });
                  }}
                />
              </div>
              <div style={{ marginTop: "-2em", display: "flex", justifyContent: "center" }} className="app-input-field-container-1">
                <InputFieldComponent
                  className={`input-field-container input-field`}
                  type={"number"}
                  value={newLocationData.postalCode}
                  labeltext={"Postal Code"}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      return;
                    }
                    setNewLocationData({
                      ...newLocationData,
                      postalCode: parseInt(e.target.value)
                    });

                    if (parseInt(e.target.value) && e.target.value.length < 7) {
                      setNewLocationData({
                        ...newLocationData,
                        postalCode: parseInt(e.target.value)
                      });
                      if (e.target.value.length === 6) {
                        setNewLocationData({
                          ...newLocationData,
                          postalCode: parseInt(e.target.value)
                        });
                        const data = pincodeData(e.target.value);
                        Promise.resolve(data).then((res) => {

                          setNewLocationData({
                            ...newLocationData,
                            postalCode: parseInt(e.target.value),
                            city: res.Block,
                            state: getKeyByValue(statesCodes, res.State),
                          });
                        });
                      }
                    }
                  }}
                />
              </div>
              <div style={{ marginTop: "-2em", display: "flex", justifyContent: "center" }} className="app-input-field-container-1">
                {
                  <DropdownComponent
                    size="large"
                    labeltext={"Organization State"}
                    value={newLocationData.state}
                    options={Object.keys(states)}
                    values={Object.values(states)}
                    style={{ width: "100%", paddingLeft: '5px' }}
                    showSearch
                    optionFilterProp='state'
                    filterOption={(input, option) => (option.children.split(" ").join("").toLowerCase()).includes(input.toLowerCase())}
                    onChange={(val) =>
                      setNewLocationData({
                        ...newLocationData,
                        state: val
                      })
                    }
                  >
                    {Object.keys(config.states).map((val, index) => (
                      <Option value={config.states[val]} key={`month-${index}`}>
                        {val}
                      </Option>
                    ))}
                  </DropdownComponent>
                }
              </div>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: "2.5em" }} className="app-input-field-container-1">
                <InputFieldComponent
                  className={`input-field-container input-field`}
                  labeltext={"Country"}
                  onPressEnter={onSave}
                  value={newLocationData.country}
                />
              </div>
              <div className="m-top-1 modal-margin-left-global-1">
                <NormalButton
                  type={13}
                  buttonText={isUpdateFunction ? "Update" : "Save"}
                  onClick={onSave}
                />
                <NormalButton
                  type={5}
                  buttonText={"Cancel"}
                  margin={[0, 12]}
                  onClick={onCancel}
                />
              </div>
            </div>
          </Spin>
        </Modal>
        <Modal
          loading={isLoading}
          centered
          closable={false}
          visible={showDeleteModal}
          className="modal-container"
          footer={null}
          onCancel={() => {
            setShowDeleteModal(false);
            setUpdateData({});
          }}
        >
          <Spin spinning={isLoading}>
            <div className="">
              <div className="flex justify-between">
                <h1 className="app-heading-2">Delete Location</h1>
              </div>
              <div className="">
                <p className="font-size-16 font-weight-500">
                  Are you sure you want to delete the location of "
                  {updateData.officeName}" office
                </p>
              </div>
              <div className="m-top-36  text-align-right">
                <NormalButton
                  type={5}
                  buttonText={"Cancel"}
                  margin={[0, 12]}
                  onClick={() => {
                    setShowDeleteModal(false);
                    setUpdateData({});
                  }}
                />
                <NormalButton
                  type={5}
                  buttonText="Delete"
                  onClick={() => {
                    onDeleteConfirm();
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </div>
  );
};

const LocationContainer = connect(
  (state) => ({
    deviceWidth: state.responsive.deviceWidth,
    location: state.organisation.location,
    loading: state.organisation.loading,
    user: state.auth.user,
    onboarding: state.organisation.onboarding,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch)
  })
)(Location);

export default LocationContainer;
