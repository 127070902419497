import { useState, useEffect } from "react";
import { Layout, Slider, Button, Skeleton, Row, Col } from "antd";
import Chart from "react-apexcharts";
import employee from "../../redux/modules/employee";
import auth from "../../redux/modules/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import config, {
  checkFinancialYear,
  numformatter,
} from "../../utils/config";
import { HeaderComponent } from "../Common";
import activeSalary from "../../assets/icons/activeIcons/activeSalary.svg";

const { Content } = Layout;

const SalaryManagement = ({
  employeeReducer,
  organisationSettings,
  ctc,
  user,
  finalPayslip,
  selectedIndex,
  isChanged,
  marker,
  freezeInfo,
  moolData,
  isPercentageStructure,
}) => {
  const [userRegime, setUserRegime] = useState("oldRegime");
  const [ctcValue, setCTCValue] = useState(0);
  const [fixedAmount, setFixedAmount] = useState(0);
  const [variableAmount, setVariableAmount] = useState(0);

  useEffect(() => {
    let regime = user.regimeOptedStatus === 1 ? "newRegime" : "oldRegime";
    setUserRegime(regime);
    const { financialYear, month } = checkFinancialYear();
    employeeReducer.askMool({
      financialYear,
      month,
      id: user._id,
      user,
      ctc,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, ctc]);

  const currentMonth = new Date().getMonth();

  useEffect(() => {
    document.title = 'Salary | Management';
  });

  useEffect(() => {
    let ctcVal = ctc.costToCompany[currentMonth].ctc
      ? parseInt(ctc.costToCompany[currentMonth].ctc)
      : 0;
    let va =
      ctc.costToCompany[currentMonth].isPercentage === null ||
        ctc.costToCompany[currentMonth].isPercentage
        ? (ctcVal *
          parseFloat(ctc.costToCompany[currentMonth].variablePercentage)) /
        100
        : parseFloat(ctc.costToCompany[currentMonth].variableAmount);
    let variableDeduction = organisationSettings.variableAboveCTC ? 0 : va;
    setCTCValue(ctcVal);
    setVariableAmount(va);
    setFixedAmount(ctcVal - variableDeduction);
  }, [ctc, currentMonth, organisationSettings]);

  const submit = () => {
    employeeReducer.savePayslip({ user, finalPayslip, selectedIndex });
  };

  const DataItem = ({ itemName, amount }) => {
    return (
      <div className="individual-structure" key={itemName}>
        <p className="break-label">{itemName}</p>
        <p className="break-value">{config.numformatter(amount)} INR</p>
      </div>
    );
  };


  return (
    <div className="site-layout">
      <HeaderComponent icon={activeSalary} name="Manage Salary" />
      <Content className="salary-container">
        <Row
          className="manage-salary"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col sm={24} md={10} className="graph-data">
            <h2>Salary Modification</h2>
            <Skeleton
              loading={finalPayslip.basic === 0}
              active
              paragraph={{ rows: 20 }}
            >
              <div className="salary-breakdown-info-container">
                <div className="ctc-data-container">
                  <h3 className="card-data-heading">CTC</h3>
                  <span className="currency-format">INR</span>
                  <span className="amount">{numformatter(ctcValue)}</span>
                </div>
                <div className="separator" />
                <div className="ctc-data-container">
                  <h3 className="card-data-heading">Fixed Component</h3>
                  <p className="salary-right-card-amount">
                    <span className="currency-format">INR</span>
                    <span className="amount">{numformatter(fixedAmount)}</span>
                  </p>
                </div>
                <div className="separator" />
                <div className="ctc-data-container">
                  <h3 className="card-data-heading">Variable Component</h3>
                  <p className="salary-right-card-amount">
                    <span className="currency-format">INR</span>
                    <span className="amount">
                      {config.numformatter(variableAmount)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="chart-container">
                <Chart
                  options={options}
                  series={[
                    {
                      name: "Salary Structure",
                      data: [
                        finalPayslip[userRegime].takeHome,
                        finalPayslip.employee_PF,
                        finalPayslip.proffTax +
                        finalPayslip[userRegime].incomeTax,
                      ],
                    },
                  ]}
                  type="bar"
                  height={400}
                />
              </div>
              <p className="info-container">
                Note : The savings are equivalent to an employee PF Contribution
              </p>
            </Skeleton>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14}>
            <section className="slider">
              <div className="slider-header">
                <h3>Modification scale</h3>
                {isChanged && (
                  <Button
                    size="large"
                    type="secondary"
                    onClick={submit}
                    className="payslip-button"
                  >
                    Save Payslip
                  </Button>
                )}
              </div>
              <div className="slider-container">
                <p>Takehome</p>
                <p>Savings</p>
              </div>
              <Slider
                disabled={
                  finalPayslip.basic === 0 ||
                  !organisationSettings.pfEnabled ||
                  moolData.graphData.length === 0 ||
                  (freezeInfo && freezeInfo.payrollFreeze) ||
                  isPercentageStructure
                }
                tooltipVisible={false}
                // tipFormatter={value => (<p>{Math.round((finalPayslip.employee_PF / finalPayslip.basic) * 100) > 100 ? 100 : Math.round((finalPayslip.employee_PF / finalPayslip.basic) * 100)} % of Basic</p>)}
                // disabled={finalPayslip.basic === 0 || (currentDate > organisationSettings.payrollFreezeDate || currentDate < 5)}
                value={organisationSettings.pfEnabled ? selectedIndex : 0}
                onChange={(val) => {
                  if (organisationSettings.pfEnabled)
                    employeeReducer.updateSlider(val);
                }}
                marks={marker}
                max={1000}
                min={0}
                handleStyle={{
                  height: "1rem",
                  width: "1rem",
                  border: "solod 1px #252525",
                  backgroundColor: "#252525",
                }}
              />
            </section>
            <section className="break-down">
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                className="padding-breakdown"
              >
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  className="break-down-section break-down-section-left "
                >
                  <Skeleton
                    loading={finalPayslip.basic === 0}
                    active
                    paragraph={{ rows: 12 }}
                  >
                    <h3>Earning</h3>
                    <DataItem itemName={"Basic"} amount={finalPayslip.basic} />

                    {finalPayslip.HRA !== 0 && (
                      <DataItem
                        itemName={"House Rent Allowance"}
                        amount={finalPayslip.HRA}
                      />
                    )}
                    {finalPayslip.DA !== 0 && (
                      <DataItem
                        itemName={"Dearness Allowance"}
                        amount={finalPayslip.DA}
                      />
                    )}
                    {finalPayslip.LTA !== 0 && (
                      <DataItem
                        itemName={"Leave Travel Allowance"}
                        amount={finalPayslip.LTA}
                      />
                    )}
                    {finalPayslip.specialAllowance !== 0 && (
                      <DataItem
                        itemName={"Special Allowances"}
                        amount={finalPayslip.specialAllowance}
                      />
                    )}
                    {Object.keys(finalPayslip.otherAllowances).map((val) => (
                      <DataItem
                        itemName={organisationSettings.allowances[val].label}
                        amount={finalPayslip.otherAllowances[val]}
                      />
                    ))}

                    {Object.keys(finalPayslip.perks).map((val) => (
                      <DataItem
                        itemName={"Motor Car Conveyance"}
                        amount={finalPayslip.perks[val]}
                      >
                      </DataItem>
                    ))}
                    {Object.keys(finalPayslip.customAllowances).map((val) => (
                      <DataItem
                        itemName={val}
                        amount={finalPayslip.customAllowances[val]}
                      />
                    ))}
                    {finalPayslip.reimbursements &&
                      Object.keys(finalPayslip.reimbursements).map((val) => (
                        <DataItem
                          itemName={val}
                          amount={finalPayslip.reimbursements[val]}
                        />
                      ))}

                    {Object.keys(finalPayslip.bonuses).map((val) => (
                      <DataItem
                        itemName={val}
                        amount={finalPayslip.bonuses[val]}
                      />
                    ))}
                    {finalPayslip.statutoryBonuse !== 0 &&
                      !isNaN(finalPayslip.statutoryBonuse) && (
                        <DataItem
                          itemName={"Statutory Bonus"}
                          amount={finalPayslip.statutoryBonuse}
                        />
                      )}
                    <div className="individual-structure">
                      <p className="break-label-earning">Earning</p>
                      <p className="break-value-earning">
                        {config.numformatter(finalPayslip.totalEarnings)} INR
                      </p>
                    </div>
                  </Skeleton>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  className="break-down-section"
                >
                  <Skeleton
                    loading={finalPayslip.basic === 0}
                    active
                    paragraph={{ rows: 12 }}
                  >
                    <h3>Deduction</h3>
                    {finalPayslip.employee_PF !== 0 && (
                      <DataItem
                        itemName={"Provident Fund"}
                        amount={finalPayslip.employer_PF}
                      />
                    )}
                    {finalPayslip.employee_PF !== 0 &&
                      finalPayslip.employee_PF > finalPayslip.employer_PF && (
                        <DataItem
                          itemName={"Voluntary PF"}
                          amount={
                            finalPayslip.employee_PF - finalPayslip.employer_PF
                          }
                        />
                      )}
                    {(user.regimeOptedStatus === 1
                      ? finalPayslip.newRegime.incomeTax !== 0
                      : finalPayslip.oldRegime.incomeTax !== 0) && (
                        <DataItem
                          itemName={"Income Tax"}
                          amount={
                            (user.regimeOptedStatus === 1
                              ? finalPayslip.newRegime.incomeTax
                              : finalPayslip.oldRegime.incomeTax) +
                            (isNaN(parseInt(finalPayslip.advanceTax))
                              ? 0
                              : finalPayslip.advanceTax)
                          }
                        />
                      )}
                    {finalPayslip.proffTax !== 0 && (
                      <DataItem
                        itemName={"Professional Tax"}
                        amount={finalPayslip.proffTax}
                      />
                    )}
                    {finalPayslip.monthClose &&
                      Object.keys(finalPayslip.monthClose).map((val) => (
                        <DataItem
                          itemName={val}
                          amount={finalPayslip.monthClose[val]}
                        />
                      ))}
                    {finalPayslip.FOOD !== 0 && (
                      <DataItem
                        itemName={"Food Allowances"}
                        amount={finalPayslip.FOOD}
                      />
                    )}
                    {finalPayslip.employee_LWF !== 0 &&
                      !isNaN(finalPayslip.employee_LWF) && (
                        <DataItem
                          itemName={"Labour Walfare Fund"}
                          amount={finalPayslip.employee_LWF}
                        />
                      )}

                    {finalPayslip.employee_ESI !== 0 && (
                      <DataItem
                        itemName={"ESIC"}
                        amount={finalPayslip.employee_ESI}
                      />
                    )}
                    {(user.regimeOptedStatus === 1
                      ? finalPayslip.newRegime.deductions !== 0
                      : finalPayslip.oldRegime.deductions !== 0) && (
                        <div className="individual-structure">
                          <p className="break-label-deduction">Deduction</p>
                          <p className="break-value-deduction">
                            {config.numformatter(
                              user.regimeOptedStatus === 1
                                ? finalPayslip.newRegime.deductions
                                : finalPayslip.oldRegime.deductions
                            )}{" "}
                            INR
                          </p>
                        </div>
                      )}
                  </Skeleton>
                </Col>
              </Row>
              <div className="individual-structure-takehome">
                <p className="break-label">Takehome</p>
                <p className="break-value">
                  {config.numformatter(finalPayslip.oldRegime.takeHome)} INR
                </p>
              </div>
            </section>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

let options = {
  chart: {
    height: 400,
    type: "bar",
  },
  plotOptions: {
    bar: {
      columnWidth: "45%",
      distributed: true,
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories: ["Inhand", "Savings", "Taxes"],
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    show: false,
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return config.numformatter(val) + " INR";
    },
    offsetY: -20,
    style: {
      fontSize: "12px",
      colors: ["#304758"],
    },
  },
};

SalaryManagement.propTypes = {
  user: PropTypes.object.isRequired,
  ctc: PropTypes.object.isRequired,
  hra: PropTypes.object.isRequired,
  deductions: PropTypes.array.isRequired,
  bonuses: PropTypes.array.isRequired,
  organisationSettings: PropTypes.object.isRequired,
};

const SalaryManagementContainer = connect(
  (state) => ({
    user: state.auth.user,
    ctc: state.employee.ctc,
    organisationSettings: state.employee.organisationSettings,
    finalPayslip: state.employee.finalPayslip,
    isChanged: state.employee.isChanged,
    selectedIndex: state.employee.selectedIndex,
    marker: state.employee.marker,
    moolData: state.employee.moolData,
    freezeInfo: state.employee.freezeInfo,
    isPercentageStructure: state.employee.isPercentageStructure,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    employeeReducer: employee.getActions(dispatch),
  })
)(SalaryManagement);

export default SalaryManagementContainer;
