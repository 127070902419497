import HeaderComponent from "../Common/Header/Header";
import { withRouter } from "react-router-dom";
import reportActive from "../../assets/icons/activeIcons/reports.svg";
import { connect } from "react-redux";
import { message, Modal, Progress, Spin, Tabs } from "antd";
import "./rebalance.less";
import Declaration from "./Declaration";
import Allowances from "./Allowances";
import organisation from "../../redux/modules/organisation";
import { useState, useEffect } from "react";
import { checkFinancialYear } from "../../utils/config";
import {
  sendTaxSettlementNotification,
  updateTaxSettlementData,
} from "../../apis/organisation";
import { NormalButton } from "../ComponentLibrary";
import { createLog } from "../../apis/user";
import DateComponent from "../InputLibrary/DateComponent/DateComponent";
import moment from "moment";
import TrialPage from "../Common/TrialComponent";
import incomeTaxSettlementBgImage from "../../assets/dummyPages/incomeTaxSettlementBgImage.png"

const { TabPane } = Tabs;

const Rebalance = ({
  user,
  isTrial,
  organisationReducer,
  taxSettlementData,
  employeeList,
}) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dueDate, setDueDate] = useState(new Date().toDateString());
  const [dueDateModal, setDueDateModal] = useState(false);
  const [notificationEmployeeList, setNotificationEmployeeList] = useState(
    false
  );

  const updateStructure = async () => {
    setLoading(true);
    let success = 0;
    const { financialYear } = checkFinancialYear();
    for (let employee of employeeList) {
      try {
        let postBody = {
          financialYear,
          id: employee.user._id,
        };
        await updateTaxSettlementData(postBody);
        success++;
        setProgress(success);
      } catch (e) { }
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Updated tax settlement`,
      category: 1,
    });
    organisationReducer.updateTaxSettlement({
      organisationId: user.organisationId,
      financialYear,
    });
    cancelDuedateModal();
    setLoading(false)
  };

  const notifyTaxSettlement = (empList) => {
    setNotificationEmployeeList([...empList]);
    setDueDateModal(true);
  };

  // Cancel notification modal
  const cancelDuedateModal = () => {
    setDueDate(moment());
    setNotificationEmployeeList([]);
    setDueDateModal(false);
    setLoading(false);
    setProgress(0);
  };

  // notify the users
  const notify = async () => {
    const { financialYear } = checkFinancialYear();
    let success = 0;

    if (!dueDate) {
      message.error("Please select one Due date");
      return;
    }
    setDueDateModal(false);
    setLoading(true);
    for (let emp of notificationEmployeeList) {
      if (emp.notified && emp.notified > 5) {
        continue;
      }
      let postBody = {
        financialYear,
        id: emp.user._id,
        dueDate,
      };
      await sendTaxSettlementNotification(postBody);
      success++;
      setProgress(success);
    }
    cancelDuedateModal();
  };
  useEffect(() => {
    document.title = 'Payroll | Income Tax Settlement';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Three months of works reduced to three clicks!"} bgImage={incomeTaxSettlementBgImage}
    /> :
      <div className="rebalance">
        <HeaderComponent
          show={false}
          showMoolCoins={user.role === "org"}
          icon={reportActive}
          name="Income Tax Settlement"
        />
        <div className="card-container">
          {loading && (
            <Progress
              percent={Math.round((progress / employeeList.length) * 100)}
              size="large"
              status="active"
            />
          )}
          <Modal
            centered
            closable={false}
            visible={dueDateModal}
            className="modal-container"
            footer={null}
            onCancel={cancelDuedateModal}
          >
            <div>
              <div className="m-top-8 ">
                <DateComponent
                  onChange={(e) => { setDueDate(new Date(e).toDateString()) }}
                  labelText={"Select Due Date"}
                  value={moment(dueDate)}
                  style={{ width: "100%" }}
                  size={"large"}
                />
              </div>
              <div className="m-top-8 text-align-right">
                <NormalButton
                  type={5}
                  buttonText={"Cancel"}
                  margin={[0, 12]}
                  onClick={cancelDuedateModal}
                />
                <NormalButton type={13} buttonText={"Notify"} onClick={notify} />
              </div>
            </div>
          </Modal>
          <Spin spinning={loading} size="large">
            <Tabs type="card">
              <TabPane tab="IT Declarations" key="1">
                <Declaration
                  updateStructure={updateStructure}
                  taxSettlementData={taxSettlementData}
                  notifyTaxSettlement={notifyTaxSettlement}
                  setLoading={setLoading}
                  setProgress={setProgress}
                  organisationId={user.organisationId}
                  organisationReducer={organisationReducer}
                />
              </TabPane>
              <TabPane tab="Allowances" key="2">
                <Allowances
                  updateStructure={updateStructure}
                  taxSettlementData={taxSettlementData}
                  notifyTaxSettlement={notifyTaxSettlement}
                  setLoading={setLoading}
                  setProgress={setProgress}
                  organisationId={user.organisationId}
                  organisationReducer={organisationReducer}
                />
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      </div>
  );
};

const RebalanceContainer = connect(
  (state) => ({
    user: state.auth.user,
    taxSettlementData: state.organisation.taxSettlementData,
    employeeList: state.organisation.employeeList,
    isTrial: state.auth.user.isTrial,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Rebalance);

export default withRouter(RebalanceContainer);
