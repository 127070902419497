import { Tabs } from "antd";
import React from 'react';
import { useHistory } from "react-router-dom";
import auth from "../../../redux/modules/auth";
import AuditComplianceReport from "./AuditComplianceReport";
import AuditValidationPage from './AuditValidation/AuditValidationPage';
import {
  FileSyncOutlined
} from "@ant-design/icons";
import { connect } from "react-redux";
import { categoryMappring } from "../../../constants/routes";
const { TabPane } = Tabs;


const ErrorValidatorPage = ({ currentRecord, user, authReducer }) => {
  const history = useHistory();
  let complianceHeading = "Compliances";
  if (!user.isTrial) {
    complianceHeading = `Compliances ( Already subscribed )`;
  }
  else if (currentRecord.status <= 2) {
    complianceHeading = `Compliances ( Generate your correct audit report )`
  }
  return (
    <div style={{ width: "100%" }}>
      <Tabs defaultActiveKey="0">
        <TabPane tab="Audit Report" key="0">
          <div><AuditValidationPage currentRecord={currentRecord} /></div>
        </TabPane>
        <TabPane tab={complianceHeading} key="1" disabled={currentRecord.status <= 2}  >
          {user.isTrial ? <div><AuditComplianceReport currentRecord={currentRecord} /></div> :
            <div className='m-top-50 flex justify-center align-center' style={{ marginTop: "40px", flexDirection: "column" }}>
              <h2>Try downloading compliance reports from reports section (for free)</h2>
              <div onClick={() => {
                authReducer.updateCurrentTab("ComplianceReport")
                authReducer.updateSelectedCategory(categoryMappring.org.ComplianceReport)
                history.push('/org/reports/compliance');
              }} className="flex-column justify-center align-center" style={{ cursor: "pointer" }}>
                <FileSyncOutlined style={{ fontSize: "4rem", margin: "3rem 3rem 1rem 3rem", color: "gray" }} />
                <h2>Redirect me there!</h2>
              </div>
            </div>
          }
        </TabPane>
      </Tabs>
    </div>
  )
}

const ErrorValidatorPageContainer = connect(
  (state) => ({
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
  })
)(ErrorValidatorPage);
export default ErrorValidatorPageContainer;