module.exports = {
  checkFinancialYear: () => {
    let currentDate = new Date();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();
    let date = currentDate.getDate();
    let financialYear = "";
    if (month < 3) {
      financialYear = `${year - 1}-${year}`;
    } else {
      financialYear = `${year}-${year + 1}`;
    }
    return { month, year, financialYear, date, currentDate: currentDate.toDateString() };
  },
  getUpcomingFinancialYear: () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    return `${year}-${year + 1}`;
  },
  getYearFromFY: (financialYear, month) => {
    const year = parseInt(month) < 3 ? financialYear.split('-')[1] : financialYear.split('-')[0]
    return year;
  },
  getFinancialYear: (date) => {
    let currentDate = new Date(date);
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();
    let financialYear = "";
    if (month < 3) {
      financialYear = `${year - 1}-${year}`;
    } else {
      financialYear = `${year}-${year + 1}`;
    }
    return { month, year, financialYear };
  },
  getMonthFrom: (fromDate) => {
    try {
      const date = new Date(fromDate);
      return date.getMonth() + 1;
    } catch (err) {
      return null;
    }
  },
  dateDestruct: (fromDate) => {
    try {
      const toDate = new Date(fromDate);
      let month = toDate.getMonth() + 1;
      let year = toDate.getFullYear();
      let day = toDate.getDate();
      let time = toDate.getTime();
      let date = toDate.toDateString();
      let hour = toDate.getHours();
      let minute = toDate.getMinutes();
      let seconds = toDate.getSeconds();
      return { date, time, day, month, year, hour, minute, seconds };
    } catch (err) {
      return null;
    }
  },

  timeDiffCalc: (datePast, dateFuture) => {
    let seconds =
      (new Date(dateFuture).getTime() - new Date(datePast).getTime()) / 1000;
    let hourDiff = seconds / 3600;
    let minuteDiff = (seconds / 3600) * 60;
    let dayDiff = seconds / 86400;
    let futureMonth = new Date(dateFuture).getMonth();
    let pastMonth = new Date(datePast).getMonth();
    let futureYear = new Date(dateFuture).getFullYear();
    let pastYear = new Date(datePast).getFullYear();

    if (seconds < 0) {
      dayDiff = -dayDiff;
    }
    // month and Year difference
    let yearDiff = futureYear - pastYear;
    let monthDiff = yearDiff * 12 + (futureMonth - pastMonth);
    return { dayDiff, hourDiff, minuteDiff, monthDiff, yearDiff };
  },
  getWeekList: (month, year) => {
    const daysInMonthList = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let totalDays = daysInMonthList[month];
    let dateDay = new Date(`${month + 1}/1/${year}`);
    let weekStart = 1;
    let weeks = [];
    let dayPointer = 1;
    for (dayPointer; dayPointer <= totalDays; dayPointer++) {
      dateDay = dateDay % 7;

      if (dateDay === 6 || dayPointer === totalDays) {
        weeks.push({
          weekStart,
          weekEnd: dayPointer,
        });
      }
      if (dateDay === 0) {
        weekStart = dayPointer;
      }
      dateDay++;
    }
    return weeks;
  },
  getWeekdaysInMonth: (month, year) => {
    var days = daysInMonth(month, year);
    var weekdays = 0;
    for (var i = 0; i < days; i++) {
      if (isWeekday(year, month, i + 1)) weekdays++;
    }
    return weekdays;
  },
  countWeekendDays(startDate, endDate) {
    return getWeekEnd(startDate, endDate)
  },
  countWeekdays(startDate, endDate) {
    const { numberOfDays, totalWeekends } = getWeekEnd(startDate, endDate)
    return numberOfDays - totalWeekends;
  },
  listOfWeekDays(startDate, endDate) {
    const daysInMonthList = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let start = new Date(startDate);
    let end = new Date(endDate);
    let dateCounter = start.getDate();
    let startMonth = start.getMonth();
    const listOfDays = [];
    let monthDiff = end.getMonth() - startMonth
    if (monthDiff === 0) {
      while (dateCounter <= end.getDate()) {
        if (isWeekday(start.getFullYear(), startMonth, dateCounter)) {
          let dateData = new Date();
          dateData.setDate(dateCounter);
          dateData.setMonth(startMonth);
          dateData.setFullYear(start.getFullYear());
          listOfDays.push(dateData.toDateString());
        }
        dateCounter++;
      }
    } else {
      for (let currMonth = startMonth; currMonth <= startMonth + monthDiff; currMonth++) {
        let daysToGo = daysInMonthList[currMonth];
        if (isLeapYear(start.getFullYear()) && currMonth === 1) {
          daysToGo += 1;
        }
        if (currMonth === startMonth + monthDiff) {
          daysToGo = end.getDate();
        }

        while (dateCounter <= daysToGo) {
          if (isWeekday(start.getFullYear(), currMonth, dateCounter)) {
            if (dateCounter === 29 && currMonth === 1) {
              listOfDays.push(new Date(`2/29/${start.getFullYear()}`).toDateString());
            } else {
              let dateData = new Date();
              dateData.setDate(dateCounter);
              dateData.setMonth(currMonth);
              dateData.setFullYear(start.getFullYear());
              listOfDays.push(dateData.toDateString());
            }
          }
          dateCounter++;
        }
        dateCounter = 1;
      }
    }
    return listOfDays;
  },
  getNextDate(date) {
    return new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toDateString()
  },
  getWorkingHourDates(dayValue, month) {
    const dateIn = new Date(new Date(new Date(new Date(new Date().setDate(dayValue)).setMonth(month)).setHours(9)).setMinutes(0))
    const dateOut = new Date(new Date(dateIn).setHours(new Date(dateIn).getHours() + 9))
    return [dateIn, dateOut]
  }
};


function isWeekday(year, month, day) {
  let date = new Date(year, month, day).getDay();
  return date !== 0 && date !== 6;
}

function daysInMonth(iMonth, iYear) {
  return 32 - new Date(iYear, iMonth, 32).getDate();
}

function getWeekEnd(startDate, endDate) {
  const fromDate = new Date(startDate);
  const toDate = new Date(endDate);
  const numberOfDays = 1 + Math.round((toDate.getTime() - fromDate.getTime()) / (24 * 3600 * 1000));
  const numberOfSatDays = Math.floor((fromDate.getDay() + numberOfDays) / 7);
  const totalWeekends = 2 * numberOfSatDays + (fromDate.getDay() === 0) - (toDate.getDay() === 6);
  return { numberOfDays, totalWeekends };

}

const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
