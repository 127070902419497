import React, { useState, useEffect } from "react";
import { HeaderComponent } from "../Common";
import { getEmployeeDetails, getEmployeeOnBoarding } from "../../apis/employee";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/money.json";
import { Modal, notification, Spin, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import PersonalInformation from "./Components/personalInformation";
import PreviousEmployment from "../PreviousEmployment/PreviousEmployment";
import PayslipAndForms from "./Components/payslipAndForms";
import Declaration from "./Components/declaration";
import Settlement from "./Components/settlement";
import SalaryDetails from "./Components/salaryDetails";
import config, { checkFinancialYear, getFinancialYearFromDate } from "../../utils/config";
import Documents from "./Components/documents";
import Resignation from "../EmployeProfile/Components/TabContents/resignation";
import TaxProjection from "../PreviousEmployment/TaxProjection";
import activeEmployees from "../../assets/icons/activeIcons/eac.svg";
import backBtn from "../../assets/icons/backBtn.png";
import { NormalButton } from "../ComponentLibrary";
import { changePassword, updateUsername } from "../../apis/auth";
import {getUserFromId} from "../../apis/organisation"
import InputVerifyComponent from "../InputLibrary/InputVerifyComponent/InputVerifyComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";

const { TabPane } = Tabs;
const resetHeading = ["", "Reset Employee Password", "Reset Employee Username"]
const EmployeeDetails = (props) => {
  let history = useHistory();
  const pathArr = history.location.pathname.split("/");
  const employeeId = pathArr[pathArr.length-1];
  const [isCurrentFinancialYearJoine,setIsCurrentFinancialYearJoinee]= useState(false);

  useEffect(()=>{
    getUserFromId(employeeId).then(res=>{
      const { dateOfJoining } = res.data.currentOrganisationMeta;
      let joiningDate = new Date(dateOfJoining);
      let employeeJoiningFinancialYear = getFinancialYearFromDate(
        joiningDate
      );

      let { financialYear } = checkFinancialYear();
      if (employeeJoiningFinancialYear.financialYear===financialYear){
        setIsCurrentFinancialYearJoinee(true)
      }else{
        setIsCurrentFinancialYearJoinee(false)
      }
    })
  },[employeeId])

  const [isLoading, setIsLoading] = useState(false);
  const [resetType, setResetType] = useState(0);
  const [submitClicked, setSubmitClicked] = useState("");
  const [checkUserExist, setCheckUserExist] = useState(false);

  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [user, setUser] = useState({
    _id: "",
    displayName: "",
    designation: "",
    primaryEmail: "",
    phone: "",
    PAN: "",
    mappedTo: {
      name: "",
      _id: "",
      currentOrganisationMeta: {
        employeeId: "",
      },
    },
    currentOrganisationMeta: {
      employeeId: "",
    },
  });
  const [username, setUsername] = useState("");
  const [onboarding, setOnboarding] = useState(null);
  const [familyDetail, setFamilyDetail] = useState({
    phoneNumber: "",
    gender: "",
    spouseType: "",
    dateOfBirth: "",
    martialStatus: "",
    spouseStatus: "",
    parentType: "",
    parentAge: "",
    disabled: "",
  });
  const [address, setAddress] = useState({
    addressLine1: "",
    pincode: "",
    city: "",
    addressLine2: "",
    landmark: "",
    state: "",
  });
  const [bankInformation, setBankInformation] = useState({
    accountNumber: "",
    ifsc: "",
    bankName: "",
    branchAddress: "",
  });
  const [contactInfo, setContactInfo] = useState({
    personalPhone: "",
    linkedIn: "",
    skypeId: "",
    slackUser: "",
    personalEmail: "",
  });
  const [credentials, setCredentials] = useState({
    aadhaar: "",
    passportNumber: "",
    SSN: "",
    drivingLicenseNumber: "",
  });
  const [statusName, setStatusName] = useState({
    statusName: "",
  });
  const [department, setDepartment] = useState({
    departmentName: "",
  });
  const [designation, setDesignation] = useState({
    designationName: "",
  });
  const [payslips, setPayslips] = useState([]);
  const [location, setLocation] = useState({});
  const [ctc, setCtc] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [settlementData, setSettlementData] = useState({});

  const setEmployeeData = (data) => {
    const [employeeData, empOnboarding] = data;
    setLocation(employeeData.data.data.location);
    setDesignation(employeeData.data.data.designation);
    setDepartment(employeeData.data.data.department);
    setSettlementData(employeeData.data.data.settlement);
    setStatusName(employeeData.data.data.status);
    setCtc(employeeData.data.data.ctc);
    setUserDetails({ ...employeeData.data.data });
    setUser({ ...employeeData.data.data.user });
    setFamilyDetail({ ...employeeData.data.data.user.familyDetails });
    setAddress({ ...employeeData.data.data.user.address });
    setBankInformation({ ...employeeData.data.data.user.bankInfo });
    setContactInfo({ ...employeeData.data.data.user.contactInfo });
    setCredentials({ ...employeeData.data.data.user.credentials });
    setOnboarding({ ...empOnboarding.data });
    let tempPayslips = employeeData.data.data.payslip.sort(
      (a, b) => a.month - b.month
    );
    setPayslips([...tempPayslips]);
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  };
  
  useEffect(
    () => {
      setLoading(true);
      const controller = new AbortController();
      getEmployeeData(id, { signal: controller.signal });
      return () => {
        controller.abort();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getEmployeeData = async (_id, cancelToken) => {
    const { month, financialYear } = config.checkFinancialYear();

    let employeeData = await getEmployeeDetails(
      `${_id}/${month}/${financialYear}`,
      cancelToken
    );
    let empOnboarding = await getEmployeeOnBoarding(
      employeeData.data.data.user.organisationId,
      _id,
      cancelToken
    );
    setEmployeeData([employeeData, empOnboarding]);
  };

  const animationString = JSON.stringify(animationData);
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: JSON.parse(animationString),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  const showRestModal = (type) => {
    setIsResetModalVisible(true);
    setResetType(type);
    if (type === 2) {
      setUsername(user.username)
    }
  };

  const handleResetCancel = () => {
    setResetPassword({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setResetType(0);
    setUsername("");
    setIsResetModalVisible(false);
  };

  const handleResetPassword = (e) => {
    setResetPassword({
      ...resetPassword,
      [e.target.name]: e.target.value,
    });
  };

  const onResetPassSubmit = async () => {
    if (
      !resetPassword.oldPassword ||
      !resetPassword.newPassword ||
      !resetPassword.confirmPassword
    ) {
      notification.error({ message: "Field is required" });
      return;
    }
    if (resetPassword.oldPassword === resetPassword.newPassword) {
      notification.error({
        message: "Your new Password cannot be the same as your Old Password",
      });
      return;
    }
    if (resetPassword.newPassword !== resetPassword.confirmPassword) {
      notification.error({
        message: "New Password and Confirm Password are not same",
      });
      return;
    }
    if (
      resetPassword.oldPassword !== resetPassword.newPassword &&
      resetPassword.newPassword === resetPassword.confirmPassword
    ) {
      setIsLoading(true);
      const response = await changePassword({
        username: user.username,
        oldPassword: resetPassword.oldPassword,
        newPassword: resetPassword.newPassword,
      });
      setIsLoading(false);
      if (response.data.success) {
        notification.success({ message: "Password Changed Successfully!" });
        handleResetCancel();
      } else {
        if (response.data.message === "WRONG_CREDENTIALS") {
          notification.error({
            message: "Incorrect Old password!, Please try again!",
          });
        } else {
          notification.error({
            message: "Can not change your password, Please try again!",
          });
        }
      }
    }
  };

  const handleCancel = () => {
    setIsLoading(false);
    setIsResetModalVisible(false);
  };

  const updateUsernameDetails = async () => {
    if (username === "" || checkUserExist) {
      setSubmitClicked("username");
      return;
    }
    setIsLoading(true)
    let res = await updateUsername({
      id: user._id,
      username,
    });
    if (res.data.success) {
      localStorage.setItem("mool_auth", res.data.data.auth);
      notification.success({ message: "Successfully updated the username" })
    } else {
      notification.error({ message: "Sorry unable to set the username" });
    }
    handleCancel()
  }


  return (
    <div className='site-layout'>
      <Modal
        visible={isResetModalVisible}
        onCancel={handleResetCancel}
        footer={null}
        centered
        width={400}
      >
        <Spin spinning={isLoading} size='large'>
          <h1 className='font-weight-global-700 font-size-global-24 modal-margin-left-global-heading'>
            {resetHeading[resetType]}
          </h1>
          {resetType === 1 && (
            <div>
              <div className='w-full position-relative modal-alignment-center-global modal-margin-top-add-global-2'>
                <InputFieldComponent
                  className='input-register'
                  type={"password"}
                  labeltext={"Old Password"}
                  name='oldPassword'
                  onChange={handleResetPassword}
                  value={resetPassword.oldPassword}
                />
              </div>
              <div className='w-full position-relative modal-alignment-center-global'>
                <InputFieldComponent
                  name='newPassword'
                  labeltext={"New Password"}
                  type={"password"}
                  className='input-register'
                  onChange={handleResetPassword}
                  value={resetPassword.newPassword}
                />
              </div>
              <div className='w-full modal-alignment-center-global'>
                <InputFieldComponent
                  name='confirmPassword'
                  labeltext={"Confirm Password"}
                  type={"password"}
                  className='input-register'
                  onChange={handleResetPassword}
                  value={resetPassword.confirmPassword}
                />
              </div>
              <div className='modal-margin-top-add-global-3'>
                <NormalButton
                  buttonText='Change Password'
                  onClick={onResetPassSubmit}
                  type={13}
                  margin={[8, 0]}
                  block={true}
                />
              </div>
            </div>
          )}
          {resetType === 2 && (
            <div>
              <div
                className='w-full position-relative'
                style={{ marginTop: 10 }}
              >
                <InputVerifyComponent
                  margin={["30", "0", "20", "0"]}
                  width={"100%"}
                  maxWidth={"35rem"}
                  setCheckUserExist={setCheckUserExist}
                  required={true}
                  uniqueKey={"username"}
                  labeltext={"User Name"}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  value={username}
                  setSubmitClicked={setSubmitClicked}
                  submitClicked={submitClicked}
                />
              </div>
              <div>
                <NormalButton
                  buttonText='Change Username'
                  onClick={updateUsernameDetails}
                  type={13}
                  margin={[8, 0]}
                  block={true}
                />
              </div>
            </div>
          )}
        </Spin>
      </Modal>
      <HeaderComponent
        show={false}
        showMoolCoins={true}
        icon={activeEmployees}
        name='Employee'
      />
      <Content className='app-main-content-container emoloyee-list-container'>
        <Content className='header-container'>
          <span className='font-weight-400 font-size-global-24 font-weight-global-700'>
            {!loading && (
              <img
                src={backBtn}
                alt='back button'
                style={{ marginRight: "1rem", maxWidth: "2rem" }}
                onClick={() => {
                  history.goBack();
                }}
              />
            )}
            {user.displayName && !loading ? `${user.displayName}` : ""}
            {user._id && !loading ? (
              <span
                style={{ paddingRight: "0.5em" }}
                className='userIDLabel font-size-global-14'
              >
                {" "}
                Employee ID : {user.currentOrganisationMeta.employeeId}
              </span>
            ) : (
              ""
            )}
          </span>
          {!loading && (
            <div>
              <NormalButton
                margin={[0, 49]}
                buttonText='Reset Password'
                type={13}
                onClick={() => showRestModal(1)}
              />
            </div>
          )}
        </Content>
        <div>
          {loading ? (
            <Lottie options={animationOptions} height={300} width={300} />
          ) : (
            <Tabs defaultActiveKey='1'>
              <TabPane
                tab={
                  <div className='tabs'>
                    <span className='tab-name'>Overview</span>
                  </div>
                }
                key='1'
              >
                <PersonalInformation
                  user={user}
                  orgInfo={props.user}
                  familyDetail={familyDetail}
                  address={address}
                  bankInformation={bankInformation}
                  contactInfo={contactInfo}
                  credentials={credentials}
                  statusName={statusName}
                  department={department}
                  location={location}
                  designation={designation}
                  getEmployeeData={getEmployeeData}
                  onboarding={onboarding}
                />
              </TabPane>
              <TabPane
                tab={
                  <div className='tabs'>
                    <span className='tab-name'>Salary Details</span>
                  </div>
                }
                key='2'
              >
                <SalaryDetails
                  user={user}
                  ctc={ctc}
                  getEmployeeData={getEmployeeData}
                  payslips={payslips}
                  orgInfo={userDetails.organisation}
                />
              </TabPane>
              <TabPane
                tab={
                  <div className='tabs'>
                    <span className='tab-name'>Payslips & Forms</span>
                  </div>
                }
                key='3'
              >
                <PayslipAndForms
                  ctc={userDetails.ctc}
                  designation={designation}
                  payslips={payslips}
                  user={user}
                  orgInfo={userDetails.organisation}
                />
              </TabPane>
              <TabPane
                tab={
                  <div className='tabs'>
                    <span className='tab-name'>Tax Projections</span>
                  </div>
                }
                key='4'
              >
                <div className='paper-wrapper'>
                  <TaxProjection
                    user={user}
                    declaration={userDetails.declaration}
                    payslips={userDetails.payslip}
                    the12BBApproved={true}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={
                  <div className='tabs'>
                    <span className='tab-name'>Proofs</span>
                  </div>
                }
                key='5'
              >
                <Declaration user={user} orgInfo={userDetails.organisation} />
              </TabPane>
              <TabPane
                tab={
                  <div className='tabs'>
                    <span className='tab-name'>Documents</span>
                  </div>
                }
                key='6'
              >
                <Documents id={id} />
              </TabPane>
              {isCurrentFinancialYearJoine ? (
                <TabPane
                  tab={
                    <div className='tabs'>
                      <span className='tab-name'>Previous Employments</span>
                    </div>
                  }
                  key='7'
                >
                  <PreviousEmployment user={user} />
                </TabPane>
              ) : null}

              {settlementData && Object.keys(settlementData).length > 0 && (
                <TabPane
                  tab={
                    <div className='tabs'>
                      <span className='tab-name'> Settlements</span>
                    </div>
                  }
                  key='8'
                >
                  <div className='personal-info-container'>
                    <Settlement
                      settlementData={settlementData}
                      user={user}
                      id={id}
                    />
                  </div>
                </TabPane>
              )}
              {(!settlementData || Object.keys(settlementData).length <= 0) && (
                <TabPane
                  tab={
                    <div className='tabs'>
                      <span className='tab-name'>Initiate Separations</span>
                    </div>
                  }
                  key='8'
                >
                  <div className='personal-info-container'>
                    <Resignation
                      user={user}
                      separations={settlementData}
                      isEmployerPage={false}
                      updateFunction={getEmployeeData}
                    />
                  </div>
                </TabPane>
              )}
            </Tabs>
          )}
        </div>
      </Content>
      {/* <div className="employee-header-container">
        <Link to={`/org/employees`}><p className="employee-arrow-outlined"><ArrowLeftOutlined /></p></Link>
        <p className="employee-name">{user.displayName}</p>
        <p className="employee-id">{user.currentOrganisationMeta.employeeId}</p>
      </div> */}
    </div>
  );
};

const EmployeeDetailsContainer = connect((state) => ({
  user: state.auth.user,
  employeeList: state.organisation.employeeList,
}))(EmployeeDetails);

export default EmployeeDetailsContainer;