import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Modal, notification, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getOrgReim, updateReimbursementState, verifyReimbursement
} from "../../apis/organisation";
import { createLog } from "../../apis/user";
import {
  verifyError,
  verifyNo,
  verifyYes
} from "../../assets/common/verifiedLogos";
import employeeLogo from "../../assets/employeeProfile/EmployeeLogo.svg";
import activeReimbursement from "../../assets/icons/activeIcons/reim.svg";
import organisation from "../../redux/modules/organisation";
import config from "../../utils/config";
import { HeaderComponent } from "../Common";
import { FileViewer, NormalButton, FileLogos } from "../ComponentLibrary";
import TextAreaComponent from "../InputLibrary/TextAreaComponent/TextAreaComponent";
import { getDocumentURL } from "../../apis/user";
import packageJson from "../../../package.json";
// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Plugins
import {
  defaultLayoutPlugin,
  // ToolbarProps,
  // ToolbarSlot,
} from "@react-pdf-viewer/default-layout";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];




const OrganisationReimbursement = ({ organisationReducer, user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [docType, setDocType] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);
  const [reimbursementsData, setReimbursementData] = useState([])
  const [reportDate, setReportDate] = useState(moment());
  const [viewFile, setviewFile] = useState(false);
  const [viewFileDocumentProps, setviewFileDocumentProps] = useState({});
  const [
    viewFileDocumentVerifyProps,
    setviewFileDocumentVerifyProps,
  ] = useState({});
  const [isReasonModalVisible, setisReasonModalVisible] = useState(false);
  const [reasonForRejection, setreasonForRejection] = useState("");
  const [isDeclineModalVisible, setIsDeclineModalVisble] = useState(false);
  const [currectDeclineModal, setcurrentDeclineModal] = useState(null);
  const [rejectionReason, setrejectionReason] = useState(null);
  const [isAcceptModalVisible, setIsAcceptModalVisible] = useState(false);
  const [currentAcceptReimbursement, setcurrentAcceptReimbursement] = useState(
    null
  );
  const [month, setMonth] = useState(Number(moment().format("M")) - 1);
  const [financialYear, setFinancialYear] = useState(config.checkFinancialYear().financialYear);
  useEffect(() => {
    document.title = 'Payroll | Reimbursements';
  });
  useEffect(() => {
    organisationReducer.orgReimbursement({ organisationId: user.organisationId, userRole: user.role, PAN: user.PAN });
    getOrgReim(`${user.organisationId}/${month}/${financialYear}`)
      .then((res) => {
        setReimbursementData(res.data)
      })
  }, [month, isRefresh, financialYear, organisationReducer, user.organisationId, user.role, user.PAN]);

  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  const handelFileVerify = async (propsForVerifyCallback) => {
    let tempObejct = {
      index: propsForVerifyCallback.fileIndexNumber,
      reimbursementId: propsForVerifyCallback.reimbursementsId,
    };
    let response = await verifyReimbursement(tempObejct);
    if (response.data.success === true) {
      if (currentAcceptReimbursement !== null) {
        let tempDocumets = currentAcceptReimbursement.documents;
        tempDocumets[propsForVerifyCallback.fileIndexNumber].verified = true;
        setcurrentAcceptReimbursement({
          ...currentAcceptReimbursement,
          documents: tempDocumets,
        });
      }
      organisationReducer.orgReimbursement({ organisationId: user.organisationId, userRole: user.role, PAN: user.PAN });
      setviewFile(false);
      notification.success({ message: "Document has been verified" });
    } else {
      notification.error({
        message: "Failed to verify the document ,please try again",
      });
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setviewFile(false);
    setDocType("");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setviewFile(false);
    setDocType("");
  };
  const imageCloseHandler = () => {
    setviewFile(false);
    setDocType("");
  }
  const getDocumentURLFromKey = async (key) => {
    let response = await getDocumentURL({ Key: key });
    setDocType("pdf")
    setUrl(response.data);
    showModal();
  }
  const handelViewDocument = (fileProps, index, reimbursementId) => {
    setviewFile(true);
    let tempFileProps = {
      documentKey: fileProps.key,
      documentType: fileProps.key.split(".").pop(),
      documentName: fileProps.key.split("reimbursement-").pop(),
    };
    let tempViewFileDocumentVerifyProps = {
      showVerificationStatus: true,
      userCanVerify: true,
      fileIsVerified: fileProps.verified,
      verifyCallback: handelFileVerify,
      propsForVerifyCallback: {
        fileIndexNumber: index,
        reimbursementsId: reimbursementId,
      },
    };
    setviewFileDocumentProps(tempFileProps);
    setviewFileDocumentVerifyProps(tempViewFileDocumentVerifyProps);
    if (tempFileProps.documentType === "pdf") {
      (async () => {
        await getDocumentURLFromKey(tempFileProps.documentKey);
      })();
    } else {
      setDocType("image");
    }

  };

  const showReasonModal = (data) => {
    setisReasonModalVisible(true);
    setreasonForRejection(data.statusMessage);
  };

  const closeReasonModal = () => {
    setisReasonModalVisible(false);
    setreasonForRejection("");
  };

  const handleAcceptModalOpen = (data) => {
    setcurrentAcceptReimbursement(data);
    setIsAcceptModalVisible(true);
  };

  const handleAcceptModalCancel = () => {
    setcurrentAcceptReimbursement(null);
    setIsAcceptModalVisible(false);
  };

  const handelReimbursementAcceptStateChange = async () => {
    let documents = currentAcceptReimbursement.documents;
    let allDocumentsVerified = true;
    documents.forEach((doc) => {
      if (doc.verified !== true) {
        allDocumentsVerified = false;
      }
    });
    if (!allDocumentsVerified) {
      notification.error({
        message:
          "Reimbursement can only be accepted after all the documents are verified.",
      });
      return;
    }
    let tempUpdateStateData = {
      state: 1,
      statusMessage: "Reimbursement request has been accepted",
      organisationId: currentAcceptReimbursement.organisationId,
      reimbursementId: currentAcceptReimbursement.reimbursementId,
    };
    let response = await updateReimbursementState(tempUpdateStateData);
    if (response.data.success === true) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organiation user updated reimbursement state`,
        category: 1,
      });
      organisationReducer.orgReimbursement({ organisationId: user.organisationId, userRole: user.role, PAN: user.PAN });
      getOrgReim(`${user.organisationId}/${month}/${financialYear}`)
        .then((res) => {
          setReimbursementData(res.data)
        })
      setIsAcceptModalVisible(false);
      notification.success({ message: "Document has been Accepted" });
      return;
    }
    notification.error({
      message: "Failed to accept reimbursement, please try again.",
    });
  };  

  const handelReimbursementDeclineStateChange = async () => {
    if (rejectionReason === null) {
      notification.error({ message: "Please enter the rejection reason" });
      return;
    }
    let tempUpdateStateData = {
      state: 2,
      statusMessage: rejectionReason,
      organisationId: currectDeclineModal.organisationId,
      reimbursementId: currectDeclineModal.reimbursementId,
    };
    let response = await updateReimbursementState(tempUpdateStateData);
    if (response.data.success === true) {
      organisationReducer.orgReimbursement({ organisationId: user.organisationId, userRole: user.role, PAN: user.PAN });
      getOrgReim(`${user.organisationId}/${month}/${financialYear}`)
        .then((res) => {
          setReimbursementData(res.data)
        })
      setIsDeclineModalVisble(false);
      setrejectionReason("");
      notification.success({ message: "Document has been rejected" });
      return;
    }
    notification.error({ message: "Failed to reject, please try again." });
  };

  const showDeclineModal = (data) => {
    setcurrentDeclineModal(data);
    setIsDeclineModalVisble(true);
  };

  const handleDeclineModalCancel = () => {
    setIsDeclineModalVisble(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["user"],
      width: "13%",
      render: (data) => {
        return (<div className="flex align-center">
          <img alt="img" className="rounded-image-1" src={data.image ? data.image : employeeLogo} />
          <p className="app-label-3" style={{ marginLeft: 10 }}>{data.displayName}</p>
        </div>)
      }
    },
    {
      title: "Date",
      dataIndex: "reimbursementBillDate",
      key: "reimbursementBillDate",
      width: "13%",
      render: reimbursementBillDate => (
        moment(reimbursementBillDate).format('ll')
      )
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
      render: (text) => <p>{config.reimbursementTypes[text]}</p>,
      width: "13%",
    },
    {
      title: "Reason",
      dataIndex: "details",
      key: "details",
      ellipsis: true,
      width: "13%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      ellipsis: true,
      width: "13%",
    },
    {
      title: "Attachment",
      dataIndex: "documents",
      key: "documents",
      ellipsis: true,
      width: "20%",
      render: (documents, reim) =>
        documents.map((item, i) => {
          return (
            <NormalButton
              onClick={() =>
                handelViewDocument(item, i, reim.reimbursementId)
              }
              type={7}
              heightWidth={[40, 40]}
              padding={[0, 0]}
              margin={[0, 8]}
              buttonText={`#${i + 1}`}
            />
          );
        })

    },
    {
      title: "",
      dataIndex: "state",
      key: "state",
      ellipsis: true,
      width: "20%",
      render: (state, data) => (
        <div>
          {state === 0 && (
            <>
              <NormalButton
                type={9}
                buttonText="Reject"
                onClick={() => showDeclineModal(data)}
                margin={[0, 8]}
              />
              <NormalButton
                type={13}
                buttonText="Accept"
                margin={[0, 8]}
                onClick={() => handleAcceptModalOpen(data)}
              />
            </>
          )} {state === 1 && (
            <div className="status-conatiner">
              <img alt="" src={verifyYes} className="status-logo"></img>Accepted
            </div>
          )} {!(state === 1 || state === 0) && (
            <div className="status-conatiner">
              <div className="status-conatiner">
                <img alt="" src={verifyError} className="status-logo"></img>
                Rejected
              </div>
              <NormalButton
                type={5}
                buttonText="Reason?"
                margin={[0, 24]}
                onClick={() => showReasonModal(data)}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const handelDateChange = (date) => {
    let tempMonth = Number(moment(date).format("M"));
    let tempYear = Number(moment(date).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    setMonth(tempMonth - 1);
    setFinancialYear(tempFinancialYear);
    setReportDate(date);
    getOrgReim(`${user.organisationId}/${tempMonth - 1}/${tempFinancialYear}`)
      .then((res) => {
        setReimbursementData(res.data)
      })
  };

  const renderToolbar = (Toolbar) =>
    React.createElement(Toolbar, null, (slots) => {
      const {
        CurrentPageInput,
        Download,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        // Print,
        // ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots;
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <ZoomOut />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <Zoom />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <ZoomIn />
          </div>
          <div
            style={{
              padding: "0px 2px",
              marginLeft: "auto",
            }}
          >
            <GoToPreviousPage />
          </div>
          <div
            style={{
              padding: "0px 2px",
              width: "4rem",
            }}
          >
            <CurrentPageInput />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            / <NumberOfPages />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <GoToNextPage />
          </div>
          <div
            style={{
              padding: "0px 2px",
              marginLeft: "auto",
            }}
          >
            <EnterFullScreen />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <Download />
          </div>
        </div>
      );
    });

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });

  return (
    <>
      {docType === "pdf" && <Modal
        title={
          <>
            <FileLogos type={1} /> {viewFileDocumentProps.documentName}
          </>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {
            viewFileDocumentVerifyProps.showVerificationStatus
            && (viewFileDocumentVerifyProps.userCanVerify
              ? viewFileDocumentVerifyProps.fileIsVerified
                ? <div className="verified-text-container"><img alt="" src={verifyYes} className="verified-logo-img"></img>Verified</div>
                : <div >
                  <NormalButton
                    buttonText="Verify The Document"
                    type={1}
                    onClick={() => viewFileDocumentVerifyProps.verifyCallback(viewFileDocumentVerifyProps.propsForVerifyCallback)}
                  />
                </div>
              : viewFileDocumentVerifyProps.fileIsVerified
                ? <div className="verified-text-container"><img alt="" src={verifyYes} className="verified-logo-img"></img>Verified</div>
                : <div className="verified-text-container"><img alt="" src={verifyNo} className="verified-logo-img"></img>Pending</div>)

          }
        </div>
        <div>
          {url ? (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={url}
                plugins={[defaultLayoutPluginInstance]}
                renderError={console.log}
              />
            </Worker>
          ) : (
            "Document URL not found"
          )}
        </div>
      </Modal>}
      {docType === "image" && < FileViewer
        visible={viewFile}
        onclose={imageCloseHandler}
        fileProps={viewFileDocumentProps}
        verifyProps={viewFileDocumentVerifyProps}
      />}

      <div className="site-layout">
        <HeaderComponent show={false} showMoolCoins={user.role === "org"} icon={activeReimbursement} name="Reimbursements" />
        <div className="app-main-content-container reimbursement-container">
          <div className="flex justify-between align-cener">
            <h1 className="font-size-global-24 font-weight-global-700">Requests</h1>            <div className="flex align-center">
              <Button
                onClick={startRefresh}
                className={`button-lib button-type-13 p-10`}
                style={{ height: "2.5rem" }}
              >
                <div className="flex justify-between align-center">
                  Refresh
                </div>
              </Button>
              <div className="flex align-center">
                <p className="font-size-16 font-weight-500" style={{ color: "#505057" }}>Financial Year</p>
                <DatePicker
                  format={"YYYY - MM"}
                  className="app-date-picker month-picker"
                  onChange={handelDateChange}
                  picker={"month"}
                  value={reportDate}
                  style={{ padding: 0, margin: 0, marginLeft: "10px" }}
                />
              </div>
            </div>
          </div>
          <Table
            pagination={reimbursementsData.length < 8 ? false : { pageSize: 8 }}
            className="reimbursement-table"
            columns={columns}
            dataSource={reimbursementsData.reverse()}
            scroll={{ x: 1100 }}
          />
        </div>
      </div>

      <Modal
        className="rejection-modal"
        visible={isDeclineModalVisible}
        onCancel={handleDeclineModalCancel}
        centered
        closable={false}
        footer={null}
      >
        <h4 className="rejection-modal-header">
          <InfoCircleOutlined style={{ color: "red", marginRight: "8px" }} /> {"Reject this request?"}
        </h4>
        <p>Reason For Rejection</p>
        <TextAreaComponent
          className="rejection-text-box"
          placeholder="Reason for rejection"
          rows={4}
          onChange={(e) => setrejectionReason(e.target.value)}
          value={rejectionReason}
        ></TextAreaComponent>
        <h4>Once you reject the request the employee won't be able to do modification in this current request.</h4>
        <div className="rejection-button">
          <NormalButton
            type={5}
            buttonText={"No,Cancel"}
            fontSize={"14px"}
            onClick={handleDeclineModalCancel}
          ></NormalButton>
          <NormalButton
            type={15}
            buttonText={"Yes,Continue"}
            fontSize={"14px"}
            onClick={handelReimbursementDeclineStateChange}
          ></NormalButton>
        </div>
      </Modal>

      <Modal
        className="rejection-modal"
        visible={isReasonModalVisible}
        onCancel={closeReasonModal}
        centered
        footer={null}
        closable={false}
      >
        <h3 className="rejection-modal-header">Reason for rejection</h3>
        <div className="rejection-text-box">{reasonForRejection}</div>
        <div className="rejection-button">
          <NormalButton
            type={5}
            buttonText={"Close"}
            onClick={closeReasonModal}
          ></NormalButton>
        </div>
      </Modal>

      <Modal
        className="accept-modal"
        visible={isAcceptModalVisible}
        onCancel={handleAcceptModalCancel}
        centered
        footer={null}
        closable={false}
        zIndex={50}
      >
        {currentAcceptReimbursement !== null ? (
          <>
            <h3 className="h5-bold-20">Reimbursement Data</h3>
            <div className="accept-modal-text-box">
              <div className="accept-modal-text flex">
                <span className="accept-modal-text-label" style={{ flex: "1" }}>PAN</span>
                <span className="accept-modal-text-value" style={{ flex: "2" }}>
                  {": " + currentAcceptReimbursement.user.PAN}
                </span>
              </div>
              <div className="accept-modal-text flex">
                <span className="accept-modal-text-label" style={{ flex: "1" }}>
                  Date
                </span>
                <span className="accept-modal-text-value" style={{ flex: "2" }} >
                  {": " + new Date(currentAcceptReimbursement.reimbursementBillDate).toDateString()}
                </span>
              </div>
              <div className="accept-modal-text flex">
                <span className="accept-modal-text-label" style={{ flex: "1" }}>
                  Type
                </span>
                <span className="accept-modal-text-value" style={{ flex: "2" }}>
                  {": " + config.reimbursementTypes[currentAcceptReimbursement.type]}
                </span>
              </div>
              <div className="accept-modal-text flex">
                <span className="accept-modal-text-label" style={{ flex: "1" }}>Reason </span>
                <span className="accept-modal-text-value" style={{ flex: "2" }}>
                  {": " + currentAcceptReimbursement.details}
                </span>
              </div>
              <div className="accept-modal-text flex">
                <span className="accept-modal-text-label" style={{ flex: "1" }}>
                  Amount
                </span>
                <span className="accept-modal-text-value" style={{ flex: "2" }}>
                  {": " + currentAcceptReimbursement.amount}
                </span>
              </div>
              <div className="accept-modal-text">
                <span className="accept-modal-text-label">Documents: </span>
              </div>
              {currentAcceptReimbursement.documents.map((data, i) => {
                return (
                  <span className="accept-modal-doucments-container">
                    <NormalButton
                      onClick={() =>
                        handelViewDocument(
                          data,
                          i,
                          currentAcceptReimbursement.reimbursementId
                        )
                      }
                      type={7}
                      heightWidth={[40, 40]}
                      padding={[0, 0]}
                      margin={[0, 8]}
                      buttonText={`#${i + 1}`}
                    />
                    {data.verified === true ? (
                      <div className="accept-modal-status-conatiner">
                        <img
                          alt=""
                          src={verifyYes}
                          className="accept-modal-status-logo"
                        ></img>
                        Verified
                      </div>
                    ) : (
                      <div className="accept-modal-status-conatiner">
                        <img
                          alt=""
                          src={verifyNo}
                          className="accept-modal-status-logo"
                        ></img>
                        Verification Pending
                      </div>
                    )}
                  </span>
                );
              })}
            </div>
          </>
        ) : (
          ""
        )}
        <div className="accept-modal-button">
          <NormalButton
            type={13}
            buttonText={"Accept"}
            onClick={handelReimbursementAcceptStateChange}
            margin={[20, 10]}
          />
          <NormalButton
            type={5}
            buttonText={"Close"}
            onClick={handleAcceptModalCancel}
            margin={[20, 0]}
          />
        </div>
      </Modal>
    </>
  );
};

const OrganisationReimbursementContainer = connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(OrganisationReimbursement);

export default OrganisationReimbursementContainer;
