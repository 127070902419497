import { Tabs } from 'antd';
import ForDisabled from "./deducation/forDisabled.js";
import General from "./deducation/general.js";
import Loans from './deducation/loans.js';
import MoreDeducation from "./deducation/moreDeducation.js"
import Summary from "./deducation/summary.js";

const { TabPane } = Tabs;

const deducationTabsLists = [
    {
        tabName: "General",
        key: "1",
        component: General,
        forceRender: false,
    },
    {
        tabName: "More Deductions",
        key: "2",
        component: MoreDeducation,
        forceRender: false,
    },
    {
        tabName: "For Disabled",
        key: "3",
        component: ForDisabled,
        forceRender: false,

    },
    {
        tabName: "Interest on Loans",
        key: "4",
        component: Loans,
        forceRender: false,

    },
    {
        tabName: "Summary",
        key: "5",
        component: Summary,
        forceRender: true,
    }
]

const Deduction = ({ the12BBApproved, organisationFreeze }) => {
    return (
        <div>
            <div className="blue-tabs-deduction-container">
                <Tabs tabPosition='left'>
                    {
                        deducationTabsLists.map((data, index) => {
                            return (
                                <TabPane key={`deduction-tab-pane-${index}`}
                                    tab={
                                        <span className="left-side-tabs">
                                            {data.tabName}
                                        </span>
                                    }
                                    forceRender={data.forceRender}
                                >
                                    <data.component organisationFreeze={organisationFreeze} key={`deduction-tab-pane-data-${index}`} the12BBApproved={the12BBApproved} />
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        </div >
    )
}

export default Deduction;