import React from "react";
import { Tabs } from "antd";
import Allowance from "./Declaration/allowance";
import Deduction from "./Declaration/deduction";
import { connect } from "react-redux";

const { TabPane } = Tabs;

const Declaration = ({ user, orgInfo }) => {

  return (
    <div className="employee-details-container">
      <div className="declaration-page-main-tabs-container">
        <Tabs
          defaultActiveKey="1"
        >
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name">Deduction</span>
              </div>
            }
            key="1"
          >
            <div className="personal-info-container">
              <Deduction user={user} orgInfo={orgInfo} />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name">Allowance</span>
              </div>
            }
            key="2"
          >
            <div className="personal-info-container">
              <Allowance user={user} orgInfo={orgInfo} />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const DeclarationContainer = connect((state) => ({
  deductions: state.employee.deductions,
}))(Declaration);

export default DeclarationContainer;
