import React, { useState } from "react";
import { FileViewer } from "../../../ComponentLibrary";
import EmployeeDocs from "../../../AllInvestementAllowance/Components/EmployeeDocs";
import { DatePicker } from "antd";

const Allowance = ({ user, orgInfo }) => {
  const [viewFile, setViewFile] = useState(false);
  const [viewFileDocumentProps, setviewFileDocumentProps] = useState({});
  const [
    viewFileDocumentVerifyProps,
    setviewFileDocumentVerifyProps,
  ] = useState({});
  
  return (
    <>
      <FileViewer
        visible={viewFile}
        onclose={setViewFile}
        fileProps={viewFileDocumentProps}
        verifyProps={viewFileDocumentVerifyProps}
      />
      <div className="employee-deduction-file-container">
        <div className="employee-upload-files-container">
          <div className="deduction-header">
            <h1 className="app-heading-3">Allowance List</h1>
            <div className="year-container">
              <span className="app-label-2">Financial year</span>
              <DatePicker
                format={"YYYY"}
                className="app-date-picker month-picker"
                // onChange={handelDateChange}
                picker={"year"}
              // value={reportDate}
              />
            </div>
          </div>
          <EmployeeDocs
            employeeDetails={user}
            organisaiton={orgInfo}
            setViewFile={setViewFile}
            id={user._id}
            setviewFileDocumentVerifyProps={setviewFileDocumentVerifyProps}
            setviewFileDocumentProps={setviewFileDocumentProps}
            AllowanceOrDeduction={true}
          />
        </div>
      </div>
    </>
  );
};

export default Allowance;
