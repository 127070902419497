
import axios from './index'

export function getAuditReport({ organisationId, month, financialYear }) {
    return axios.get(`/audit/report/${organisationId}/${month}/${financialYear}`)
}

export function parseAuditReport(files) {
    return axios.post(`/audit/report_parser`, files)
}

export function saveAuditRecord(data) {
    return axios.post(`/audit`, data)
}

export function initiatePayment(data) {
    return axios.post(`/audit/payment`, data)
}

export function getPaymentStatus({ organisationId, month, financialYear }) {
    return axios.get(`audit/payment/${organisationId}/${month}/${financialYear}`)
}

export function getExistingAuditRecord({ organisationId, month, financialYear }) {
    return axios.get(`/audit/record/${organisationId}/${month}/${financialYear}`)
}

export function getExistingLatestAuditRecord({ organisationId }) {
    return axios.get(`/audit/latest/record/${organisationId}`)
}

export function getAllRecordsForFY({ organisationId, financialYear }) {
    return axios.get(`/audit/records/${organisationId}/${financialYear}`)
}


export function initiatePaymentForCompliance(data) {
    return axios.post(`/audit/compliance/create_order`, data)
}

export function getAllPaidComplianceReports({ organisationId, month, financialYear }) {
    return axios.get(`/audit/compliance/reports/${organisationId}/${month}/${financialYear}`)
}