import { InboxOutlined } from "@ant-design/icons";
import { notification, Spin, Switch, Tabs, Upload } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addProofs,
  updateEmployeeOnBoarding
} from "../../../../../apis/employee";
import { getFreezeObject, getHRAObjectByUser, updateHRAYearly } from "../../../../../apis/organisation";
import { createLog } from "../../../../../apis/user";
import employee from "../../../../../redux/modules/employee";
import config, { checkFinancialYear, monthList, finMonthNumbers } from "../../../../../utils/config";
import { NormalButton } from "../../../../ComponentLibrary";
import InputFieldComponent from "../../../../InputLibrary/InputFieldComponent/InputFieldComponent";

const { Dragger } = Upload;
const { TabPane } = Tabs
const HraFilesUpload = ({
  user,
  declaration,
  updateDeclarationDocument,
  hraDocuments,
  employeeReducer,
  organisationFreeze,
  onboarding,
  ctc
}) => {
  const [hraUpdateData, setHraUpdateData] = useState({});
  const [payslipFreezeInfo, setPayslipFreezeInfo] = useState({});

  const [rentAgreement, setRentAgreement] = useState({});
  const [loading, setLoading] = useState(false);
  const [valueUpdated, setValueUpdated] = useState(false);
  const [key, setKey] = useState("3");
  const uploadRentAgreement = {
    name: "file",
    action: `${config.rootUrl}/api/document/proofs`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    multiple: false,
    async onChange(info) {
      setLoading(true);
      let { response } = info.file;
      if (response) {
        setLoading(false);
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.substring(
          0,
          info.file.name.lastIndexOf(".")
        );
        delete documentDetails.Bucket;
        delete documentDetails.ETag;
        uploadProofs("rentAgreement", documentDetails);
      }
    },
  };

  useEffect(() => {
    if (user._id && user.organisationId) {
      const financialYear = checkFinancialYear().financialYear;
      getHRAObjectByUser(user._id, financialYear).then(res => {
        if (res.data.success) {
          setHraUpdateData({ ...res.data.data.houseRent })
        }
      });
      getFreezeObject({ organisationId: user.organisationId, financialYear }).then(res => {
        if (res.data.success) {
          setPayslipFreezeInfo(res.data.data);
        }
      })
    }
  }, [user._id, user.organisationId]);

  useEffect(() => {
    let ra = hraDocuments.filter((val) => val.subCatagory === "rentAgreement");
    ra ? setRentAgreement({ ...ra[0] }) : setRentAgreement({});
  }, [hraDocuments]);


  const uploadProofs = async (type, documents) => {
    if (
      organisationFreeze.allowancesFreeze &&
      organisationFreeze.allowances[user._id]
    ) {
      notification.warn({
        message: `Organisation has freezed the allowance for the current financial year`,
      });
      return;
    }
    setLoading(true);
    let tempUploadData = {
      proofObj: {
        subCatagory: type,
        amount: 0,
        documents: [documents],
        category: "HRA",
      },
    };
    tempUploadData.proofObj.id = user._id;
    tempUploadData.proofObj.organisationId = user.currentOrganisationId;
    tempUploadData.proofObj.proofType = 1;
    await addProofs(tempUploadData);
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `User has uploaded the HRA proofs`,
      category: 13,
    });
    await updateDeclarationDocument();
    setLoading(false);
  };

  const saveHRA = async () => {
    if (
      organisationFreeze.allowancesFreeze &&
      organisationFreeze.allowances[user._id]
    ) {
      notification.warn({
        message: `Organisation has freezed the allowance for the current financial year`,
      });
      return;
    }
    const { financialYear } = checkFinancialYear();

    // let dcl = declaration;
    // let hraData = hraDetails;
    // hraData.rent =
    //   hraData.rent === "" || hraData.rent === null || !parseInt(hraData.rent)
    //     ? 0
    //     : hraData.rent;
    // if (hraData.rent === 0) {
    //   hraData.applicable = false;
    //   hraData.isMetro = false;
    //   hraData.ownerPAN = "";
    // }
    // const { financialYear, month } = checkFinancialYear();
    // dcl.declarations.houseRent = { ...hraData };
    // dcl.declarations.houseRent.applicable = true;
    // setLoading(true);
    // await updateDeclarations({
    //   id: user._id,
    //   declarations: dcl.declarations,
    //   month
    // });

    await updateHRAYearly({
      id: user._id, financialYear, houseRent: hraUpdateData
    })
    // await deleteCurrentPayslip(user._id);
    // await employeeReducer.askMool({
    //   financialYear,
    //   month,
    //   id: user._id,
    //   user,
    //   ctc
    // });
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `User has updated the HRA`,
      category: 13,
    });
    if (onboarding && !onboarding.fields.hra) {
      await updateEmployeeOnBoarding({
        fields: { hra: true },
        organisationId: user.organisationId,
        id: user._id,
      });
      employeeReducer.updateEmployeeOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
    notification.success({ message: "House rent has been updated" })
    setValueUpdated(false);
    setLoading(false);
  };

  return (
    <div className="hra-files-upload-container">
      <div className="hra-files-header">
        <h1 className="hra-files-heading">Fill up details</h1>
        <div className="hra-files-header-button-conatiner">
          {valueUpdated &&
            !(
              organisationFreeze.allowancesFreeze &&
              organisationFreeze.allowances[user._id]
            ) && (
              <NormalButton
                disabled={loading}
                buttonText={"Save"}
                type={13}
                size={"large"}
                onClick={saveHRA}
                className="save-manage-btn manage-btn"
              />

            )}
        </div>
      </div>
      <Spin spinning={loading} size="large">
        <Tabs defaultActiveKey={key} key={key} style={{ marginLeft: "10px" }} onChange={(e) => { setKey(e + "") }}>
          {
            finMonthNumbers.map((item) => (
              <TabPane
                tab={
                  <div className="tabs-item" >
                    <span className="tabs-item-name p-left-10 p-right-10">{monthList[item]}</span>
                  </div>
                }
                key={item}
              >
                <div className="hra-files-house-owner-pan-conatiner">
                  <InputFieldComponent
                    labeltext={"Monthly Rent of " + monthList[item] + " in INR"}
                    className="general-ant-field-input"
                    type={"text"}
                    disabled={
                      (payslipFreezeInfo[item]?.payrollFreeze ?? false) &&
                      organisationFreeze.allowancesFreeze &&
                      organisationFreeze.allowances[user._id]
                    }
                    min={0}
                    style={{ width: "100%" }}
                    value={hraUpdateData[key]?.rent ?? 0}
                    onChange={(val) => {
                      if (parseInt(val.target.value) || val.target.value === "") {
                        setHraUpdateData({
                          ...hraUpdateData,
                          [key]: {
                            ...hraUpdateData[key],
                            rent: parseInt(val.target.value)
                              ? parseInt(val.target.value)
                              : null,
                          }
                        })
                        setValueUpdated(true);
                      }
                    }}
                  />
                </div>
                <div
                  className="hra-files-monthly-rent-container"
                  style={{
                    margin: "20px 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label>Are you staying in a metro cities?</label>{" "}
                  <Switch
                    disabled={
                      (payslipFreezeInfo[item]?.payrollFreeze ?? false) &&
                      organisationFreeze.allowancesFreeze &&
                      organisationFreeze.allowances[user._id]
                    }
                    checked={hraUpdateData[key]?.isMetro ?? false}
                    onChange={(checked) => {
                      setValueUpdated(true);
                      setHraUpdateData({
                        ...hraUpdateData,
                        [key]: {
                          ...hraUpdateData[key],
                          isMetro: checked
                        }
                      })
                    }}
                  />
                </div>
                <div className="hra-files-house-owner-pan-conatiner">

                  <InputFieldComponent
                    labeltext={"PAN Number of the owner"}
                    className="general-ant-field-input"
                    disabled={
                      (payslipFreezeInfo[item]?.payrollFreeze ?? false) &&
                      organisationFreeze.allowancesFreeze &&
                      organisationFreeze.allowances[user._id]
                    }
                    type={"text"}
                    min={0}
                    value={hraUpdateData[key]?.ownerPAN ?? ""}
                    onChange={(val) => {
                      setValueUpdated(true);
                      if (val.target.value.length < 11)
                        setHraUpdateData({
                          ...hraUpdateData,
                          [key]: {
                            ...hraUpdateData[key],
                            ownerPAN: val.target.value.toUpperCase(),

                          }
                        })
                    }}
                    style={{ width: "100%", marginBottom: "20px" }}
                  />
                </div>

              </TabPane>
            ))
          }
        </Tabs>
        <div className="hra-files-home-agrement-container">
          <h4 className="hra-files-home-agrement-heading">Home Agreement</h4>
          {!Object.keys(rentAgreement).length &&
            !(
              organisationFreeze.allowancesFreeze &&
              organisationFreeze.allowances[user._id]
            ) ? (
            <Dragger
              disabled={
                organisationFreeze.allowancesFreeze &&
                organisationFreeze.allowances[user._id]
              }
              {...uploadRentAgreement}
              beforeUpload={config.beforeFileUpload}
              showUploadList={false}
              className="document-upload"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-hint">
                Drag and drop .pdf file or Click here to upload files
              </p>
            </Dragger>
          ) : (
            <p>Rent Agreement has already been uploaded</p>
          )}
        </div>
      </Spin>
    </div>
  );
};
const HraFilesUploadContainer = connect(
  (state) => ({
    user: state.auth.user,
    onboarding: state.employee.onboarding,
    ctc: state.employee.ctc,
    declaration: state.employee.declaration,
    organisationFreeze: state.employee.organisationFreeze
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
  })
)(HraFilesUpload);
export default HraFilesUploadContainer;
