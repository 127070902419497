import auth from "../../redux/modules/auth";
import organisation from "../../redux/modules/organisation";
import { connect } from "react-redux";
import { Col, Row } from "antd";
import { HeaderComponent } from "../Common";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import SettlementForm from "./components/SettlementForm";
import SettlementList from "./components/SettlementList";
import {
  getEmployeeSettlement,
  getUserFromId,
} from "../../apis/organisation";
import { useParams, withRouter } from "react-router";
// import { Link } from "react-router-dom";

const Settlement = ({ user, history }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [settlementData, setSettlementData] = useState({
    leaveEncashment: 0,
    lossOfPay: 0,
    gratuityAmount: 0,
    pfAmount: 0,
    otherDebitAmount: 0,
    otherCreditAmount: 0,
    taxDeducted: 0,
    initiationDate: "",
    lastDayOfWorking: "",
    gratuityEligible: false,
    withdrawPF: false,
  });
  const [updateFinalSettlement, setUpdateFinalSettlement] = useState(true);
  const [userDetails, setUserDetails] = useState({})

  const updateSettlementData = (settlement) => {
    if (settlement) setUpdateFinalSettlement(false);
    const dummySettlement = {
      leaveEncashment: settlement.leaveEncashment || 0,
      lossOfPay: settlement.lossOfPay || 0,
      gratuityAmount: settlement.gratuityAmount || 0,
      pfAmount: settlement.pfAmount || 0,
      otherDebitAmount: settlement.otherDebitAmount || 0,
      otherCreditAmount: settlement.otherCreditAmount || 0,
      gratuityEligible: settlement.gratuityEligible || false,
      withdrawPF: settlement.withdrawPF || false,
      taxDeducted: settlement.taxDeducted || 0,
      lastDayOfWorking: settlement.lastDayOfWorking || "",
      initiationDate: settlement.initiationDate || "",
    };
    setSettlementData({ ...dummySettlement });
  };

  useEffect(() => {
    document.title = 'Settlement';
  });

  const getUserDetails = async () => {
    let res = await getUserFromId(id)
    setUserDetails(res.data)
  }

  useEffect(() => {
    setLoading(true);
    getUserDetails()
    getEmployeeSettlement(id)
      .then((data) => {
        updateSettlementData(data.data);
        setLoading(false);
      })
      .catch((_err) => setLoading(false));
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className="site-layout">
      <HeaderComponent show={false} showMoolCoins={true} name="Separation" />
      <div className="settlement-container">
        {/* <div className="app-breadcrum-container-2 m-bottom-20">
          <Breadcrumb className="app-breadcrum">
            <Breadcrumb.Item>
              {
                history.location.state && history.location.state.fromEmployeeProfile === true
                  ? <Link to={`/org/employee/employee_details/${userDetails._id}`}>{userDetails.displayName}</Link>
                  : userDetails.displayName
              }
            </Breadcrumb.Item>
          </Breadcrumb>
        </div> */}
        <Row gutter={24}>
          <Col sm={24} md={14}>
            <SettlementForm
              settlementData={settlementData}
              loading={loading}
              id={id}
              user={user}
              updateFinalSettlement={updateFinalSettlement}
              updateSettlementData={updateSettlementData}
              userDetails={userDetails}
              parentComponentSetLoading={setLoading}
            />
          </Col>
          <Col sm={24} md={10}>
            <SettlementList settlementData={settlementData} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

Settlement.propTypes = {
  user: PropTypes.object.isRequired,
};

const SettlementContainer = connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    organisation: organisation.getActions(dispatch),
  })
)(Settlement);

export default withRouter(SettlementContainer);