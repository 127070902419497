import { useState,useEffect } from "react";
import { Tabs } from "antd";
import TaxProjection from "./TaxProjection.js"
import { HeaderComponent } from "../Common";
import activeDeclaration from "../../assets/icons/activeIcons/activeDeclaration.svg";
import { connect } from "react-redux";
import { PreviousEmployment } from "../index.js";
import AdvanceTaxContainer from "./AdvanceTax.js";

const { TabPane } = Tabs;

const TaxDeclarations = (props) => {
    const [the12BBApproved, setThe12BBApproved] = useState(false)
    useEffect(() => {
        document.title = 'Declarations | Tax Projections';
      });
    return (
        <div className="site-layout">
            <HeaderComponent icon={activeDeclaration} name="Declaration" />
            <div className="manage-declaration-container declaration-page-main-tabs-container">
                <Tabs
                    defaultActiveKey="1"
                >
                    <TabPane
                        tab={
                            <div className="tabs">
                                <span className="tab-name">Tax Projection</span>
                            </div>
                        }
                        key="1"
                    >
                        <TaxProjection user={props.user} declaration={props.declaration} payslips={props.payslips} the12BBApproved={the12BBApproved} setThe12BBApproved={setThe12BBApproved} />
                    </TabPane>
                    <TabPane
                        tab={
                            <div className="tabs">
                                <span className="tab-name">Advance Tax</span>
                            </div>
                        }
                        key="2"
                    >
                        <AdvanceTaxContainer />
                    </TabPane>
                    {
                        props.isCurrentFinancialYearJoinee && <TabPane
                            tab={
                                <div className="tabs">
                                    <span className="tab-name">Previous Employment</span>
                                </div>
                            }
                            key="3"
                        >
                            <PreviousEmployment />
                        </TabPane>
                    }
                </Tabs>
            </div>
        </div>
    );
};

const TaxDeclarationsItem = connect((state) => ({
    user: state.auth.user,
    declaration: state.employee.declaration,
    payslips: state.employee.payslips,
    organisationFreeze: state.employee.organisationFreeze,
    isCurrentFinancialYearJoinee: state.auth.isCurrentFinancialYearJoinee
}))(TaxDeclarations);

export default TaxDeclarationsItem;
