import React from 'react'
import { cashfreeConfig } from '../../utils/config';
import useScript from '../../utils/useScript';

const SDKLoaders = () => {
    useScript(cashfreeConfig.REACT_APP_CASHFREE_SDK_URL);

    return (
        <div></div>
    );
}

export default SDKLoaders