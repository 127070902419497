export const docs = {
  profile: {
    num: '8',
    name: "Address",
    sub: "Add in departments that are there in your company",
    path: "/org/common/profile",
    subcategory: "profile",
  },
  department: {
    num: '2',
    name: "Add Departments",
    sub: "Add in departments that are there in your company",
    path: "/org/config/department",
    subcategory: "department",
  },
  designation: {
    num: '3',
    name: "Setup Designation",
    sub: "Add in departments that are there in your company",
    path: "/org/config/designation",
    subcategory: "designation",
  },
  groups: {
    num: '4',
    name: "Create allowance group",
    sub: "Add in departments that are there in your company",
    path: "/org/config/groups",
    subcategory: "groups",
  },
  employeeAddition: {
    num: '7',
    name: "Create an employee",
    sub: "Add in departments that are there in your company",
    path: "/org/employee/employees",
    subcategory: "employees",
  },
  
  profilePicture: {
    num: '1',
    name: "Add a profile picture",
    sub: "Distinguish yourself by having an identity",
    path: "/org/common/profile",
    subcategory: "profile",
  },
  status: {
    num: '5',
    name: "Create status type",
    sub: "Add in departments that are there in your company",
    path: "/org/config/status",
    subcategory: "status",
  },
  location: {
    num: '6',
    name: "Location",
    sub: "Add in departments that are there in your company",
    path: "/org/config/location",
    subcategory: "location",
  },
  previousDataSync: {
    num: '9',
    name: "Previous Data Sync",
    sub: "Add in departments that are there in your company",
    path: "/org/employee/data_sync",
    subcategory: "data_sync",
  },
};

export const empDocs = {
  address: {
    num: '7',
    name: "Address",
    path: "/employee/common/profile#employeeAddress",
    subcategory: "profile",
  },
  allowances: {
    num: '1',
    name: "Allowances",
    path: "/employee/declaration/allowance",
    subcategory: "allowance",
  },
  bankInformation: {
    num: '3',
    name: "Bank Information",
    path: "/employee/common/profile#bankInfoContainer",
    subcategory: "profile",
  },
  declarations: {
    num: '4',
    name: "Declarations",
    path: "/employee/declaration/declaration",
    subcategory: "declaration",
  },
  hra: {
    num: '2',
    name: "HRA",
    path: "/employee/declaration/allowance",
    subcategory: "allowance",
  },
  personalCredentials: {
    num: '6',
    name: "Personal Credentials",
    path: "/employee/common/profile#credentials",
    subcategory: "profile",
  },
  profilePicture: {
    num: '8',
    name: "Profile Picture",
    path: "/employee/common/profile",
    subcategory: "profile",
  },
  personalInformation: {
    num: '5',
    name: "Personal Information",
    path: "/employee/common/profile",
    subcategory: "profile",
  },
};
