import { Modal, Spin, Table, Progress, notification } from "antd";
import { useEffect, useState } from "react";
import { payoutBeneficiary, payoutTransfer } from "../../../apis/organisation";
import { NormalButton } from "../../ComponentLibrary";
const transferCategory = ["Salary", "Reimbursement", "Settlement"];

/**
 * Transaction Type
 * 0: Salary
 * 1: Reimbursement
 * 2: Settlement
 */
const TransactMoney = ({
    transferList,
    listTransaction,
    user,
    transactionModal,
    setTransactionModal,
    updateTransaction,
    transactionType,
    month,
    year
}) => {
    const [loading, setLoading] = useState(false);
    const [payrollProcessed, setPayrollProcessed] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [progressCount, setProgressCount] = useState(0);
    useEffect(() => {
        setLoading(true);
        payoutBeneficiary(user.organisationId)
            .then((response) => {
                let beneficiaries = response.data.data.beneficiaries ? response.data.data.beneficiaries : [];
                let fd = [];
                beneficiaries.forEach(val => {
                    let id = val.id;
                    // First Search
                    let transfer = listTransaction.filter(lt => {
                        let data = id.toString() === lt.user._id.toString()
                        return data;
                    })
                    if (transfer[0]) {
                        let resData = {
                            id,
                            month,
                            year,
                            organisationId: user.organisationId,
                            type: transactionType,
                            name: val.name,
                            status: false,
                            username: val.user.username
                        }
                        // If There is already one transaction left, then the status is true, that means processed
                        let availableTransfer = transferList.filter(tl => (id === tl.id.toString() && tl.type === transactionType && tl.status === "SUCCESS"))
                        if (availableTransfer[0]) {
                            resData.status = true;
                        }
                        fd.push(resData)
                    }
                    else {
                        fd.push({ ...val, username: val.user.username, status: false })
                    }
                })
                const processed = fd.every(item => item.status)
                setPayrollProcessed(processed)
                setFilteredData([...fd])
                setLoading(false);
            })
            .catch((_error) => {
                setLoading(false);
                setFilteredData([]);
                setTransactionModal(false);
            });
    }, [listTransaction, month, setTransactionModal, transactionType, transferList, user.organisationId, year]);
    const ProcessPayment = async () => {
        setLoading(true);
        let total = 0;
        for (const element of filteredData) {
            if (!element.status) {
                await payoutTransfer(element);
            }
            total++;
            setProgressCount(total)
        }
        await updateTransaction();
        notification.success({ message: "Payroll has been processed" })
        setLoading(false);
        setTransactionModal(false)
    }
    const columns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Name",
            dataIndex: ["name"],
            key: "name",
        },
        {
            title: "Type",
            dataIndex: ["type"],
            key: "type",
            render: data => <p>{transferCategory[data]}</p>
        },
        {
            title: "Status",
            dataIndex: ["status"],
            key: "resData",
            render: (data) => <p className={data ? 'success-text' : 'danger-text'}>{data ? "Processed" : "Not Processed"}</p>

        },
    ];

    return (
        <Modal
            visible={transactionModal}
            footer={null}
            closable={false}
            width={800}
        >
            <div className="w-full">
                <Spin spinning={loading} size={"large"}>
                    <h3 className="app-heading-2">Process {transferCategory[transactionType]} Payments</h3>
                    {loading && <Progress
                        percent={Math.round(
                            (progressCount / filteredData.length) * 100
                        )}
                        size="small"
                        status="active"
                    />}
                    <Table columns={columns} dataSource={filteredData} />
                    <div className="w-full flex justify-end">
                        <NormalButton
                            type={1}
                            buttonText={payrollProcessed ? "Already Processed" : "Process Payment"}
                            disabled={loading || payrollProcessed}
                            onClick={ProcessPayment}
                            margin={[0, 8]}
                        />
                        <NormalButton
                            type={2}
                            buttonText={"Cancel"}
                            disabled={loading}
                            onClick={() => {
                                setLoading(false);
                                setTransactionModal(false);
                            }}
                            margin={[0, 8]}
                        />
                    </div>
                </Spin>
            </div>
        </Modal>
    );
};

export default TransactMoney;
