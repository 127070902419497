import { DeleteOutlined } from "@ant-design/icons";
import { Skeleton, Button, Card, Modal, Spin, Typography } from "antd";
import { removeProof } from "../../../../../apis/employee";
import { createLog, getDocumentURL } from "../../../../../apis/user";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PasswordResponses from "react-pdf/dist/esm/PasswordResponses";
import React, { useState } from "react";
import verifiedTrue from "../../../../../assets/common/verifiedLogos/verify-yes.svg";
import verifiedFalse from "../../../../../assets/common/verifiedLogos/verify-no.svg";
import { FileLogos } from "../../../../ComponentLibrary"
import packageJson from "../../../../../../package.json";
// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
const { Text } = Typography;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const subCategory = {
  rentRecipt: "Rent Recipt",
  rentAgreement: "Rent Agreement",
};
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];
const HraSupportingDocuments = ({
  hraDocuments,
  updateDeclarationDocument,
  loadingMain,
  user
}) => {
  const [document, setDocument] = useState("");
  const [modalstatus, setModalStatus] = useState(false);
  const [format, setFormat] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewFileDocumentProps, setViewFileDocumentProps] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderToolbar = (Toolbar) => (React.createElement(Toolbar, null, (slots) => {
    const { CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage, NumberOfPages, Zoom, ZoomIn, ZoomOut } = slots;
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div style={{ padding: '0px 2px' }}>
          <ZoomOut />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Zoom />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <ZoomIn />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <GoToPreviousPage />
        </div>
        <div style={{ padding: '0px 2px', width: '4rem' }}>
          <CurrentPageInput />
        </div>
        <div style={{ padding: '0px 2px' }}>
          / <NumberOfPages />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <GoToNextPage />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <EnterFullScreen />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Download />
        </div>
      </div>
    );
  }));

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });
  const getDocumentURLFromKey = async (data) => {
    let fileProperty = {
      documentKey: data.Key,
      documentType: data.Key.split(".").pop(),
      documentName: data.name,
    }
    setViewFileDocumentProps(fileProperty);
    let documents = await getDocumentURL({ Key: data.Key });
    setDocument(documents.data);
    if (fileProperty.documentType === "pdf") {
      setFormat("pdf");
      setModalStatus(false);
      showModal();
    } else {
      setFormat("image");
      setModalStatus(true);
    }
  };

  const DocumentAllowances = ({ data }) => {
    return (
      <div className="document-container">
        <div
          style={{ display: "flex" }}
          onClick={() => {
            getDocumentURLFromKey(data);
          }}
        >
          <span
            className="file-typeHeader"
          >
            {data.Key.split(".").pop()}
          </span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text
              style={{ width: 200 }}
              ellipsis={{ tooltip: data.name }}
            >{data.name}</Text>
            <span className="date-contianer">
              {new Date(data.createdAt).toDateString()}
            </span>
          </div>
        </div>
        {data.verified ? (
          <span className="verifiection-text verified">
            <img
              alt="verification status logo"
              className="verifiection-logo"
              src={verifiedTrue}
            >
            </img>
            Verified
          </span>
        ) : (
          <span className="verifiection-text not-verified">
            <img
              alt="verification status logo"
              className="verifiection-logo"
              src={verifiedFalse}
            >
            </img>
            Not Verified
          </span>
        )}
      </div>
    );
  };

  const onPasswordHandler = function (callback, reason) {
    function callbackProxy(password) {
      // Cancel button handler
      if (!password) {
        setModalStatus(false)
        return
      }
      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }

  const removeProofs = async (data) => {
    setLoading(true);
    await removeProof(data.proofId);
    await updateDeclarationDocument();
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `User has removed one document`,
      category: 13,
    });
    setLoading(false);
  };

  return (
    <div className="otherAllowance-files-container">
      <div className="otherAllowance-files-header">
        <h1 className="otherAllowance-files-heading">Documents</h1>
      </div>
      <Spin spinning={loading} size="large">
        <div className="document-list-container">
          <Skeleton loading={loadingMain} active paragraph={{ rows: 3 }} avatar>
            {hraDocuments &&
              hraDocuments.map((value, index) => {
                return (
                  <div>
                    <Card bordered={false} className="document-group">
                      <div className="document-header">
                        <div className="document-action-container">
                          {!value.approved && <Button
                            danger
                            shape="circle"
                            type=""
                            onClick={() => removeProofs(value)}
                          >
                            <DeleteOutlined
                              key={`doc-container-delete-${index}`}
                            />
                          </Button>}
                        </div>
                        <p className="document-header-labele">
                          {subCategory[value.subCatagory]}
                        </p>
                      </div>
                      <div className="document-list">
                        {value.documents.map((val, i) => (
                          <DocumentAllowances data={val} key={`hra-docs-${i}`} />
                        ))}
                      </div>
                    </Card>
                    <div className="seperator"></div>
                  </div>
                );
              })}
          </Skeleton>
          <Modal
            onCancel={() => {
              setDocument("");
              setModalStatus(false);
              setFormat("");
            }}
            visible={modalstatus}
            footer={null}
            centered
            width={900}
          >
            {format === "image" && <Skeleton loading={!document} active avatar paragraph={{ rows: 5 }}>
              <a download href={document}>
                Download Document
              </a>
              <img src={document} className="document-display" alt="document-display" />
            </Skeleton>}
          </Modal>
          {format === "pdf" &&
            <Modal
              title={
                <>
                  <FileLogos type={1} /> {viewFileDocumentProps.documentName}
                </>
              }
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              width={1000}
              footer={null}
            >
              <div>
                {document ? (
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
                  >
                    <Viewer
                      fileUrl={document}
                      plugins={[defaultLayoutPluginInstance]}
                      renderError={console.log}
                      onDocumentAskPassword={onPasswordHandler}
                      initialPage={1}
                    />
                  </Worker>
                ) : (
                  "Document URL not found"
                )}
              </div>
            </Modal>
          }
        </div>
      </Spin>
    </div>
  );
};

export default HraSupportingDocuments;
